<template>
  <div class="payContent detail">

        <el-table
            :data="mapList"
            :span-method="objectSpanMethod"
            border
            style="width: 100%; margin-top: 20px">
          <el-table-column
              prop="broadHeading"
              label="报价类别"
              width="180">
          </el-table-column>
          <el-table-column
              prop="pronname"
              label="报价项目">
          </el-table-column>
          <el-table-column
              prop="provalue"
              label="公式">
          </el-table-column>
          <el-table-column
              prop="value"
              label="金额">
          </el-table-column>
          <el-table-column
              prop="fee"
              label="合计">
          </el-table-column>
          <el-table-column
              prop="rate"
              label="占比">
          </el-table-column>
        </el-table>

        <tfoot>
        <tr class="success">
          <th colspan="2">合计金额：</th>
          <th style="font-size: large;color: #0E5ED3;">{{orderfee}}元</th>
        </tr>
        </tfoot>

    </div>


</template>

<script>
export default {
  name: "FeeDetails",
  props: ["orderId","userfatherid","orderfee"],
  data() {
    return {
      mapList: [],
      productFee: ''
    };
  },
  filters: {

  },
  created() {
     this.$http
      .get("/cpshttp/getExtendInfoListById?userfatherid="+this.userfatherid+ "&orderId=" + this.orderId)
      .then((res) => {
        console.log("获取报价详情", res);
        if (res.data.code == 200) {
            const  list = res.data.data
            for(let index = 0;index < list.length;index ++) {
                list[index].datalist.forEach(i => {
                this.mapList.push({
                  broadHeading: list[index].broadHeading,
                  fee:   list[index].fee,
                  rate:  list[index].rate,
                  pronname:   i.pronname,
                  provalue:  i.provalue,
                  value: i.value
              })
            })
           }
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error", error);
      });
  },
  mounted() {},
  methods: {

    mergeCol(id, rowIndex) { // 合并单元格
      // id：属性名
      // rowIndex：行索引值
      var idName = this.mapList[rowIndex][id] // 获取当前单元格的值
      if (rowIndex > 0) { // 判断是不是第一行
        // eslint-disable-next-line eqeqeq
        if (this.mapList[rowIndex][id] != this.mapList[rowIndex - 1][id]) { // 先判断当前单元格的值是不是和上一行的值相等
          var i = rowIndex; var num = 0 // 定义一个变量i，用于记录行索引值并进行循环，num用于计数
          while (i < this.mapList.length) { // 当索引值小于table的数组长度时，循环执行
            if (this.mapList[i][id] === idName) { // 判断循环的单元格的值是不是和当前行的值相等
              i++ // 如果相等，则索引值加1
              num++ // 合并的num计数加1
            } else {
              i = this.mapList.length // 如果不相等，将索引值设置为table的数组长度，跳出循环
            }
          }
          return {
            rowspan: num, // 最终将合并的行数返回
            colspan: 1
          }
        } else {
          return {
            rowspan: 0, // 如果相等，则将rowspan设置为0
            colspan: 1
          }
        }
      } else { // 如果是第一行，则直接返回
        let i = rowIndex; let num = 0
        while (i < this.mapList.length) { // 当索引值小于table的数组长度时，循环执行
          if (this.mapList[i][id] === idName) {
            i++
            num++
          } else {
            i = this.mapList.length
          }
        }
        return {
          rowspan: num,
          colspan: 1
        }
      }
    },

    objectSpanMethod({row, column, rowIndex, columnIndex })
    {
      switch (columnIndex) { // 将列索引作为判断值
        case 0:
          return this.mergeCol('broadHeading', rowIndex)
        case 4:
          return this.mergeCol('fee', rowIndex)
        case 5:
          return this.mergeCol('rate', rowIndex)
      }
    }


  }
}
</script>

<style lang="scss" scoped>
@media print {
  @page {
    size: auto;
    margin: 3mm;
  }
}
.trth {
  background-color: #ecf6ff;
  padding: 0;
  height: 34px;
  line-height: 34px;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
}

.rate-style {
  color: red;
  font-weight: bolder;
  font-size: 14px;
}

.fee-style {
  color: #1736ff;
  font-size: 14px;
}
.print {
  margin: 10px auto;
  display: flex;
}

.bottom {
  text-align: center;
  margin-top: 15px;
}
</style>
