<template>
  <div>
    <div class="inquiry">
      <div class="card-left">
        <div class="top">
          <i class="iconfont icon-iconcopy"></i>
          <span>订单管理</span>
        </div>
        <ul class="infos">
          <li
            :class="checked === item.id ? 'checked' : ''"
            :key="item.id"
            @click="replace(item)"
            class="info-item"
            v-for="item in list"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="card-right">
        <!--筛选条件-->
        <div class="screen">
          <el-row>
            <el-col :span="14" class="colls">
              <span>按日期查询:</span>
              <el-date-picker
                format="yyyy-MM-dd"
                placeholder="选择日期"
                type="date"
                v-model="startTime"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <div class="kuoz">-</div>
              <el-date-picker
                format="yyyy-MM-dd"
                placeholder="选择日期"
                type="date"
                v-model="endtime"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="4" class="coll">
              <el-button @click="query" class="btn" type="primary"
                >查询
              </el-button>
              <el-button @click="refresh" class="btn">刷新</el-button>
            </el-col>
            <el-col :span="6">
              <p class="moneyTotal">
                订单总金额:<span style="color: #ff0000">{{
                  allTotalMoney
                }}</span>
              </p>
            </el-col>
          </el-row>
        </div>
        <!-- 表格 -->
        <div class="tablist">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column
              prop="createTime"
              label="询价时间"
              width="200"
              align="center"
              header-align="center"
            >
            </el-table-column>

            <el-table-column
              prop="userName"
              label="客户名称"
              width="200"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="联系方式"
              width="200"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              label="订单金额"
              width="200"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.disCountFee | filterMoney }}</span>
              </template>
            </el-table-column>

            <el-table-column
              label="产品类型"
              align="center"
              width="120"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.productId == 1038
                    ? "综合"
                    : scope.row.productId == 1036
                    ? "挂历"
                    : scope.row.productId == 1035
                    ? "台历"
                    : scope.row.productId == 1034
                    ? "精装书"
                    : scope.row.productId == 1032
                    ? "封套"
                    : scope.row.productId == 1028
                    ? "单页/海报"
                    : scope.row.productId == 1027
                    ? "教辅"
                    : scope.row.productId == 1024
                    ? "瓦楞彩箱"
                    : scope.row.productId == 1023
                    ? "单层卡纸彩盒"
                    : scope.row.productId == 1020
                    ? "画册/样本"
                    : scope.row.productId == 1018
                    ? "装帧加工"
                    : scope.row.productId == 1016
                    ? "印刷加工"
                    : scope.row.productId == 1015
                    ? "手提袋"
                                                                      : scope.row.productId == 2028
                                                                          ? "单页"
                                                                          : scope.row.productId == 2037
                                                                              ? "不干胶"
                                                                              : scope.row.productId == 2020
                                                                                  ? "普通画册"
                                                                                  : scope.row.productId == 2034
                                                                                      ? "精装画册"
                                                                                      : scope.row.productId == 2026
                                                                                          ? "挂历"
                                                                                          : scope.row.productId == 2015
                                                                                              ? "手提袋"
                                                                                              : scope.row.productId == 2039
                                                                                                  ? "吊旗"
                                                                                                  : scope.row.productId == 2035
                                                                                                      ? "台历"
                                                                                                      : scope.row.productId == 2032
                                                                                                          ? "封套"
                                                                                                          : scope.row.productId == 2040
                                                                                                              ? "写真"
                                                                                                              : scope.row.productId == 2041
                                                                                                                  ? "X型展架"
                                                                                                                  : scope.row.productId == 2042
                                                                                                                      ? "易拉宝"
                                                                                                                      : scope.row.productId == 2043
                                                                                                                          ? "名片"
                    : ""
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="productNumber"
              label="产品数量"
              align="center"
              header-align="center"
            >
            </el-table-column>

            <el-table-column
              label="操作"
              width="200"
              align="center"
              header-align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="orderInfo(scope.row)"
                  >订单详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <!-- 详情弹窗 -->
        <el-dialog :title="title" :visible.sync="dialogVisible" width="50%">
          <div class="top">
            <div class="hand_line">
              <div class="title">客户名称</div>
              <span>:</span>
              <div class="text">{{ userInfo.userName }}</div>
            </div>
            <div class="hand_line">
              <div class="title">联系电话</div>
              <span>:</span>
              <div class="text">{{ userInfo.tel }}</div>
            </div>

            <div class="hand_line">
              <div class="title">询价时间</div>
              <span>:</span>
              <div class="text">{{ userInfo.time }}</div>
            </div>
            <div class="hand_line">
              <div class="title">产品类型</div>
              <span>:</span>
              <div class="text">
                <span>{{
                  productId == 1038
                    ? "综合"
                    : productId == 1036
                    ? "挂历"
                    : productId == 1035
                    ? "台历"
                    : productId == 1034
                    ? "精装书"
                    : productId == 1032
                    ? "封套"
                    : productId == 1028
                    ? "单页/海报"
                    : productId == 1027
                    ? "教辅"
                    : productId == 1024
                    ? "瓦楞彩箱"
                    : productId == 1023
                    ? "单层卡纸彩盒"
                    : productId == 1020
                    ? "画册/样本"
                    : productId == 1018
                    ? "装帧加工"
                    : productId == 1016
                    ? "印刷加工"
                    : productId == 1015
                    ? "手提袋"
                                                                      : productId == 2028
                                                                          ? "单页"
                                                                          :productId == 2037
                                                                              ? "不干胶"
                                                                              : productId == 2020
                                                                                  ? "普通画册"
                                                                                  : productId == 2034
                                                                                      ? "精装画册"
                                                                                      : productId == 2026
                                                                                          ? "挂历"
                                                                                          : productId == 2015
                                                                                              ? "手提袋"
                                                                                              : productId == 2039
                                                                                                  ? "吊旗"
                                                                                                  : productId == 2035
                                                                                                      ? "台历"
                                                                                                      : productId == 2032
                                                                                                          ? "封套"
                                                                                                          : productId == 2040
                                                                                                              ? "写真"
                                                                                                              : productId == 2041
                                                                                                                  ? "X型展架"
                                                                                                                  : productId == 2042
                                                                                                                      ? "易拉宝"
                                                                                                                      : productId == 2043
                                                                                                                          ? "名片"
                    : ""
                }}</span>
              </div>
            </div>
            <div class="hand_line">
              <div class="title">系统报价</div>
              <span>:</span>
              <div class="text">
                <span class="money">{{ disCountFee | filterMoney }}</span>
              </div>
            </div>
          </div>

          <div class="content">
            <!--手拎袋-->
            <div v-if="productId == 1015 ||productId == 2015">
              <table>
                <tr>
                  <th>成品尺寸(高*宽*厚)</th>
                  <th>印刷数量</th>
                  <th>印刷颜色</th>
                  <th>印刷纸张</th>
                </tr>
                <tr>
                  <td>{{ info.productSpec }}</td>
                  <td>{{ info.productNum }}</td>
                  <td>{{ info.printColor }}</td>
                  <td>{{ info.paperName }}</td>
                </tr>
                <tr class="packType">
                  <th>后道工艺</th>
                  <td colspan="4">{{ info.aftDesc }}</td>
                </tr>
                <tr class="packType">
                  <th>包装方式</th>
                  <td colspan="4">{{ info.packageType }}</td>
                </tr>
              </table>
            </div>

            <!--普通画册-->
            <div v-if="productId == 1020 || productId == 2020">
              <table>
                <tr>
                  <th>成品尺寸(宽*高)</th>
                  <th>印刷数量</th>
                  <th>装订方式</th>
                </tr>
                <tr>
                  <td>{{ info.productSpec }}</td>
                  <td>{{ info.productNum }}</td>
                  <td>{{ info.bindingType }}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>部件名称</th>
                  <th>P数/尺寸</th>
                  <th>印刷颜色</th>
                  <th>印刷纸张</th>
                  <th>后道工艺</th>
                </tr>
                <tr v-for="(item, index) in info.partsList" :key="index">
                  <td style="font-weight: bold">{{ item.partsName }}</td>
                  <td>{{ item.pNum }}</td>
                  <td>{{ item.printColor }}</td>
                  <td>{{ item.paperName }}</td>
                  <td>{{ item.aftDesc }}</td>
                </tr>
                <tr class="packType">
                  <td style="font-weight: bold">包装方式</td>
                  <td colspan="4">{{ info.packageType }}</td>
                </tr>
              </table>
            </div>

            <!--单页-->
            <div v-if="productId == 1028 || productId == 2028">
              <table>
                <tr>
                  <th>成品尺寸(宽*高)</th>
                  <th>印刷数量</th>
                  <th>印刷颜色</th>
                  <th>印刷纸张</th>
                  <th>后道工艺</th>
                </tr>
                <tr>
                  <td>{{ info.productSpec }}</td>
                  <td>{{ info.productNum }}</td>
                  <td>{{ info.printColor }}</td>
                  <td>{{ info.paperName }}</td>
                  <td>{{ info.aftDesc }}</td>
                </tr>
                <tr class="packType">
                  <th>包装方式</th>
                  <td colspan="4">{{ info.packageType }}</td>
                </tr>
              </table>
            </div>

            <!--封套-->
            <div v-if="productId == 1032 || productId == 2032">
              <table>
                <tr>
                  <th>成品尺寸(高*宽)</th>
                  <th>印刷数量</th>
                  <th>印刷颜色</th>
                  <th>印刷纸张</th>
                  <th>后道工艺</th>
                </tr>
                <tr>
                  <td>{{ info.productSpec }}</td>
                  <td>{{ info.productNum }}</td>
                  <td>{{ info.printColor }}</td>
                  <td>{{ info.paperName }}</td>
                  <td>{{ info.aftDesc }}</td>
                </tr>
                <tr class="packType">
                  <th>包装方式</th>
                  <td colspan="4">{{ info.packageType }}</td>
                </tr>
              </table>
            </div>

            <!--精装书-->
            <div v-if="productId == 1034 || productId == 2034">
              <table>
                <tr>
                  <th>成品尺寸(高*宽)</th>
                  <th>印刷数量</th>
                  <th>印刷颜色</th>
                </tr>
                <tr>
                  <td>{{ info.productSpec }}</td>
                  <td>{{ info.productNum }}</td>
                  <td>{{ info.bindingType }}</td>
                </tr>
                <tr class="packType">
                  <th>包装方式</th>
                  <td colspan="2">{{ orderProduct.packType }}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>部件名称</th>
                  <th>部件数量</th>
                  <th>印刷颜色</th>
                  <th>印刷纸张</th>
                  <th>后道工艺</th>
                </tr>
                <tr v-for="(item, index) in info.partsList" :key="index">
                  <td style="font-weight: bold">{{ item.partsName }}</td>
                  <td>{{ item.pNum }}</td>
                  <td>{{ item.printColor }}</td>
                  <td>{{ item.paperName }}</td>
                  <td>{{ item.aftDesc }}</td>
                </tr>
                <tr class="packType">
                  <td style="font-weight: bold">包装方式</td>
                  <td colspan="4">{{ info.packageType }}</td>
                </tr>
              </table>
            </div>

            <!--台历-->
            <div v-if="productId == 1035 || productId == 2035">
              <table>
                <tr>
                  <th>成品尺寸(x*y*z示例图)</th>
                  <th>印刷数量</th>
                  <th>装订方式</th>
                </tr>
                <tr>
                  <td>{{ info.productSpec }}</td>
                  <td>{{ info.productNum }}</td>
                  <td>{{ info.bindingType }}</td>
                </tr>
                <tr class="packType">
                  <th>包装方式</th>
                  <td colspan="3">{{ info.packageType }}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>部件名称</th>
                  <th>部件数量</th>
                  <th>印刷颜色</th>
                  <th>印刷纸张</th>
                  <th>后道工艺</th>
                </tr>
                <tr v-for="(item, index) in info.partsList" :key="index">
                  <td style="font-weight: bold">{{ item.partsName }}</td>
                  <td>{{ item.pNum }}</td>
                  <td>{{ item.printColor }}</td>
                  <td>{{ item.paperName }}</td>
                  <td>{{ item.aftDesc }}</td>
                </tr>
                <tr class="packType">
                  <td style="font-weight: bold">成品工艺</td>
                  <td colspan="4">{{ info.processName }}</td>
                </tr>
                <tr class="packType">
                  <td style="font-weight: bold">包装方式</td>
                  <td colspan="4">{{ info.packageType }}</td>
                </tr>
              </table>
            </div>

            <!--挂历-->
            <div v-if="productId == 1036 || productId == 2026">
              <table>
                <tr>
                  <th>成品尺寸(高*宽)</th>
                  <th>印刷数量</th>
                  <th>装订方式</th>
                </tr>
                <tr>
                  <td>{{ info.productSpec }}</td>
                  <td>{{ info.productNum }}</td>
                  <td>{{ info.bindingType }}</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>部件名称</th>
                  <th>部件数量</th>
                  <th>印刷颜色</th>
                  <th>印刷纸张</th>
                  <th>后道工艺</th>
                </tr>
                <tr v-for="(item, index) in info.partsList" :key="index">
                  <td style="font-weight: bold">{{ item.partsName }}</td>
                  <td>{{ item.pNum }}</td>
                  <td>{{ item.printColor }}</td>
                  <td>{{ item.paperName }}</td>
                  <td>{{ item.aftDesc }}</td>
                </tr>
                <tr class="packType">
                  <td style="font-weight: bold">包装方式</td>
                  <td colspan="4">{{ info.packageType }}</td>
                </tr>
              </table>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">关闭</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/invoice/invoice";
import picUrls from "../../api/picture";
export default {
  name: "inquiry",
  data() {
    return {
      allTotalMoney: 0, // 当前页总金额
      orderProduct: {},
      disCountFee: "",
      productId: "",
      info: {},
      userInfo: {
        userName: "",
        tel: "",
        time: "",
      },
      dialogVisible: false,
      title: "",
      total: 0,
      pageSize: 5,
      pageNumber: 1,
      tableData: [],
      picUrls: picUrls,
      startTime: "",
      endtime: "",
      checked: 0,
      list: [
        {
          id: 0,
          text: "询价记录",
        },
      ],
    };
  },
  created() {
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (obj) {
      this.userType = obj.userType;
    } else {
      return;
    }
    this.init();
  },
  filters: {
    filterMoney(value) {
      const itemVal = Number(value).toFixed(3);
      if (itemVal === "NaN") {
        return "0.00";
      }
      const realVal = itemVal.substring(0, itemVal.length - 1);
      return realVal;
    },
  },
  methods: {
    init() {
      api
        .inquirycecord(
          this.pageSize,
          this.pageNumber,
          this.startTime,
          this.endtime
        )
        .then((res) => {
          console.log("询价详情", res);
          this.tableData = res.data.pageInfo.list;
          this.total = res.data.pageInfo.total;
          this.allTotalMoney = res.data.orderMoney;
        });
    },
    // 订单详情
    orderInfo(item) {
      console.log(item);
      this.userInfo = {
        userName: item.userName,
        tel: item.phone,
        time: item.createTime.slice(0, 10),
      };
      this.disCountFee = item.disCountFee;
      this.title = `${item.userName}的产品询价`;
      let infos = JSON.parse(item.resultContext);
      console.log("infos", infos);
      let once = Object.assign({}, infos.orderProduct);
      console.log("once", once);
      this.orderProduct = once;
      this.productId = once.productId;
      this.info = JSON.parse(once.info);
      console.log(this.info);
      this.dialogVisible = true;
    },
    //查询
    query() {
      this.init();
    },
    //刷新
    refresh() {
      this.startTime = this.endtime = "";
      this.init();
    },
    // 切换
    replace(item) {
      console.log(item);
    },
    tableRowStyle() {
      return "padding:0;";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.inquiry {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .card-left {
    width: 200px;
    height: 320px;

    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;

      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }

    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
      }

      li:last-child {
        border-bottom: none;
      }

      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }
  }

  .card-right {
    width: 980px;
    background-color: #fff;
    .screen {
      margin-top: 10px;

      .colls {
        display: flex;
        line-height: 40px;
        margin: 15px 0 15px 0px;

        span {
          margin-right: 5px;
        }

        .kuoz {
          margin: 0 10px;
        }
      }
      .moneyTotal {
        line-height: 40px;
        margin-top: 15px;
      }
      .coll {
        margin-top: 15px;
      }
    }

    .tables {
      ::v-deep .el-table .cell {
        padding: 0;
      }

      ::v-deep .el-table .cell,
      ::v-deep .el-table--border td:first-child .cell {
        padding: 0;
      }

      ::v-deep .el-table_1_column_1 {
        padding: 0;
      }

      ::v-deep .el-table td {
        padding: 0;
      }

      .top {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #f8f8f8;
        color: #b4b4b4;

        span {
          color: #000000;
        }
      }
    }
    .top {
      margin-bottom: 20px;
      .hand_line {
        display: inline-flex;
        width: 50%;
        height: 30px;
        line-height: 30px;

        .title,
        span {
          font-weight: bold;
          color: black;
        }

        .title {
          width: 80px;
          text-align: justify;
          text-align-last: justify;
        }

        .text {
          border-bottom: 1px solid #eee;
          margin-left: 20px;
          color: #666;
          width: calc(100% - 80px);
          text-align: left;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .money {
            display: inline-block;
            color: #f29900;
          }
        }
      }
    }
    /*表格*/
    table {
      border: 1px solid #ebeef5;
      width: 100%;
      /*tr{*/
      /*    border-bottom: 1px solid #EBEEF5;*/
      /*}*/
      /*tr:last-of-type{*/
      /*    border-bottom: 0;*/
      /*}*/
      tr {
        th {
          color: #909399;
          font-size: 14px;
          font-weight: bold;
          padding: 12px 10px;
          text-align: center;
          border-right: 1px solid #ebeef5;
          border-bottom: 1px solid #ebeef5;
        }

        th:last-of-type {
          border-right: 0;
        }

        td {
          color: #606266;
          font-size: 14px;
          padding: 12px 10px;
          text-align: center;
          border-right: 1px solid #ebeef5;
          border-bottom: 1px solid #ebeef5;
        }

        td:last-of-type {
          border-right: 0;
        }

        .orange {
          color: #eb6d00;
          font-size: 20px;
        }
      }

      tr:last-of-type th {
        border-bottom: 0;
      }

      tr:last-of-type td {
        border-bottom: 0;
      }

      .packType th {
        border-right: 1px solid #ebeef5 !important;
      }

      .packType td {
        border-right: 1px solid #ebeef5 !important;
      }

      .packType td:last-of-type {
        border-right: 0 !important;
      }
    }
  }
}
</style>
