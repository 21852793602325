<template>
  <div>
    <div class="personal">
      <div class="nav">
        <div class="ban">
          <ul class="nav-top">
            <li class="yinyou">
              <!-- <i class="img">我的会员</i> -->
              <span class="myMember">我的会员</span>
            </li>
            <li v-for="item in tabList" :key="item.id" @click="changes(item)">
              <span>{{ item.text }}</span>
              <i :class="chiecked === item.id ? 'chiecked' : ''"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="ban">
        <!-- 个人信息 -->
        <person-info v-show="chiecked === 0"></person-info>
        <!-- 费用中心 -->
        <cost ref="cost" v-show="chiecked === 1"></cost>
        <!-- 订单状态 -->
        <person-order
          ref="order"
          v-show="chiecked === 2"
          @jumpNav="orderCome"
        ></person-order>
        <!-- 投诉评价 -->
        <evaluate ref="luate" v-show="chiecked === 3"></evaluate>
        <!-- 会员管理 -->
        <members ref="members" v-show="chiecked === 4"></members>
        <!-- 询价管理 -->
        <inquiry ref="inquiry" v-show="chiecked === 5"></inquiry>
      </div>
    </div>
  </div>
</template>

<script>
import personInfo from "../../components/personal/info";
import cost from "../../components/personal/cost";
import personOrder from "../../components/personal/order";
import evaluate from "../../components/personal/evaluate";
// import { getusername } from "../../api/myAuth";
import members from "../../components/personal/members.vue";
import inquiry from "@/components/personal/inquiry";
export default {
  name: "Personal",
  components: {
    personInfo,
    cost,
    personOrder,
    evaluate,
    members,
    inquiry,
  },
  data() {
    return {
      chiecked: 2,
      nav: [
        {
          id: 0,
          text: "会员信息",
        },
        {
          id: 1,
          text: "费用中心",
        },
        {
          id: 2,
          text: "订单状态",
        },
        {
          id: 3,
          text: "我的投诉",
        },
        {
          id: 4,
          text: "会员管理",
        },
        {
          id: 5,
          text: "询价记录",
        },
      ],
      userType: 0,
    };
  },
  computed: {
    tabList() {
      if (this.userType === 0) {
        return this.nav.slice(0, 4);
      } else {
        return this.nav;
      }
    },
  },
  created() {
    let userObj = JSON.parse(window.sessionStorage.getItem("userInfo"));
    if (userObj === null) {
      // this.$message.warning("请先登录！");
      this.$router.push({ path: "/login" });
      return;
    }
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (obj) {
      this.userType = obj.userType;
      if (this.userType !== 0) {
        this.nav[3].text = "客户投诉";
      }
    }
    let id = this.$route.params.chiecked;
    let jump = this.$route.params.jump; // 头部所有订单跳转
    console.log(this.$route.params.chiecked);
    if (id) {
      this.chiecked = id;
    }
    if (jump) {
      this.chiecked = jump;
    }
  },
  mounted() {
    if (this.chiecked === 2) {
      console.log("执行了跳转");
      // this.$refs.order.init();
    }
  },
  methods: {
    // 投诉跳转
    orderCome() {
      console.log("触发了父组件事件");
      this.chiecked = 3;
      this.$refs.luate.getcomplaint();
    },
    // 动态添加下标
    changes(item) {
      this.chiecked = item.id;
      if (item.id === 1) {
        this.$refs.cost.getUserInfo();
      }
      if (item.id === 2) {
        this.$refs.order.init();
      }
      if (item.id === 3) {
        this.$refs.luate.getcomplaint();
      }
      if (item.id === 4) {
        console.log("执行了这里");
        this.$refs.members.init();
      }
      if (item.id === 5) {
        this.$refs.inquiry.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.personal {
  width: 100%;
  background-color: #f8f8f8;
  .nav {
    width: 100%;
    background-color: #0289f2;
    .nav-top {
      display: flex;
      li {
        width: 160px;
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: #fff;
        position: relative;
        cursor: pointer;
        .chiecked {
          width: 12px;
          height: 8px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          background: url("../../static/imgs/icon_triangle_white.png") no-repeat
            center;
          background-size: cover;
        }
      }
      .yinyou {
        width: 160px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background-color: #047bd7;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // .img {
        //   display: block;
        //   width: 98px;
        //   height: 23px;
        //   background: url("../../static/imgs/personalCenter/mineyy.png")
        //     no-repeat center;
        // }
        .myMember {
          display: block;
          font-size: 20px;
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
  .ban {
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
  }
}
</style>
