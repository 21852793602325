import request from "@/api/request";
// const basUrl = "http://cpsapi.yy12365.com";
// const localUrl = "http://192.168.0.113";

// 查询地址列表
function getAddressList(id, data) {
  return request({
    method: "get",
    url: `/receiveadderss/listAll`,
    data,
  });
}
// 查询地址详情
function queryAddress(id, data) {
  return request({
    method: "get",
    url: `/receiveadderss/${id}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// 新增收货地址
function addAddress(data) {
  return request({
    method: "post",
    url: "/receiveadderss",
    data,
  });
}

// 修改默认收货地址
function editAddress(id, data) {
  return request({
    method: "put",
    url: `/receiveadderss/${id}`,
    data,
  });
}
// 删除地址
function delAddress(id, data) {
  return request({
    method: "delete",
    url: `/receiveadderss/${id}`,
    data,
  });
}

// 修改密码
function editPassword(phone, old, news, data) {
  return request({
    method: "PUT",
    url: `/user/updatePwd?phone=${phone}&oldPwd=${old}&newPwd=${news}`,
    data,
  });
}
export default {
  getAddressList,
  addAddress,
  editAddress,
  queryAddress,
  delAddress,
  editPassword,
};
