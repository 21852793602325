<template>
  <div class="evaluate">
    <div class="card-left">
      <div class="top">
        <i class="iconfont icon-iconcopy"></i>
        <span>投诉管理</span>
      </div>
      <ul class="infos">
        <li
          class="info-item"
          :class="checked === item.id ? 'checked' : ''"
          v-for="item in Info"
          :key="item.id"
          @click="replace(item)"
        >
          {{ item.text }}
        </li>
      </ul>
    </div>
    <div class="card-right">
      <div class="info">
        <div class="card">
          <div class="card-top">
            <span></span>
            <h3>我的投诉</h3>
          </div>
        </div>
        <div class="query">
          <el-input
            class="input"
            v-model="querys"
            placeholder="请输入订单号"
          ></el-input>
          <el-select v-model="value2" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button class="btn" type="primary" @click="getcomplaint"
            >查询
          </el-button>
          <el-button @click="refreshs">刷新</el-button>
        </div>
        <!--表格-->
        <div class="card-form table">
          <el-table :data="getcomplaintList" border style="width: 100%">
            <el-table-column header-align="center" label="投诉编号">
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
            <el-table-column header-align="center" label="订单编号" width="200">
              <template slot-scope="scope">
                {{ scope.row.orderNumber }}
              </template>
            </el-table-column>
            <el-table-column header-align="center" label="投诉内容">
              <template slot-scope="scope">
                {{ scope.row.detailstext }}
              </template>
            </el-table-column>
            <el-table-column header-align="center" label="投诉状态">
              <template slot-scope="scope">
                {{ scope.row.extendColumn1 === "0" ? "待处理" : "已处理" }}
              </template>
            </el-table-column>
            <el-table-column header-align="center" label="发起时间" width="200">
              <template slot-scope="scope">
                {{ scope.row.createTime }}
              </template>
            </el-table-column>
            <el-table-column header-align="center" label="处理时间" width="200">
              <template slot-scope="scope">
                {{ scope.row.extendColumn3 }}
              </template>
            </el-table-column>

            <el-table-column
              header-align="center"
              label="操作"
              width="200"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-link
                  @click="details(scope.row)"
                  :underline="false"
                  type="primary"
                  >投诉详情
                </el-link>
                <el-link
                  style="margin-left: 10px"
                  @click="orderDetails(scope.row)"
                  :underline="false"
                  type="primary"
                  >订单详情
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 投诉内容弹框 -->
        <el-dialog
          :visible.sync="complaints"
          class="detail"
          title="投诉单详情"
          width="55%"
        >
          <div class="detail_top">
            <!--            <img :src="picUrls+complaintsdetail.productimage" alt=""/>-->
            <div>
              <p>投诉日期：{{ complaintsdetail.createTime }}</p>
            </div>
            <div>*{{ complaintsdetail.orderNumber }}*</div>
          </div>
          <div class="hand">
            <h2>客户投诉处理单(ORDER)</h2>
            <div class="hand_line">
              <div class="title">客户名称</div>
              <span>:</span>
              <div class="text">{{ complaintInfo.customername }}</div>
            </div>
            <div class="hand_line">
              <div class="title">印件名称</div>
              <span>:</span>
              <div class="text">{{ complaintInfo.ordername }}</div>
            </div>
            <div class="hand_line">
              <div class="title">订单编号</div>
              <span>:</span>
              <div class="text">{{ complaintsdetail.id }}</div>
            </div>
            <div class="hand_line">
              <div class="title">产品数量</div>
              <span>:</span>
              <div class="text">{{ complaintInfo.quantitydemanded }}</div>
            </div>
            <div class="hand_line">
              <div class="title">订单金额</div>
              <span>:</span>
              <div class="text">{{ complaintInfo.discountfee }}</div>
            </div>
            <div class="hand_line">
              <div class="title">投诉分类</div>
              <span>:</span>
              <div class="text">{{ complaintsType }}</div>
            </div>
            <div class="hand_line" id="hand_line">
              <div class="title">处理结果</div>
              <span>:</span>
              <div class="text" @click="textChange" id="text">
                {{ complaintsdetail.extendColumn2 }}
              </div>
            </div>
            <div class="hand_line">
              <div class="title">处理日期</div>
              <span>:</span>
              <div class="text">{{ complaintsdetail.extendColumn3 }}</div>
            </div>
            <div class="cont">
              <div class="title">投诉内容:</div>
              <div
                class="text"
                v-html="complaintsdetail.complaintsContext"
              ></div>
            </div>
          </div>

          <!--          &lt;!&ndash;投诉分类&ndash;&gt;-->
          <!--          <el-form ref="form" :rules="rule" :model="form" label-width="100px" class="fwb">-->
          <!--            <el-form-item label="投诉分类：" prop="types">-->
          <!--              <el-checkbox-group v-model="form.types" @change="CheckedChange">-->
          <!--                <el-checkbox v-for="item in ComplaintClassification" :label="item"-->
          <!--                             :value="item.id" :key="item.id">{{item.name}}-->
          <!--                </el-checkbox>-->
          <!--              </el-checkbox-group>-->
          <!--            </el-form-item>-->
          <!--            &lt;!&ndash;投诉内容&ndash;&gt;-->
          <!--            &lt;!&ndash;                <el-form ref="rule" :model="form" label-width="100px" class="fwb">&ndash;&gt;-->
          <!--            <el-form-item label="投诉内容：" prop="text">-->
          <!--              <editor-->
          <!--                      v-model.trim="form.text"-->
          <!--                      @change="change"-->
          <!--                      :info="form.text"-->
          <!--              ></editor>-->
          <!--            </el-form-item>-->
          <!--            <el-form-item>-->
          <!--              <el-button type="primary" @click="submitForm('form')">立即投诉</el-button>-->
          <!--              <el-button @click="resetForm('form')">重置</el-button>-->
          <!--            </el-form-item>-->

          <!--          </el-form>-->
        </el-dialog>
        <!-- 订单详情弹框 -->
        <el-dialog title="订单详情" :visible.sync="dialogVisible" width="60%">
          <simple-details :key="timer" :orderId="orderId"></simple-details>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">关闭</el-button>
          </span>
        </el-dialog>
        <!--分页-->
        <div class="pages">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[5, 10, 20, 30]"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import picUrl from "../../api/picture";
import api from "../../api/webAdmin/webAdmin";
import simpleDetails from "../../components/OrderDetails/simpleDetails.vue";
export default {
  components: { simpleDetails },
  name: "evaluate",
  data() {
    return {
      value2: "",
      dialogVisible: false,
      options: [
        {
          label: "未处理",
          value: "0",
        },
        {
          label: "已处理",
          value: "1",
        },
      ],
      querys: "", // 查询条件
      checked: 0,
      total: 0,
      currentPage: 1,
      pageSize: 5,
      Info: [
        {
          id: 0,
          text: "我的投诉",
        },
      ],
      //获取投诉列表
      getcomplaintList: [],
      complaints: false, // 投诉详情弹窗打开
      complaintsdetail: {},
      picUrls: "",
      complaintInfo: {},

      complaintsType: "", //投诉详情_投诉分类
      orderId: "",
      timer: "",
      user_ID: "",
    };
  },
  created() {
    this.picUrls = picUrl.picUrl;
    let userInfo = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (userInfo) {
      this.user_ID = userInfo.user_ID;
    } else {
      return;
    }
    this.getcomplaint();
  },
  methods: {
    replace(item) {
      if (item.id === 0) {
        this.getcomplaint();
      }
    },
    orderDetails(item) {
      console.log(item);
      this.timer = new Date().getTime();
      this.orderId = item.orderNumber;
      console.log("订单id", this.orderId);
      this.dialogVisible = true;
    },
    // 刷新查询
    refreshs() {
      this.value2 = this.querys = "";
      this.getcomplaint();
    },
    //获取投诉
    getcomplaint() {
      this.$http
        .get(
          `/ordercomplaints?pageSize=${this.pageSize}&pageNumber=${this.currentPage}&extendColumn1=${this.value2}&orderNumber=${this.querys}`
        )
        .then((res) => {
          console.log("获取投诉列表", res);
          if (res.data.code == 200) {
            // this.$message({
            //   message: "投诉成功",
            //   type: "success",
            // });
            this.total = res.data.data.total;
            this.getcomplaintList = res.data.data.list;

            for (let a = 0; a < this.getcomplaintList.length; a++) {
              this.getcomplaintList[a].list = [];
              this.getcomplaintList[a].details = [];
              this.getcomplaintList[a].detailstext = "";
              this.getcomplaintList[a].list = this.getcomplaintList[
                a
              ].complaintsType.split(",");
              for (let c = 0; c < this.getcomplaintList[a].list.length; c++) {
                if (this.getcomplaintList[a].list[c] == 0) {
                  this.getcomplaintList[a].details.push("质量");
                } else if (this.getcomplaintList[a].list[c] == 1) {
                  this.getcomplaintList[a].details.push("数量");
                } else if (this.getcomplaintList[a].list[c] == 2) {
                  this.getcomplaintList[a].details.push("交期");
                } else if (this.getcomplaintList[a].list[c] == 3) {
                  this.getcomplaintList[a].details.push("物流");
                } else if (this.getcomplaintList[a].list[c] == 4) {
                  this.getcomplaintList[a].details.push("包装");
                } else if (this.getcomplaintList[a].list[c] == 5) {
                  this.getcomplaintList[a].details.push("服务");
                } else if (this.getcomplaintList[a].list[c] == 6) {
                  this.getcomplaintList[a].details.push("印前");
                } else if (this.getcomplaintList[a].list[c] == 7) {
                  this.getcomplaintList[a].details.push("其他");
                }
              }
              for (
                let b = 0;
                b < this.getcomplaintList[a].details.length;
                b++
              ) {
                this.getcomplaintList[a].detailstext =
                  this.getcomplaintList[a].detailstext +
                  this.getcomplaintList[a].details[b] +
                  "，";
              }
              // 去掉最后一个逗号
              if (this.getcomplaintList[a].detailstext.length != 0) {
                this.getcomplaintList[a].detailstext = this.getcomplaintList[
                  a
                ].detailstext.substring(
                  0,
                  this.getcomplaintList[a].detailstext.length - 1
                );
              }
            }

            console.log(this.getcomplaintList);
          } else {
            this.$message.error("查询失败");
          }
        });
    },

    //投诉详情
    details(item) {
      console.log(item);
      this.complaints = true;
      this.complaintsdetail = item;
      let array = item.complaintsType.split(",");
      console.log(array);
      let list = [];
      this.complaintsType = "";
      for (let a = 0; a < array.length; a++) {
        if (array[a] == 0) {
          list.push("质量");
        } else if (array[a] == 1) {
          list.push("数量");
        } else if (array[a] == 2) {
          list.push("交期");
        } else if (array[a] == 3) {
          list.push("物流");
        } else if (array[a] == 4) {
          list.push("包装");
        } else if (array[a] == 5) {
          list.push("服务");
        } else if (array[a] == 6) {
          list.push("印前");
        } else if (array[a] == 7) {
          list.push("其他");
        }
      }

      for (let a = 0; a < list.length; a++) {
        this.complaintsType = this.complaintsType + list[a] + "，";
      }
      console.log(this.complaintsType);
      // 去掉最后一个逗号
      if (this.complaintsType.length != 0) {
        this.complaintsType = this.complaintsType.substring(
          0,
          this.complaintsType.length - 1
        );
      }

      api
        .complaintInfo(item.id)
        .then((res) => {
          console.log("获取投诉详情", res);
          if (res.success) {
            this.complaintInfo = res.data.orderInfo;
          } else {
            this.$message.error("获取详情失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },

    textChange() {
      let test = document.getElementById("text"),
        demo = window.getComputedStyle(test, null);
      let hand_line = document.getElementById("hand_line"),
        demo2 = window.getComputedStyle(hand_line, null);
      //overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      if (demo.overflow == "hidden" || demo.whiteSpace == "nowrap") {
        test.style.overflow = "initial";
        test.style.whiteSpace = "normal";
        test.style.height = "60px";
        hand_line.style.height = "60px";
      } else if (demo.overflow == "initial" || demo.whiteSpace == "normal") {
        test.style.overflow = "hidden";
        test.style.whiteSpace = "nowrap";
        test.style.height = "30px";
        hand_line.style.height = "30px";
      }
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getcomplaint();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getcomplaint();
    },
  },
};
</script>

<style lang="scss" scoped>
.evaluate {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .card-left {
    width: 200px;

    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;

      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }

    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
      }

      li:last-child {
        border-bottom: none;
      }

      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }

    .card {
      width: 100%;

      .card-top {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #e6e6e6;
        padding: 0 30px;
        line-height: 50px;

        span {
          display: inline-block;
          width: 5px;
          height: 17px;
          background: #058af2;
          margin-right: 20px;
        }

        h3 {
          color: #058af2;
        }
      }
    }
  }

  .query {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .input {
      width: 30%;
      margin: 0 10px;
    }

    .btn {
      margin-left: 10px;
    }
  }

  .card-right {
    width: 980px;
    background-color: #fff;
  }

  .info {
    width: 100%;
    box-sizing: border-box;
    // padding: 15px;
  }

  .card {
    width: 100%;

    .card-top {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #e6e6e6;
      padding: 0 30px;
      line-height: 50px;

      span {
        display: inline-block;
        width: 5px;
        height: 17px;
        background: #058af2;
        margin-right: 20px;
      }

      h3 {
        width: 70%;
        color: #058af2;
      }

      .fapiao {
        width: 300px;
        color: #058af2;
      }

      .info {
        margin-left: 20px;
        color: #047bd7;
        cursor: pointer;
      }
    }

    .table {
      margin: 15px;
    }
  }

  /*表格*/
  .table {
    margin-right: 0;
    border-top: 1px dashed #e6e6e6;
  }

  /*分页*/
  .pages {
    display: flex;
    margin: 20px 20px;
    justify-content: space-between;
  }
}

/*订单详情*/
.detail {
  .detail_top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    img {
      width: 30px;
      height: 30px;
      border: 1px solid #f7f7f7;
    }

    p {
      text-align: left;
    }
  }

  .hand {
    h2 {
      margin: 15px 0;
      text-align: center;
    }

    .hand_line {
      display: inline-flex;
      width: 50%;
      height: 30px;
      line-height: 30px;

      .title,
      span {
        font-weight: bold;
        color: black;
      }

      .title {
        width: 80px;
        text-align: justify;
        text-align-last: justify;
      }

      .text {
        border-bottom: 1px solid #eee;
        margin-left: 20px;
        color: #666;
        width: calc(100% - 80px);
        text-align: left;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .cont {
      width: 100%;

      .title {
        width: 80px;
        text-align: justify;
        text-align-last: justify;
        font-weight: bold;
        color: black;
      }
    }

    h4 {
      color: red;
      text-align: center;
      margin: 10px 0;
    }
  }
}
</style>

<style lang="scss">
.detail {
  .el-dialog {
    /*height: 75%;*/
    height: auto !important;
    overflow: auto;

    .el-dialog__header {
      padding: 20px 20px 20px;
      // position: fixed;
      background: #fff;
      width: calc(55% - 40px);
    }

    // .el-dialog__body {
    //   margin-top: 64px;
    // }
  }
}
</style>
