<template>
  <div>
    <div class="personalInfo">
      <div class="card-left">
        <div class="top">
          <i class="iconfont icon-iconcopy"></i>
          <span>账户管理</span>
        </div>
        <ul class="infos">
          <li
            class="info-item"
            :class="checked === item.id ? 'checked' : ''"
            v-for="item in userInfo"
            :key="item.id"
            @click="replace(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="card-right">
        <!-- 账户充值 -->
        <div class="info recharge" v-if="checked === 0">
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>账户充值</h3>
              <p class="infos" @click="showDigs">钱包明细</p>
            </div>
            <div class="card-form">
              <el-form
                :model="dynamicValidateForm"
                ref="dynamicValidateForm"
                :rules="rules"
                label-width="100px"
                class="demo-dynamic"
                key="1"
              >
                <el-form-item prop="recharge" label="充值金额:">
                  <el-input
                    v-model.trim="dynamicValidateForm.recharge"
                    :disabled="disabled"
                    style="width: 30%; padding-right: 12px"
                  ></el-input>
                  元
                  <el-button type="primary" v-if="disabled" @click="editQuota"
                    >修改
                  </el-button>
                  （可用金额：<span class="money">{{ allMoney }}</span
                  >元）
                </el-form-item>
              </el-form>
              <el-row style="width: 500px; margin: -20px 0 0 -40px" v-if="payMoneyData.length > 0">
                <el-col :span="6" v-for="item in payMoneyData" :key="item.id" style="padding: 10px">
                  <el-button @click="clickRechargeConfig(item)" style="width: 110px" :disabled="disabled">
                    <span style="font-size: 18px">{{ (item.recharge || item.recharge !== '') ? item.recharge : '0' }}元</span>
                    <br>
                    <span style="font-size: 10px; color: red; margin-top: 5px">赠{{ item.amount }}元</span>
                  </el-button>
                </el-col>
              </el-row>
            </div>

            <div class="card-form payment">
              <div class="text">请选择支付平台：</div>
              <div class="pingtai">
                <ul>
                  <li>
                    <div class="wx" @click="bills('wx')"></div>
                  </li>
                  <li>
                    <div class="zfb" @click="bills('zfb')"></div>
                  </li>
                </ul>
              </div>
              <div class="img" v-if="img !== ''">
                <vue-qr :text="img" :size="200"></vue-qr>
              </div>
              <p v-if="showText === 1">请用微信支付</p>
              <p v-if="showText === 2">请用支付宝支付</p>
            </div>
            <div class="btns" v-if="showPsBtn">
              <el-button type="primary" @click="surePay"
                >是否支付成功
              </el-button>
              <i class="el-icon-warning">如果支付成功请点击此按钮</i>
            </div>
          </div>
        </div>
        <!-- 支付密码设置 -->
        <div class="info recharge" v-if="checked === 1">
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>设置支付密码</h3>
            </div>
            <div class="card-form">
              <el-form
                :model="ruleForm"
                status-icon
                :rules="rulepass"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
                key="5"
              >
                <el-form-item label="手机号">
                  <el-input
                    type="text"
                    v-model="phone"
                    autocomplete="off"
                    disabled
                  ></el-input>
                </el-form-item>

                <el-form-item label="支付密码" prop="pass">
                  <el-input
                    type="password"
                    v-model="ruleForm.pass"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                  <el-input
                    type="password"
                    v-model="ruleForm.checkPass"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="verification">
                  <el-row :gutter="10">
                    <el-col :span="15">
                      <el-input
                        type="text"
                        v-model="ruleForm.verification"
                        autocomplete="off"
                      ></el-input>
                    </el-col>
                    <el-col :span="8">
                      <el-button
                        style="width: 100%"
                        type="primary"
                        v-html="html"
                        :disabled="disabled1"
                        @click="getVer"
                      ></el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')"
                    >提交
                  </el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <!-- 消费记录 -->
        <div class="info bill" v-if="checked === 2">
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>消费记录</h3>
            </div>

            <!--筛选条件-->
            <div class="card-form screen">

                <span class="span"          v-if="userType == 1">客户名称:</span>
                <el-input
                    v-if="userType == 1"
                    v-model="userName"
                    placeholder="请选择客户"
                    clearable
                    @focus="changeMember"
                    style="width: 160px;"
                ></el-input>

              <div>按日期查询:</div>
              <el-date-picker
                style="width: 15%"
                v-model="starttime"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>

              <div>-</div>

              <el-date-picker
                style="width: 15%"
                v-model="endtime"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>



              <el-form style="display: flex" key="0">
                <!-- <el-form-item label="类型:" style="display: flex">
                  <el-select
                    v-model="type"
                    placeholder="请选择"
                    style="width: 100px"
                  >
                    <el-option label="全部" value="all"></el-option>
                    <el-option label="收入" value="shouru"></el-option>
                    <el-option label="支出" value="zhichu"></el-option>
                  </el-select>
                </el-form-item> -->

                <el-form-item>
                  <el-button type="primary" @click="query">查询</el-button>
                </el-form-item>
              </el-form>
            </div>

            <!--表格-->
            <div class="tops">

              <p>充值金额：{{ rechargeSumMoney }}元</p>
              <p>订单总金额：{{ orderSumMoney }}元</p>
              <p>已支付金额：{{ orderPaySumMoney }}元</p>
              <p>当前余额：{{ balanceSumMoney }}元</p>
            </div>
            <div class="card-form table">
              <el-table
                key="200"
                :data="tableData"
                style="width: 100%"
                @expand-change="onceClick"
              >
                <el-table-column type="expand" label="支付详情" width="180">
                  <template slot-scope="scoped">
                    <!-- <div class="orderInfo">
                      <span>订单支付详情：</span>

                      <div v-for="item in scope.row.children" :key="item.id">
                        <span>
                          {{
                            item.incomeType === 0
                              ? "余额支付"
                              : item.incomeType === 1
                              ? "微信支付"
                              : item.incomeType === 2
                              ? "支付宝支付"
                              : item.incomeType === 3
                              ? "信用支付"
                              : ""
                          }}
                        </span>
                        <span>{{ item.money }}元</span>
                      </div>
                    </div> -->
                    <el-table
                      :data="scoped.row.children"
                      style="width: 100%"
                      border
                    >
                      <el-table-column
                        prop="extendColumn1"
                        label="订单详情"
                        align="center"
                        header-align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="createTime"
                        label="支付时间"
                        align="center"
                        header-align="center"
                      >
                      </el-table-column>
                      <el-table-column prop="name" label="付款方式">
                        <template slot-scope="scope">
                          <span>
                            {{
                              scope.row.incomeType === 0
                                ? "余额支付"
                                : scope.row.incomeType === 1
                                ? "微信支付"
                                : scope.row.incomeType === 2
                                ? "支付宝支付"
                                : scope.row.incomeType === 3
                                ? "信用支付"
                                : scope.row.incomeType === 5
                                ? "线下补款"
                                : ""
                            }}
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="discountFee" label="订单金额">
                      </el-table-column>
                      <el-table-column prop="money" label="支付金额">
                      </el-table-column>
                      <el-table-column prop="userLastMoney" label="余额">
                      </el-table-column>
                      <!-- <el-table-column label="未支付金额">
                        <template slot-scope="scopeds">
                          <span style="color: #ff0000">{{
                            Number(scopeds.row.discountFee) -
                            Number(scoped.row.money)
                          }}</span>
                        </template>
                      </el-table-column> -->
                      <!-- <el-table-column label="测试金额">
                        <template slot-scope="scopeds">
                          <el-button
                            type="primary"
                            @click="textMoney(scopeds.row, scoped.row)"
                            >测试</el-button
                          >
                        </template>
                      </el-table-column> -->
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" label="记录时间" width="180">
                </el-table-column>
                <el-table-column prop="ordeRnumber" label="订单号" width="260">
                </el-table-column>
                <el-table-column prop="money" label="支出"></el-table-column>
                <!-- <el-table-column prop="userLastMoney" label="当前余额">
                </el-table-column> -->
              </el-table>
            </div>
            <!--分页-->
            <div class="pages">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="billForm.currentPage"
                :page-sizes="[2, 5, 10, 15]"
                :page-size="billForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- 未结款订单 -->
        <div class="info bill" v-if="checked === 3">
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>未结款订单</h3>
            </div>

            <!--筛选条件-->
            <div class="card-form screen">
              <div>按日期查询:</div>
              <el-date-picker
                style="width: 15%"
                v-model="starttime3"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>

              <div>-</div>

              <el-date-picker
                style="width: 15%"
                v-model="endtime3"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>

              <el-form style="display: flex" key="0">
                <el-form-item>
                  <el-button type="primary" @click="query">查询</el-button>
                </el-form-item>
              </el-form>
            </div>

            <!--表格-->
            <!-- <div class="tops">
              <p>订单总金额：{{ orderSumMoney }}元</p>
              <p>已支付金额：{{ orderPaySumMoney }}元</p>
            </div> -->
            <div class="card-form table">
              <el-table key="300" :data="tableData3" border style="width: 100%">
                <el-table-column
                  prop="ordername"
                  label="产品名称"
                  align="center"
                  header-align="center"
                ></el-table-column>
                <el-table-column
                  prop="customername"
                  label="客户名称"
                  align="center"
                  header-align="center"
                ></el-table-column>
                <el-table-column
                  prop="name"
                  label="付款方式"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.payStruts === 5">
                      {{
                        scope.row.whetherFullPayment === 0
                          ? "全款"
                          : scope.row.whetherFullPayment === 1
                          ? "30% 预付款"
                          : "线下付款"
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ordertime"
                  label="下单时间"
                ></el-table-column>
                <el-table-column prop="id" label="订单号"></el-table-column>
                <el-table-column
                  prop="discountfee"
                  label="订单金额"
                ></el-table-column>
                <el-table-column prop="discountfee" label="支付金额">
                  <template slot-scope="scope">
                    <span>
                      {{ numberData(1, scope.row.discountfee) }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="discountfee" label="补款金额">
                  <template slot-scope="scope">
                    <span>
                      {{ numberData(2, scope.row.discountfee) }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="userType === 0"
                  prop="discountfee"
                  label="操作"
                >
                  <template slot-scope="scope">
                    <el-button @click="paySet(scope.row)" type="primary">
                      补款
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!--分页-->
            <div class="pages">
              <!-- <span style="font-size: 14px; margin: auto 0">共 {{ this.tableData.length }} 条</span> -->
              <!--引入分页-->
              <el-pagination
                @size-change="handleSizeChange3"
                @current-change="handleCurrentChange3"
                :current-page="billForm3.currentPage"
                :page-sizes="[2, 5, 10, 15]"
                :page-size="billForm3.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total3"
              >
              </el-pagination>
              <!-- <pages ref="pages" @getTableData="getTableData"></pages> -->
              <!--<el-pagination page-size="1" background layout="prev, pager, next" :total="this.tableData.length">-->
              <!--</el-pagination>-->
            </div>
          </div>
        </div>
        <!-- 发票申请 -->
        <div class="info invoice" v-if="checked === 4">
          <div class="card">
            <div class="card-top" style="justify-content: space-between">
              <div
                style="
                  display: flex;
                  align-items: center;
                  height: 50px;
                  border-bottom: 1px solid #e6e6e6;
                  line-height: 50px;
                "
              >
                <span></span>
                <h3 class="fapiao">发票资料</h3>
              </div>
            </div>
            <el-button type="primary" @click="addIncrease">新增发票</el-button>

            <el-table :data="increaseList" border style="width: 100%">
              <el-table-column
                label="发票类型"
                text-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.increaseTicketType
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="开票类型"
                text-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.extendColumn1 === "1" ? "个人" : "单位"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="个人抬头"
                text-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.increaseTicketTitle
                      ? scope.row.increaseTicketTitle
                      : "----"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="单位名称"
                text-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.unitName ? scope.row.unitName : "----"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="收票人"
                text-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.recipientSName }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="收票地址"
                text-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.recipientSProvinceZh +
                    scope.row.recipientSCityZh +
                    scope.row.recipientSAreaZh +
                    scope.row.recipientSAdress
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                text-align="center"
                align="center"
                width="220"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    @click="editIncrease(scope.row)"
                    type="warning"
                    >修改
                  </el-button>
                  <el-button
                    @click="IncreaseInfo(scope.row)"
                    size="mini"
                    type="primary"
                    >详情
                  </el-button>
                  <el-button
                    @click="delSubmit(scope.row)"
                    size="mini"
                    type="danger"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="page">
              <el-pagination
                @size-change="increaseSizeChange"
                @current-change="increaseCurrentChange"
                :current-page="increases.currentPage"
                :page-sizes="[10, 20, 30]"
                :page-size="increases.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="increaseTotals"
              >
              </el-pagination>
            </div>
            <!-- 增加修改发票 -->
            <el-dialog
              title="新增发票"
              :visible.sync="dialogFormVisible"
              width="40%"
            >
              <!--发票票单-->
              <el-form
                ref="form"
                :model="form"
                :rules="rule"
                label-width="100px"
                key="2"
              >
                <el-form-item label="发票类型" prop="increaseTicketType">
                  <el-select
                    v-model="form.increaseTicketType"
                    placeholder="请选择"
                    @change="changes"
                  >
                    <el-option label="增值税普票" value="增值税普票"></el-option>
                    <el-option label="增值税专票" value="增值税专票"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开票类型" prop="extendColumn1">
                  <el-select
                    v-model="form.extendColumn1"
                    placeholder="请选择"
                    @change="changes1"
                  >
                    <el-option label="单位" value="2"></el-option>
                    <el-option
                      v-show="showInfo"
                      label="个人"
                      value="1"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div class="personal" v-show="form.extendColumn1 === '1'">
                  <el-form-item label="开票抬头" prop="increaseTicketTitle">
                    <el-input
                      style="width: 65%"
                      v-model="form.increaseTicketTitle"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="compony" v-show="form.extendColumn1 === '2'">
                  <el-form-item label="单位名称" prop="unitName">
                    <el-input
                      style="width: 65%"
                      v-model="form.unitName"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="发票税号" prop="increaseTicketNumber">
                    <el-input
                      style="width: 65%"
                      v-model="form.increaseTicketNumber"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="注册地址" prop="increaseTicketAdress">
                    <el-input
                      style="width: 65%"
                      v-model="form.increaseTicketAdress"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="注册电话" prop="increaseTicketPhone">
                    <el-input
                      style="width: 65%"
                      v-model="form.increaseTicketPhone"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="开户银行" prop="increaseTicketBank">
                    <el-input
                      style="width: 65%"
                      v-model="form.increaseTicketBank"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="银行账号"
                    prop="increaseTicketBankNumber"
                  >
                    <el-input
                      style="width: 65%"
                      v-model="form.increaseTicketBankNumber"
                    ></el-input>
                  </el-form-item>
                </div>
                <el-form-item label="收票人姓名" prop="recipientSName">
                  <el-input
                    style="width: 65%"
                    v-model="form.recipientSName"
                  ></el-input>
                </el-form-item>
                <el-form-item label="收票人电话" prop="recipientSPhone">
                  <el-input
                    style="width: 65%"
                    v-model="form.recipientSPhone"
                  ></el-input>
                </el-form-item>
                <el-form-item label="邮寄地址" prop="address">
                  <el-cascader
                    style="width: 65%"
                    size="large"
                    :options="options"
                    v-model="form.address"
                    @change="handleChange"
                  >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="recipientSAdress">
                  <el-input
                    style="width: 65%"
                    v-model.trim="form.recipientSAdress"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item label="开票金额" prop="invoicemoney">
                              <el-input
                                style="width: 53%"
                                v-model="form.invoiceMoney"
                              ></el-input>
                            </el-form-item> -->
                <!-- <el-form-item label="申请时间">
                              <el-col :span="11">
                                <el-date-picker
                                  type="date"
                                  placeholder="选择日期"
                                  v-model="form.date1"
                                  style="width: 115%"
                                  readonly
                                ></el-date-picker>
                              </el-col>
                            </el-form-item> -->
                <!-- <el-form-item style="width: 330px">
                <el-button v-if="showBtn" type="primary" @click="onSubmit(1)"
                  >提交
                </el-button>
                <div class="footer" v-else>
                  <el-button type="warning" @click="onSubmit(2)"
                    >修改
                  </el-button>
                  <el-popover placement="top" width="160" v-model="visible">
                    <p>确定要删除当前发票存根吗？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="visible = false"
                        >取消
                      </el-button>
                      <el-button type="primary" size="mini" @click="delSubmit"
                        >确定
                      </el-button>
                    </div>
                    <el-button class="btns" type="danger" slot="reference"
                      >删除
                    </el-button>
                  </el-popover>
                </div>
              </el-form-item> -->
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmitForm"
                  >提交
                </el-button>
                <!-- <el-button type="warning" @click="onSubmit(2)">修改 </el-button> -->
              </div>
            </el-dialog>
          </div>
        </div>
        <!-- 我的优惠券 -->
        <div class="info" v-if="checked === 5">
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>我的优惠券</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="钱包明细" :visible.sync="showDig" width="66%">
      <el-table :data="gridData" border>
        <el-table-column label="时间" width="200">
          <template slot-scope="scope">
            <p>时间:{{ scope.row.createTime }}</p>
          </template>
        </el-table-column>
        <el-table-column
          property="money"
          label="金额"
          width="100"
        ></el-table-column>
        <el-table-column
          property="ordeRnumber"
          label="订单编号"
          width="250"
        ></el-table-column>
        <el-table-column property="name" label="钱包波动" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.type === 1 ? "增加" : "减少" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          property="extendColumn1"
          label="支付主题"
          width="100"
        ></el-table-column>
        <el-table-column property="address" label="支付状态" width="100">
          <template slot-scope="scope">
            <span>{{
              scope.row.extendColumn2 === "0" ? "未支付" : "已支付"
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="pages.currentPage"
          :page-sizes="[10, 20, 30]"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totals"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 支付弹出层 -->
    <el-dialog
      title="订单支付"
      :visible.sync="payPop"
      width="50%"
      :close-on-click-modal="false"
      @close="closeDlg"
    >
      <div class="payContent">
        <div class="orderInfos">
          <p v-if="userType !== 0">
            客户名称：<span style="color: #058af2">{{ customerName }}</span>
          </p>
          <p>
            产品名称：<span style="color: #058af2">{{
              orderInfo.ordername
            }}</span>
          </p>
          <p>
            订单编号：<span>{{ orderInfo.id }}</span>
          </p>
          <p>
            订单金额：<span style="color: #ff0000">{{
              orderInfo.discountfee
            }}</span>
          </p>

          <p>
            补款金额：<span style="color: #ff0000">{{
              orderInfo.supplement
            }}</span>
          </p>
        </div>
        <div class="feetype">
          <span>付款方式：</span>
          <el-radio disabled v-model="radio1" label="4">补款</el-radio>
        </div>
        <div class="top">
          <el-checkbox-group v-model="checkList" class="check-group">
            <el-checkbox label="1" :disabled="disabled5">
              <span v-if="userType !== 0">{{ customerName }}的钱包余额：</span>
              <span v-else>钱包余额:</span>
              <span style="color: #ff0000">{{ wallet }}</span
              >元 (选择抵扣)
            </el-checkbox>
            <el-checkbox :disabled="disabled6" label="2">其他</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="ipt" v-show="changeType === '1'">
          <span>支付密码：</span>
          <el-input
            @input="wacthChange"
            @blur="blurs($event)"
            @focus="newPwdFocus($event)"
            class="inputs"
            v-model="input"
            placeholder="请输入支付密码"
            :type="(newPwdReadOnly && input) || input ? 'password' : 'text'"
            :readonly="newPwdReadOnly"
            auto-complete="new-password"
            name="person.user.new_password"
            ref="inputPs"
          ></el-input>
        </div>
        <div class="payTypes" v-show="showPayType">
          <div class="pingtai">
            <ul>
              <li>
                <div class="wx" @click="bills1('wx')"></div>
              </li>
              <li>
                <div class="zfb" @click="bills1('zfb')"></div>
              </li>
            </ul>
          </div>
          <div class="imgs" v-show="imgs !== ''">
            <vue-qr :text="imgs" :size="200"></vue-qr>
          </div>
          <p v-if="showText1 === 1">请用微信支付</p>
          <p v-if="showText1 === 2">请用支付宝支付</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clearPayData">取 消</el-button>
        <el-button
          :loading="payLoading"
          type="primary"
          @click="comitPay"
          :disabled="showSuer"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 发票详情弹出层 -->
    <el-dialog
      title="发票详情"
      :visible.sync="dialogVisibleInrecerse"
      width="30%"
    >
      <increase-info
        :increaseId="increaseInfoId"
        :key="infoTime"
      ></increase-info>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleInrecerse = false">关闭</el-button>
      </span>
    </el-dialog>


    <div class="dialogOther">
      <el-dialog
          title="选择客户"
          :visible.sync="showDialog"
          :close-on-click-modal="false"
      >
        <div class="queryMember">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-input
                  v-model.trim="custName1"
                  placeholder="请输入用户名称"
              ></el-input
              ></el-col>
            <el-col :span="6">
              <el-button type="primary" @click="queryMemberDig(2)"
              >查询</el-button
              >
              <el-button @click="refMember(2)">刷新</el-button>
            </el-col>
          </el-row>
        </div>
        <el-table
            :data="allList"
            border
            style="width: 100%"
            class="memberTable"
        >
          <el-table-column header-align="center" align="center" width="100">
            <template scope="scope">
              <el-radio
                  :label="scope.row.user_ID"
                  v-model="radio1"
                  @change.native="getCurrentRow(scope.row)"
              ></el-radio>
            </template>
          </el-table-column>

          <el-table-column
              property="name"
              label="用户名称"
              header-align="center"
              align="center"
          >
          </el-table-column>
          <el-table-column
              label="联系方式"
              width="180"
              header-align="center"
              align="center"
          >
            <template slot-scope="scope">
              <span>{{
                  scope.row.phone ? scope.row.phone : scope.row.username
                }}</span>
            </template>
          </el-table-column>
          <el-table-column
              property="createTime"
              label="注册时间"
              width="200"
              header-align="center"
              align="center"
          >
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
              :current-page="pageNumber2"
              :page-sizes="[5, 10, 20, 30]"
              :page-size="pageSize2"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          >
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDialog = false">取 消</el-button>
          <el-button type="primary" @click="showDialog = false"
          >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import pages from "../../components/pages/pagniation";
import { regionData, CodeToText } from "element-china-area-data";
import api from "../../api/invoice/invoice";
import { mapState } from "vuex";
import vueQr from "vue-qr";
import md5 from "js-md5";
import increaseInfo from "@/components/personal/increaseInfo";

export default {
  name: "cost",
  components: {
    vueQr,
    increaseInfo,
  },
  // components: {
  //   pages,
  // },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (value.length > 6) {
          callback(new Error("输入密码格式为6位纯数字"));
          return;
        }
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      infoTime: "",
      increaseInfoId: "",
      dialogVisibleInrecerse: false,
      increaseList: [],
      increaseTotals: 0,
      // 发票列表
      increases: {
        pageSize: 10,
        currentPage: 1,
        extendColumn4: 0,
      },
      dialogFormVisible: false,
      payPop: false,
      rechargeSumMoney: 0,
      orderSumMoney: 0,
      orderPaySumMoney: 0,
      balanceSumMoney: 0,
      html: "获取验证码",
      phone: "",
      disabled1: false,
      disabled: false, // 禁用input
      totals: 0,
      pages: {
        // 钱包明细
        pageSize: 10,
        currentPage: 1,
      },
      gridData: [],
      payMoneyData: [],
      showDig: false,
      allMoney: 0, // 显示可用金额
      showPsBtn: false, // 展示提示按钮
      showText: 0, // 显示支付提示文本
      ruleForm: {
        // 支付密码设置
        pass: "",
        checkPass: "",
        verification: "",
      },
      rulepass: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        verification: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      img: "", // 二维码展示
      visible: false,
      options: regionData, //省市区三级联动数据
      // invoiceInfo: {},
      total: 0,
      billForm: {
        // 表格的
        currentPage: 1,
        pageSize: 10,
      },

      //开始时间
      starttime3: "",
      //结束时间
      endtime3: "",
      total3: 0,
      //未结款订单
      billForm3: {
        // 表格的
        currentPage: 1,
        pageSize: 10,
      },
      whetherFullPayment: 1,
      // 表格数据
      tableData3: [],
      //订单总金额
      allTotalMoney: "",

      checked: 0, // 切换模块
      userInfo: [
        {
          id: 0,
          text: "账户充值",
        },
        {
          id: 1,
          text: "支付密码",
        },
        {
          id: 2,
          text: "消费记录",
        },
        {
          id: 3,
          text: "未结款订单",
        },
        {
          id: 4,
          text: "发票资料",
        },

        // {
        //     id: 5,
        //     text: "我的优惠券",
        // },
      ],
      // 充值金额
      dynamicValidateForm: {
        recharge: "",
      },
      rules: {
        recharge: [
          { required: true, message: "请输入充值金额", trigger: "blur" },
        ],
      },

      //开始时间
      starttime: "",
      //结束时间
      endtime: "",
      //类型
      type: "",
      // 表格数据
      tableData: [],

      form: {
        increaseTicketNumber: "", // 发票税号
        increaseTicketBankNumber: "", //银行卡号
        unitName: "", // 单位名称
        increaseTicketBank: "", // 开户银行
        increaseTicketPhone: "", //注册电话
        increaseTicketAdress: "", //单位注册地
        recipientSName: "", // 开票人姓名
        recipientSPhone: "", // 开票人手机号
        address: "", // 详细地址
        increaseTicketType: "1", // 发票类型
        //开票抬头
        increaseTicketTitle: "",
        //开票类型
        extendColumn1: "2",
        recipientSAdress: "", //详细地址
        recipientSProvinceCode: "",
        recipientSProvinceZh: "",
        recipientSCityCode: "",
        recipientSCityZh: "",
        recipientSAreaCode: "",
        recipientSAreaZh: "",
        // //开票金额
        // invoicemoney: "",
        // //申请时间
        // date1: "",
      },
      rule: {
        increaseTicketType: [
          { required: true, message: "请选择发票类型", trigger: "blur" },
        ],
        extendColumn1: [
          { required: true, message: "请选择开票类型", trigger: "change" },
        ],
        increaseTicketTitle: [
          { required: true, message: "请输入开票抬头", trigger: "change" },
        ],
        unitName: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
        ],
        increaseTicketNumber: [
          { required: true, message: "请输入发票税号", trigger: "blur" },
        ],
        increaseTicketAdress: [
          { required: true, message: "请输入注册地址", trigger: "blur" },
        ],
        increaseTicketPhone: [
          { required: true, message: "请输入注册电话", trigger: "blur" },
        ],
        increaseTicketBank: [
          { required: true, message: "请输入开户银行", trigger: "blur" },
        ],
        increaseTicketBankNumber: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
        // recipientSName: [
        //   { required: true, message: "请输入收票人姓名", trigger: "change" },
        // ],
        // recipientSPhone: [
        //   { required: true, message: "请输入收票人电话", trigger: "change" },
        // ],
        // address: [
        //   { required: true, message: "请选择收票地址", trigger: "change" },
        // ],
        // recipientSAdress: [
        //   { required: true, message: "请输入详细地址", trigger: "change" },
        // ],
      },
      pageNumber2: 1,
      pageSize2: 5,
      custName1: "",
      showDialog: false,
      allList: [], // 客户列表
      showInfo: false, // 展示发票类型相关数据
      showBtn: true, // 展示修改删除按钮
      invoiceId: "", // 发票id
      rechargeFrom: {
        ordeRnumber: "",
        type: "",
        userId: "",
      },
      userName: '',
      userId: "", // 当前登录者的user_ID
      userBan: {},
      pageSize: 10,
      pageNumber: 1,
      extendColumn2: "",
      reCode: "", // 获取的验证码
      reciordList: [], //消费详情
      userType: "",
      // 支付相关
      newPwdReadOnly: false,
      input: "",
      orderPay: 0, // 订单实付金额
      orderMoney: 0, //(实际订单金额)
      credit: 0, //信用额度
      wallet: 0, // 钱包余额
      radio1: "4",
      customerName: "", //客户名称
      orderInfo: {}, // 订单详情
      checkList: [], // 复选框
      changeType: "0", // 是否余额抵扣(0:否，1是)
      imgs: "", // 二维码图片
      showPayType: false, //展示支付方式
      showText1: "", //展示文本
      payInfo: {
        // 支付参数
        orderId: "",
        userId: "",
        userfatherid: "",
        paymentProportion: "",
        haveOtherPayment: "",
        paymentChooses: "",
        haveOtherPaymentChooses: "",
      },
      paySuccess: false, // 微信、支付宝 支付成功
      payLoading: false,
      showSuer: false, // 支付按钮
      disabled5: false, // 余额
      disabled6: false, // 其他
      payPass: false, // 支付密码是否校验成功
      checks: false, //有支付密码
      threePay: false, //余额加信用不足
      rechargeFromB: {
        ordeRnumber: "",
        type: "",
        userId: "",
      },
    };
  },
  computed: {
    ...mapState(["currentInfo"]),
  },
  filters: {
    numbers(value) {
      let relval = 0;
      if (!isNaN(value) && value !== "") {
        relval = parseFloat(value).toFixed(0);
      } else {
        relval = 0;
      }
      return relval;
    },
  },
  watch: {
    "form.extendColumn1": function (v) {
      console.log("当前开票类型", v);
      if (v === "1") {
        this.rule = {
          increaseTicketType: [
            { required: true, message: "请选择发票类型", trigger: "blur" },
          ],
          extendColumn1: [
            { required: true, message: "请选择开票类型", trigger: "change" },
          ],
          increaseTicketTitle: [
            { required: true, message: "请输入开票抬头", trigger: "change" },
          ],
          recipientSName: [
            { required: true, message: "请输入收票人姓名", trigger: "change" },
          ],
          recipientSPhone: [
            { required: true, message: "请输入收票人电话", trigger: "change" },
          ],
          address: [
            { required: true, message: "请选择收票地址", trigger: "change" },
          ],
          recipientSAdress: [
            { required: true, message: "请输入详细地址", trigger: "change" },
          ],
        };
      }
    },
    "form.increaseTicketType": function (v) {
      console.log("当前发票类型", v);
      if (v) {
        // this.showInfo = false; // 隐藏个人选项
        this.rule = {
          increaseTicketType: [
            { required: true, message: "请选择发票类型", trigger: "blur" },
          ],
          extendColumn1: [
            { required: true, message: "请选择开票类型", trigger: "change" },
          ],
          increaseTicketTitle: [
            { required: true, message: "请输入开票抬头", trigger: "change" },
          ],
          unitName: [
            { required: true, message: "请输入单位名称", trigger: "blur" },
          ],
          increaseTicketNumber: [
            { required: true, message: "请输入发票税号", trigger: "blur" },
          ],
          increaseTicketAdress: [
            { required: true, message: "请输入注册地址", trigger: "blur" },
          ],
          increaseTicketPhone: [
            { required: true, message: "请输入注册电话", trigger: "blur" },
          ],
          increaseTicketBank: [
            { required: true, message: "请输入开户银行", trigger: "blur" },
          ],
          increaseTicketBankNumber: [
            { required: true, message: "请输入银行账号", trigger: "blur" },
          ],
        };
      }
    },
    checkList(v) {
      console.log("复选框变化", v);
      if (v.length > 0) {
        let other = v.some((el) => el === "2"); //其他：微信、支付宝
        let pay = v.some((el) => el === "3"); // 信用（暂时不起用）
        let balance = v.some((el) => el === "1"); //余额
        console.log("存在其他", other);
        if (other && v.length > 1) {
          // 存在混合
          this.imgs = "";
          this.showText1 = 0;
          this.showSuer = false;
        }
        if (balance) {
          // 余额
          if (this.wallet >= this.orderPay) {
            //余额充足
            this.disabled6 = true; //其他禁用
            let index = v.findIndex((el) => el === "2");
            console.log("获取下标", index);
            if (index !== -1) {
              this.checkList.splice(index, 1);
            }
            if (!this.payPass) {
              this.showSuer = true;
            }
            if (this.userType !== 0) {
              // 代客支付 不需要支付验证
              this.changeType = "0";
              this.showSuer = false;
            }
          } else {
            //余额不足
            if (v.length === 1) {
              this.$message.warning("当前余额不足,请配合其他方式支付！");
              this.showSuer = true;
            }
            this.disabled6 = false;
          }
          // if (this.credit <= 0) { // 信用
          //   this.disabled3 = true;
          // }
        }

        if (pay || balance) {
          if (this.userType !== 0) {
            // 代客支付 不需要支付验证
            this.changeType = "0";
          } else {
            this.changeType = "1";
          }
        } else {
          this.changeType = "0";
        }
        if (other) {
          // 微信，支付宝
          this.showPayType = true;
          if (this.changeType === "1") {
            // 存在余额
            if (this.payPass) {
              // 支付密码正确
              this.showSuer = false;
            }
          } else {
            this.showSuer = false;
          }
        } else {
          this.showPayType = false; //隐藏付款方式
          this.imgs = "";
          this.showText1 = "";
        }
      } else {
        // if (this.radio && this.radio !== "3") {
        //   this.disabled1 = this.disabled2 = this.disabled3 = false;
        // }
        console.log("复选框为0", this.wallet);
        if (this.wallet <= 0) {
          console.log("执行了这里", this.wallet);
          //余额不足
          this.disabled5 = true;
        }
        // if (this.credit <= 0) { // 信用
        //   this.disabled3 = true;
        // }
        // if (this.radio1 !== "3" && this.radio1 !== "0") {
        //   console.log("执行了这里");
        //   this.disabled6 = false;
        //   this.showSuer = true;
        // }
        this.disabled6 = false;
        this.showSuer = true;
        this.showPayType = false; //隐藏付款方式
        this.imgs = "";
        this.showText1 = "";
        this.changeType = "0";
      }
    },
    changeType(v) {
      console.log("changeType", v);
      if (v === "1") {
        this.checks = true;
        if (this.payPass) {
          this.showSuer = true;
        }
      } else {
        this.checks = false;
      }
    },
    input(v) {
      if (this.changeType === "1") {
        if (v === "") {
          this.newPwdReadOnly = true;
          this.newPwdFocus(null);
        }
      }
    },
  },
  created() {
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (obj === null) return;
    this.userId = obj.user_ID;
    this.phone = obj.phone;
    this.userType = obj.userType;
    console.log("userType", this.userType);
    this.getUserInfo();
    this.getRechargeconfig();
    this.getPayList();
    // 获取未结款订单
    this.getUnpaidList();
    // if (this.checked === 0) {
    //   this.allMoney = obj.balance;
    // }
  },
  mounted() {},
  updated() {
    // console.log(this.onceClick());
  },
  methods: {
    onSubmitForm() {
      // 存在发票id即为修改 否者为新增
      if (this.invoiceId) {
        this.onSubmit(2);
      } else {
        this.onSubmit(1);
      }
    },
    // 详情
    IncreaseInfo(item) {
      console.log("详情", item);
      this.infoTime = new Date().getTime();
      this.increaseInfoId = item.id;
      this.dialogVisibleInrecerse = true;
    },
    // 修改
    editIncrease(data) {
      console.log("修改详情", data);
      this.invoiceId = data.id;
      if (data.increaseTicketType === "1") {
        this.showInfo = false; // 隐藏个人选项
      }
      let str =
        data.recipientSProvinceCode +
        "," +
        data.recipientSCityCode +
        "," +
        data.recipientSAreaCode;
      let arr = str.split(",");
      this.form = {
        increaseTicketNumber: data.increaseTicketNumber, // 发票税号
        increaseTicketBankNumber: data.increaseTicketBankNumber, //银行卡号
        unitName: data.unitName, // 单位名称
        increaseTicketBank: data.increaseTicketBank, // 开户银行
        increaseTicketPhone: data.increaseTicketPhone, //注册电话
        increaseTicketAdress: data.increaseTicketAdress, //单位注册地
        recipientSName: data.recipientSName, // 开票人姓名
        recipientSPhone: data.recipientSPhone, // 开票人手机号
        address: arr, // 详细地址
        increaseTicketType: data.increaseTicketType.toString(), // 发票类型
        //开票抬头
        increaseTicketTitle: data.increaseTicketTitle,
        //开票类型
        extendColumn1: data.extendColumn1 ? data.extendColumn1.toString() : "2",
        recipientSAdress: data.recipientSAdress, //详细地址
        recipientSProvinceCode: data.recipientSProvinceCode,
        recipientSProvinceZh: data.recipientSProvinceZh,
        recipientSCityCode: data.recipientSCityCode,
        recipientSCityZh: data.recipientSCityZh,
        recipientSAreaCode: data.recipientSAreaCode,
        recipientSAreaZh: data.recipientSAreaZh,
      };
      this.dialogFormVisible = true;
    },
    // 新增发票
    addIncrease() {
      this.invoiceId = "";
      this.clearFrom();
      this.dialogFormVisible = true;
    },
    // 测试参数
    // textMoney(item, item1) {
    //   console.log("详情数据", item, "订单数据", item1);
    // },
    // 清除支付参数
    clearPayData() {
      this.payInfo = {
        orderId: "",
        userId: "",
        userfatherid: "",
        paymentProportion: "",
        haveOtherPayment: "",
        paymentChooses: "",
        haveOtherPaymentChooses: "",
      };
      this.payPop = false;
      this.imgs = "";
      this.showText1 = 0;
      this.showPayType = false;
      this.changeType = "0";
      this.checkList = [];
      this.radio1 = "4";
      this.input = "";
      this.payLoading = false;
    },
    // 处理参数
    payData() {
      let info = this.orderInfo;
      let data = this.payInfo;
      data.orderId = info.id;
      data.userId = info.userid;
      data.userfatherid = info.userfatherid;
      // 付款方式
      if (this.radio1 === "1") {
        data.paymentProportion = 1; // 首付30%
      } else if (this.radio1 === "2") {
        data.paymentProportion = 2; // 全款支付
      } else if (this.radio1 === "3") {
        data.paymentProportion = 3; // 线下付款
        data.haveOtherPayment = "";
        data.paymentChooses = "";
        data.haveOtherPaymentChooses = "";
      } else {
        data.paymentProportion = 4; // 补款
      }
      // 支付方式
      let arr = [...this.checkList];
      console.log(arr, data);
      if (arr.length === 1) {
        console.log("单选");
        if (arr[0] === "1") {
          // 余额支付
          data.paymentChooses = 1; // 余额
          data.haveOtherPayment = 1; // 不需要支付宝
        } else if (arr[0] === "2") {
          // 其他支付
          data.haveOtherPayment = 3; // 单独支付宝
          data.paymentChooses = "";
        }
        // else {
        // 信用支付
        // data.haveOtherPayment = 1; // 不需要支付宝
        // data.paymentChooses = 2; // 信用
        // }
      }
      if (arr.length === 2) {
        console.log("执行了多选");
        console.log(arr.join("."));
        let str = arr.join(".");
        if (str === "1.2" || str === "2.1") {
          console.log("余额加支付宝");
          data.haveOtherPayment = 2; // 需要支付宝
          data.paymentChooses = 1; // 余额
        } else if (str === "1.3" || str === "3.1") {
          console.log("二合一");
          data.haveOtherPayment = 1; // 不需要支付宝
          data.paymentChooses = 3;
        } else {
          console.log("信用加支付宝");
          data.haveOtherPayment = 2; // 需要支付宝
          data.paymentChooses = 2; // 信用
        }
      }
      if (arr.length === 3) {
        data.haveOtherPayment = 2; // 需要支付宝
        data.paymentChooses = 3; // 信用，余额二合一
      }
    },
    //确定支付成功(补款)
    surePay1() {
      if (this.changeType === "1") {
        if (this.payPass) {
          // this.$message.success("支付密码正确");
        } else {
          this.$message.warning("支付密码验证未通过,请重新确认支付密码");
          return;
        }
      }
      api
        .queryPassType(this.rechargeFromB)
        .then((res) => {
          this.payLoading = false;
          // console.log("查询支付状态", res);
          if (res.success) {
            if (
              res.data.result === "TRADE_SUCCESS" ||
              res.data.result === "SUCCESS"
            ) {
              this.paySuccess = true;
              this.$message.success("支付成功");
              // this.stopPass();
              this.clearPayData();
              this.getUnpaidList();
            } else {
              this.$message.warning("未查询到支付成功，请确认支付是否成功");
              this.payLoading = false;
            }
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 支付弹窗确定按钮(未使用扫码支付；补款)
    comitPay() {
      this.payData();
      console.log("支付参数", this.payInfo);
      this.payLoading = true;
      if (this.showPayType) {
        if (this.imgs === "") {
          this.$message.warning("请选择支付方式，微信或支付宝");
          this.payLoading = false;
          return;
        }
        this.surePay1(); // 确认(微信，支付宝)是否支付成功
        if (this.paySuccess) {
          this.clearPayData();
        } else {
          // this.$message.warning("请确定是否扫码支付成功？");
          return;
        }
        console.log(this.payInfo);
      } else {
        if (this.changeType === "1") {
          if (this.payPass) {
            console.log("验证成功");
          } else {
            if (this.showMessage) {
              this.$message.warning("请输入支付密码！");
              return;
            }
            return;
          }
        }
        // if (this.radio1 === "3") { // 线下
        //   // this.payInfo.paymentProportion = 1;
        //   this.payInfo.orderMoney = this.offlineOrderMoney;
        // }
        console.log("支付参数", this.payInfo);
        api
          .orderpayinfo(this.payInfo)
          .then((res) => {
            this.payLoading = false;
            console.log("单独支付成功", res);
            if (res.success) {
              this.$message.success("支付成功");
              this.clearPayData();
              this.getUnpaidList();
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
        console.log(this.payInfo);
      }
      // this.payPop = false;
    },
    // 支付选择（补款）
    bills1(str) {
      this.payData();
      if (this.checks) {
        if (this.payPass) {
          console.log("验证成功");
        } else {
          this.$message.warning("请先验证支付密码!");
          return;
        }
      }
      // 微信
      if (str === "wx") {
        console.log("微信支付");
        this.payInfo.haveOtherPaymentChooses = 2;
      } else {
        // 支付宝
        console.log("支付宝支付");
        this.payInfo.haveOtherPaymentChooses = 1;
      }
      console.log("调整后的参数数据", this.payInfo);
      api
        .orderpayinfo(this.payInfo)
        .then((res) => {
          console.log("调取获取二维码", res);
          if (res.success) {
            this.imgs = res.data.qrCode;
            if (this.payInfo.haveOtherPaymentChooses === 2) {
              // 微信
              this.showText1 = 1;
              this.rechargeFromB = {
                ordeRnumber: res.data.otherPayOrderId,
                type: 2,
                userId: this.payInfo.userId,
              };
            } else {
              // 支付宝
              this.showText1 = 2;
              this.rechargeFromB = {
                ordeRnumber: res.data.otherPayOrderId,
                type: 1,
                userId: this.payInfo.userId,
              };
            }
            // console.log(this.rechargeFromB);
          } else {
            this.$message.error("获取支付二维码失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    newPwdFocus(evt, isNew = true) {
      console.log("获取焦点");
      if (evt) {
        evt.stopPropagation();
        evt.preventDefault();
      }
      setTimeout(() => {
        if (isNew) {
          this.newPwdReadOnly = false;
        } else {
          this.rePwdReadOnly = false;
        }
      }, 100);
    },
    // 失去焦点触发事件（支付密码）
    blurs(evt, isNew = true) {
      if (evt) {
        evt.stopPropagation();
      }
      if (isNew) {
        this.newPwdReadOnly = true;
      } else {
        this.rePwdReadOnly = true;
      }
      if (this.threePay) {
        this.$message.warning("当前金额不足,请配合其他方式支付！");
        return;
      }
      // console.log(event);
      if (this.changeType === "1") {
        if (this.input === "") {
          this.$message.error(
            "请输入支付密码!如若未设置或者忘记，请到费用中心设置支付密码!"
          );
          this.showSuer = true; // 支付弹窗按钮禁用
          this.payPass = false;
          return;
        } else {
          // let ps = md5(this.input);
          let data = {
            userName: this.userId,
            pwd: md5(this.input),
          };
          api
            .checkPayPassWord(data)
            .then((res) => {
              if (res.success) {
                this.showSuer = false;
                this.payPass = true;
              } else {
                this.$message.error(res.message);
                this.payPass = false;
                this.showSuer = true;
                this.showMessage = false;
              }
            })
            .catch((error) => {
              console.error("error", error);
              this.payPass = false;
              this.showSuer = true;
              this.showMessage = false;
            });
        }
      } else {
        this.showSuer = false;
      }
    },
    wacthChange(value) {
      if (value.length === 6) {
        this.$refs.inputPs.blur();
      }
    },
    // 选择客户（单选）
    getCurrentRow(item) {
        this.userName = item.name;
        this.userId = item.user_ID

    },
    //筛选客户条件分页
    handleSizeChange2(val) {
      this.pageSize2 = val;
      this.getCustomer();
    },
    handleCurrentChange2(val) {
      this.pageNumber2 = val;
      this.getCustomer();
    },
    changeMember() {
      this.showDialog = true;
      this.getCustomer();
    },
    // 新增客户弹框查询
    queryMemberDig(type) {
      if (type === 1) {
        this.radio = "";
      } else {
        this.radio1 = "";
      }
      this.getCustomer();
    },
    // 新增客户弹框刷新
    refMember(type) {
      // 新增客户查询
      if (type === 1) {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.custName = this.radio = this.form.name = "";
      } else {
        // 列表客户查询
        this.pageNumber2 = 1;
        this.pageSize2 = 5;
        this.custName1 = this.radio1 = "";
      }
      this.getCustomer();
    },
    getCustomer() {

      api
          .queryUser1(
              this.pageNumber2,
              this.pageSize2,
              "",
              "",
              this.custName1,
              "0"
          )
          .then((res) => {
            console.log("获取客户", res);
            if (res.success) {
              // this.allList = res.data.userPageInfo.list;
              this.allList = res.data.list;
              this.total = res.data.total;

            } else {
              this.$message.error(res.message);
            }
          });
    },
    // 阻止密码框自动填充
    stopPass() {
      console.log("调用了回调");
      if (this.$refs.inputPs) {
        console.log("执行了判断");
        this.$refs.inputPs.$refs.input.onmousedown = (evt) => {
          console.log("执行了点击", evt);
          if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
          }
          if (this.input === "" || this.newPwdReadOnly) {
            this.$refs.inputPs.$refs.input.blur();
            setTimeout(() => {
              this.$refs.inputPs.$refs.input.focus();
            }, 0);
          }
          return false;
        };
      }
    },
    // 关闭支付弹窗
    closeDlg() {
      this.input = "";
      this.orderPay = 0;
      this.stopPass();
      this.clearPayData();
    },
    // 获取用户详情
    getUserInfos(id) {
      api
        .getUserMoneyAndCredits(1, id)
        .then((res) => {
          // console.log("获取用户详情", res);
          if (res.success) {
            this.credit = Number(res.data.credits); //信用额度
            this.wallet = Number(res.data.balance); // 钱包余额
            this.customerName = res.data.user.name;
            if (this.wallet <= 0) {
              this.disabled5 = true;
            } else {
              this.disabled5 = false;
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 补款确定
    paySet(item) {
      console.log("补款", item);
      console.log(item);
      this.orderInfo = item;
      this.orderPay = item.supplement;
      this.orderMoney = Number(item.discountfee);
      this.payPop = true;
      this.showSuer = true;
      this.getUserInfos(item.id);
    },
    // 处理数据
    numberData(type, num) {
      if (type === 1) {
        return (Number(num) * 0.3).toFixed(2);
      } else {
        return (Number(num) * 0.7).toFixed(2);
      }
    },
    // 消费记录摘要展示
    onceClick(row, expandedRows) {
      // console.log(row, expandedRows);
      if (expandedRows.length > 0) {
        api.reciordInfo(2, row.ordeRnumber, 1).then((res) => {
          // console.log("获取当前订单消费记录", res);
          if (res.success) {
            this.tableData.forEach((el, index) => {
              if (el.ordeRnumber === row.ordeRnumber) {
                this.tableData[index].children = res.data;
              }
            });
          }
        });
      }
    },
    // 获取验证码
    getVer() {
      this.$http
        .post(`/login/sendSms?phone=${this.phone}&management=mallUse`)
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.reCode = res.data.data.retCode;
            var cont = 60;
            let timer = setInterval(() => {
              --cont;
              this.disabled1 = true;
              if (cont === 0) {
                this.disabled1 = false;
                this.html = "获取验证码";
                clearInterval(timer);
                return;
              } else {
                this.html = `${cont}秒后重新获取`;
              }
            }, 1000);
          } else {
            this.$message({
              message: res.data.message,
              type: "warning",
            });
          }
        });
    },
    // 获取消费记录
    getPayList() {
      api
        .consumerCenter(
          this.starttime,
          this.endtime,
          this.billForm.pageSize,
          this.billForm.currentPage,
         2,
            this.userId
        )
        .then((res) => {
          console.log("消费记录", res);
          if (res.success) {
            this.rechargeSumMoney =  Number(res.data.rechargeSumMoney).toFixed(2);
            this.orderSumMoney = Number(res.data.orderSumMoney).toFixed(2);
            this.orderPaySumMoney = Number(res.data.orderPaySumMoney).toFixed(
              2
            );
            this.balanceSumMoney = Number(res.data.balance).toFixed(2);
            res.data.useWalletPageInfo.list.map((el) => {
              el.children = [];
            });
            this.tableData = res.data.useWalletPageInfo.list;
            this.total = res.data.useWalletPageInfo.total;
          }
        });
    },
    // 获取未结款订单
    getUnpaidList() {
      api
        .queryUnpaidOrder(
          this.starttime3,
          this.endtime3,
          this.billForm3.pageSize,
          this.billForm3.currentPage,
          this.whetherFullPayment
        )
        .then((res) => {
          console.log("获取未结款订单", res);
          if (res.success) {
            this.total3 = res.data.pageInfo.total;
            res.data.pageInfo.list.forEach((el) => {
              el.supplement = (Number(el.discountfee) * 0.7).toFixed(2);
            });
            this.tableData3 = res.data.pageInfo.list;
            this.allTotalMoney = res.data.resultMoney;
          } else {
            this.$message.error("获取未结款订单列表失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 获取用户详情
    getUserInfo() {
      api
        .getUserMoneyAndCredits(2, null)
        .then((res) => {
          console.log("获取用户详情", res);
          if (res.success) {
            this.allMoney = res.data.balance;
          } else {
            if (this.userType === 0) {
              this.$message.error(res.message);
            }
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    getRechargeconfig() {
      api
        .queryRechargeconfig()
        .then((res) => {
          console.log("获取快捷充值金额", res);
          if (res.success) {
            this.payMoneyData = res.data;
            this.payMoneyData.forEach(item => {
              item.amount = Number((item.amount || item.amount !== "") ? item.amount : "0") - Number((item.recharge || item.recharge !== "") ? item.recharge : "0") + "";
            })
          } else {
            if (this.userType === 0) {
              this.$message.error(res.message);
            }
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 修改金额
    editQuota() {
      this.showPsBtn = false;
      this.showText = 0;
      this.img = "";
      this.disabled = false;
    },
    clickRechargeConfig(item) {
      this.dynamicValidateForm.recharge = item.recharge
    },
    handleSizeChange1(val) {
      this.pages.pageSize = val;
      this.showDigs();
    },
    handleCurrentChange1(val) {
      this.pages.currentPage = val;
      this.showDigs();
    },
    // 展示钱包明细
    showDigs() {
      api
        .walletInfo(this.pages.currentPage, this.pages.pageSize, 1, 1)
        .then((res) => {
          if (res.success) {
            this.gridData = res.data.list;
            this.totals = res.data.total;
            this.showDig = true;
          } else {
            this.$message.error("获取失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 确定支付成功（充值）
    surePay() {
      api
        .queryPassType(this.rechargeFrom)
        .then((res) => {
          // console.log("查询支付状态", res);
          if (res.success) {
            if (
              res.data.result === "TRADE_SUCCESS" ||
              res.data.result === "SUCCESS"
            ) {
              this.allMoney = res.data.user.userBalance;
              this.showText = 0;
              this.img = "";
              this.dynamicValidateForm.recharge = "";
              this.disabled = false;
            } else {
              this.$message.warning("未查询到支付成功，请确认支付是否成功");
            }
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 支付密码设置
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let userinfo = JSON.parse(
            window.sessionStorage.getItem("currentInfo")
          );
          // let data = {
          //   id: userinfo.user_ID,
          //   ps: md5(this.ruleForm.pass),
          // };
          // if (this.ruleForm.verification !== this.reCode) {
          //   this.$message.error("验证码输入错误");
          //   return;
          // }
          let data = new FormData();
          data.append("id", userinfo.user_ID);
          data.append("ps", md5(this.ruleForm.pass));
          data.append("code", this.ruleForm.verification);
          console.log(data);
          api
            .setPass(data)
            .then((res) => {
              console.log("设置支付密码", res);
              if (res.success) {
                this.$message.success("支付密码设置成功");
                this.disabled1 = false;
                this.html = "获取验证码";
                this.resetForm(formName);
              } else {
                this.$message.error("支付密码设置失败");
              }
            })
            .catch((error) => {
              console.error("error", error);
            });
        } else {
          return false;
        }
      });
    },
    // 支付密码重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 整理表单数据
    handleForm() {
      // 开票类型（1 ，个人：2，单位）
      let obj = Object.assign({ operatorId: this.userId }, this.form);
      if (obj.extendColumn1 === "1") {
        obj.increaseTicketNumber = "";
        obj.increaseTicketBankNumber = "";
        obj.unitName = "";
        obj.increaseTicketBank = "";
        obj.increaseTicketPhone = "";
        obj.increaseTicketAdress = "";
      } else {
        obj.increaseTicketTitle = "";
      }
      return obj;
    },
    // 删除发票
    delSubmit(item) {
      console.log("删除发票", item);
      this.$confirm("此操作将永久删除该发票, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .delinvoice(item.id)
            .then((res) => {
              // console.log("删除发票", res);
              if (res.success) {
                this.$message.success("删除成功");
                this.getinvoiceLists();
              } else {
                this.$message.error("删除失败");
              }
            })
            .catch((error) => {
              console.error("error", error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 校验是否是增值税发票
    // checkInvoice() {
    //   api
    //     .queryInfo({ operatorId: this.userId })
    //     .then((res) => {
    //       // console.log("是否存在增值税发票", res);
    //       if (res.data >= 1) {
    //         this.$message.warning("已存在增值税发票存根,不能再进行添加");
    //         return false;
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("error", error);
    //     });
    // },

    // 提交新增/修改发票
    onSubmit(value) {
      // value 1为新增 2为修改
      let fieldList = [];
      if (this.form.extendColumn1 === "1") {
        // 个人
        fieldList = [
          "increaseTicketType",
          "extendColumn1",
          "increaseTicketTitle",
          "recipientSName",
          "recipientSPhone",
          "address",
          "recipientSAdress",
        ];
      } else {
        // 单位
        // if (this.form.increaseTicketType === "1") {
        fieldList = [
          "increaseTicketType",
          "extendColumn1",
          "unitName",
          "increaseTicketNumber",
          "increaseTicketAdress",
          "increaseTicketPhone",
          "increaseTicketBank",
          "increaseTicketBankNumber",
        ];
      }
      const fieldPromise = fieldList.map((item) => {
        return new Promise((resolve) => {
          this.$refs.form.validateField(item, (error) => {
            resolve(error);
          });
        });
      });
      //利用 Promise.all 方法统一处理上面 map 出来的方法
      // console.log(fieldPromise);
      Promise.all(fieldPromise).then((item) => {
        //过滤数组中的空字符串，list.length为0表示通过验证
        const list = item.filter((data) => data);
        // console.log(list);
        if (list.length === 0) {
          // console.log("验证通过");
          // if (this.form.increaseTicketType === "1") {
          //   this.checkInvoice();
          // }
          if (value === 1) {
            let data = this.handleForm();
            delete data.address;
            // console.log("传的参数", data);
            api
              .addinvoice(data)
              .then((res) => {
                // console.log("新增发票存根", res);
                if (res.success) {
                  this.$message.success("新增成功");
                  // this.getinvoice();
                  this.getinvoiceLists();
                  this.dialogFormVisible = false;
                } else {
                  this.$message.error("新增成功");
                }
              })
              .catch((error) => {
                console.error("error", error);
              });
          } else {
            let data = this.handleForm();
            delete data.address;
            delete data.operatorId;
            // console.log("传的参数", data);
            api
              .editinvoice(this.invoiceId, data)
              .then((res) => {
                // console.log("修改发票存根", res);
                if (res.success) {
                  this.$message.success("修改成功");
                  // this.getinvoice();
                  this.getinvoiceLists();
                  this.dialogFormVisible = false;
                } else {
                  this.$message.error("修改失败");
                }
              })
              .catch((error) => {
                console.error("error", error);
              });
          }
        } else {
          this.$message.error("验证未通过,请认真填写带*的内容");
        }
      });
    },
    // 地址展示
    handleChange(val) {
      // console.log(val);
      // console.log(CodeToText[val[0]]);
      this.form.recipientSProvinceCode = val[0];
      this.form.recipientSProvinceZh = CodeToText[val[0]];
      this.form.recipientSCityCode = val[1];
      this.form.recipientSCityZh = CodeToText[val[1]];
      this.form.recipientSAreaCode = val[2];
      this.form.recipientSAreaZh = CodeToText[val[2]];
    },
    // 开票类型变换
    changes1(val) {
      console.log(val);
    },
    // 发票类型变换
    changes(val) {
      // console.log(val);
      if (val === "1") {
        this.showInfo = false;
        this.form.extendColumn1 = "2";
      } else {
        this.showInfo = true;
      }
    },
    // 选择支付方式
    bills(str) {
      let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
      console.log(obj);
      if (
        this.dynamicValidateForm.recharge === "" ||
        this.dynamicValidateForm.recharge === "0"
      ) {
        this.$message.warning("请输入有效充值金额");
        return;
      }
      this.disabled = true;
      let data = {
        userId: obj.user_ID,
        money: this.dynamicValidateForm.recharge,
        type: 1,
      };
      // 微信
      if (str === "wx") {
        console.log("微信支付");
        data.incomeType = 2;
      } else {
        // 支付宝
        console.log("支付宝支付");
        data.incomeType = 1;
      }
      console.log("调整后的参数数据", data);
      // this.img =
      api
        .recharge(data)
        .then((res) => {
          console.log("调取获取二维码", res);
          if (res.success) {
            this.img = res.data.qrCode;
            this.showPsBtn = true;
            if (data.incomeType === 2) {
              // 微信
              this.showText = 1;
              this.rechargeFrom = {
                ordeRnumber: res.data.orderNumber,
                type: 2,
                userId: obj.user_ID,
              };
            } else {
              // 支付宝
              this.showText = 2;
              this.rechargeFrom = {
                ordeRnumber: res.data.orderNumber,
                type: 1,
                userId: obj.user_ID,
              };
            }
            // console.log(this.rechargeFrom);
          } else {
            this.$message.error("获取支付二维码失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    handleSizeChange(val) {
      this.billForm.pageSize = val;
      this.getPayList();
    },
    handleCurrentChange(val) {
      this.billForm.currentPage = val;
      this.getPayList();
    },
    handleSizeChange3(val) {
      this.billForm.pageSize = val;
      // 获取未结款订单
      this.getUnpaidList();
    },
    handleCurrentChange3(val) {
      this.billForm.currentPage = val;
      // 获取未结款订单
      this.getUnpaidList();
    },
    // 清空表单
    clearFrom() {
      this.form = {
        increaseTicketNumber: "", // 发票税号
        increaseTicketBankNumber: "", //银行卡号
        unitName: "", // 单位名称
        increaseTicketBank: "", // 开户银行
        increaseTicketPhone: "", //注册电话
        increaseTicketAdress: "", //单位注册地
        recipientSName: "", // 开票人姓名
        recipientSPhone: "", // 开票人手机号
        address: "", // 详细地址
        increaseTicketType: "1", // 发票类型
        //开票抬头
        increaseTicketTitle: "",
        //开票类型
        extendColumn1: "2",
        recipientSAdress: "", //详细地址
        recipientSProvinceCode: "",
        recipientSProvinceZh: "",
        recipientSCityCode: "",
        recipientSCityZh: "",
        recipientSAreaCode: "",
        recipientSAreaZh: "",
      };
    },
    // 查询发票信息(无分页)
    getinvoice() {
      console.log("执行了查询发票信息");
      api
        .getinvoiceList(this.userId)
        .then((res) => {
          console.log("获取发票列表", res);
          if (res.success) {
            // this.invoiceInfo = res.data[0];
            let data = res.data[0];
            if (res.data.length > 0) {
              if (data.increaseTicketType === "1") {
                this.showInfo = false; // 隐藏个人选项
              }
              this.showBtn = false; // 显示修改删除
              this.invoiceId = data.id;
              let str =
                data.recipientSProvinceCode +
                "," +
                data.recipientSCityCode +
                "," +
                data.recipientSAreaCode;
              let arr = str.split(",");
              this.form = {
                increaseTicketNumber: data.increaseTicketNumber, // 发票税号
                increaseTicketBankNumber: data.increaseTicketBankNumber, //银行卡号
                unitName: data.unitName, // 单位名称
                increaseTicketBank: data.increaseTicketBank, // 开户银行
                increaseTicketPhone: data.increaseTicketPhone, //注册电话
                increaseTicketAdress: data.increaseTicketAdress, //单位注册地
                recipientSName: data.recipientSName, // 开票人姓名
                recipientSPhone: data.recipientSPhone, // 开票人手机号
                address: arr, // 详细地址
                increaseTicketType: data.increaseTicketType.toString(), // 发票类型
                //开票抬头
                increaseTicketTitle: data.increaseTicketTitle,
                //开票类型
                extendColumn1: data.extendColumn1
                  ? data.extendColumn1.toString()
                  : "2",
                recipientSAdress: data.recipientSAdress, //详细地址
                recipientSProvinceCode: data.recipientSProvinceCode,
                recipientSProvinceZh: data.recipientSProvinceZh,
                recipientSCityCode: data.recipientSCityCode,
                recipientSCityZh: data.recipientSCityZh,
                recipientSAreaCode: data.recipientSAreaCode,
                recipientSAreaZh: data.recipientSAreaZh,
              };
              // console.log("发票存根", data);
            } else {
              this.clearFrom();
            }
          } else {
            this.$message.error("获取发票存根失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 查询所有发票
    getinvoiceLists() {
      api
        .getinvoiceListAll(
          this.increases.currentPage,
          this.increases.pageSize,
            '',
          this.increases.extendColumn4

        )
        .then((res) => {
          console.log("获取所有发票", res);
          if (res.success) {
            this.increaseTotals = res.data.total;
            this.increaseList = res.data.list;
          }
        });
    },
    // 切换列表
    replace(item) {
      // localStorage.setItem("type", item.id); //存入本地，防止刷新页面丢失
      this.checked = item.id;
      // console.log(item);
      if (item.id === 0) {
        this.getUserInfo();
      }
      if (item.id === 4) {
        // this.getinvoice();
        this.getinvoiceLists();
      }
      if (item.id === 2) {
        this.getPayList();
      }
      if (item.id === 3) {
        // 获取未结款订单
        this.getUnpaidList();
      }
      if (item.id !== 0) {
        this.img = "";
        this.showPsBtn = false;
        this.showText = 0;
        this.dynamicValidateForm.recharge = "";
        this.disabled = false;
      }
    },
    // 查询
    query() {
      // let data = {
      //   starttime: this.starttime,
      //   endtime: this.endtime,
      //   type: this.type,
      // };
      // console.log(data);
      this.getPayList();
    },
    // 发票
    increaseSizeChange(val) {
      this.increases.pageSize = val;
      this.getinvoiceLists();
    },
    increaseCurrentChange(val) {
      this.increases.currentPage = val;
      this.getinvoiceLists();
    },
    // -----------------------------处理分页------------------------
    // getTableData(data) {
    //   console.log(data);
    //   console.log(data[0]);
    //   // this.tableData= this.tableData
    //   this.$refs.pages.currentChangePage(
    //     this.tableData,
    //     1,
    //     this.tableData.length
    //   );
    // },
  },
};
</script>

<style lang="scss" scoped>
.payContent {
  /*text-align: center;*/

  .orderInfos {
    margin: 10px 0;
  }

  .top {
    margin: 10px 0;

    .check-group {
      display: flex;
      flex-direction: column;
      line-height: 30px;
    }
  }

  .ipt {
    height: 40px;
    line-height: 40px;
    display: flex;
    margin: 15px 0px;

    .inputs {
      width: 200px;
    }
  }

  .payTypes {
    /*付款平台*/
    .pingtai {
      overflow: hidden;
      margin-left: 125px;

      ul li {
        float: left;
        display: inline;
        position: relative;
        width: 177px;
        height: 50px;
        cursor: pointer;
        border: 1px solid #d2d1d1;
        margin-right: 18px;
        /*微信支付*/
        .wx {
          background: url(../../static/imgs/sprites-payment-logo.png) no-repeat;
          background-position: 0 -1880px;
          display: inline-block;
          width: 175px;
          height: 37px;
          vertical-align: middle;
          position: absolute;
          top: 7px;
          *top: 5px;
          left: 1px;
          z-index: 2;
        }

        /*支付宝支付*/
        .zfb {
          background: url(../../static/imgs/sprites-payment-logo.png) no-repeat;
          background-position: 0 -120px;
          display: inline-block;
          width: 175px;
          height: 37px;
          vertical-align: middle;
          position: absolute;
          top: 7px;
          *top: 5px;
          left: 1px;
          z-index: 2;
        }
      }
    }

    .imgs {
      display: flex;
      justify-content: center;
    }

    p {
      text-align: center;
      color: #ff0000;
    }
  }

  .successBtn {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btns {
  margin-left: 10px;
}

.personalInfo {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .card-left {
    width: 200px;
    height: 360px;

    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;

      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }

    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
      }

      li:last-child {
        border-bottom: none;
      }

      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }
  }

  .card-right {
    width: 980px;
    background-color: #fff;
  }

  .tops {
    display: flex;
    justify-content: center;

    p {
      color: #ff0000;
      padding: 0 20px;
    }
  }

  .info {
    width: 100%;
    box-sizing: border-box;
    // padding: 15px;
  }

  .card {
    width: 100%;

    .card-top {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #e6e6e6;
      padding: 0 30px;
      line-height: 50px;

      span {
        display: inline-block;
        width: 5px;
        height: 17px;
        background: #058af2;
        margin-right: 20px;
      }

      h3 {
        width: 70%;
        color: #058af2;
      }

      .fapiao {
        width: 300px;
        color: #058af2;
      }

      .infos {
        width: 150px;
        margin-left: 20px;
        color: #047bd7;
        cursor: pointer;
      }
    }

    .table {
      margin: 15px;

      .del {
        color: #ff0000;
      }
    }

    .list {
      width: 100%;
      box-sizing: border-box;
      padding: 5px 30px;

      .list-top {
        display: flex;
        justify-content: space-between;

        span {
          color: #058af2;
        }

        span:last-child:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .list-item {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .items {
          margin-top: 10px;
          width: 255px;
          height: 155px;
          background: transparent url("../../static/imgs/beijing1.png")
            no-repeat scroll 0% 0%;
          position: relative;

          .border {
            box-sizing: border-box;
            padding: 15px;
            font-size: 14px;
            font-weight: 400;

            p {
              padding: 3px 0 5px 0;

              border-bottom: 1px solid #e6e6e6;
            }

            i {
              display: inline-block;
              margin-right: 10px;
            }

            p:nth-child(2) {
              height: 65px;
              display: flex;
              align-items: center;

              span {
                display: block;
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
              }
            }

            p:last-child {
              border-bottom: none;
            }
          }

          .top {
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            color: #fff;

            .top-df {
              display: none;
              background: #fbb244 none repeat scroll 0% 0%;
              padding: 0px 10px;
              border-radius: 0px 0px 5px 5px;
            }
          }

          .active {
            display: block;
            padding: 0 10px;
            background: #058af2 none repeat scroll 0% 0%;
          }

          .top:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          .bottom {
            display: none;
            position: absolute;
            right: 20px;
            bottom: 8px;
            cursor: pointer;
            font-size: 14px;

            p {
              float: left;
              border: 1px solid #d4d4d4;
              margin-left: 5px;
              height: 25px;
              line-height: 25px;
              width: 40px;
              text-align: center;
              border-radius: 3px;
              background-color: #fff;
            }

            p:hover {
              color: #058af2;
            }
          }
        }

        .items-bgd {
          background: transparent url("../../static/imgs/beijing2.png")
            no-repeat scroll 0% 0%;
        }

        .items:hover .top-df {
          display: block;
        }

        .items:hover .bottom {
          display: block;
        }

        .items:nth-last-child(-n + 1) {
          height: 0;
          background: none;
        }
      }
    }
  }

  /*账户充值*/
  .recharge {
    .card-form {
      width: 50%;
      margin: 30px auto;
      /*账户充值_金额数目*/
      .money {
        color: red;
        padding: 0 5px;
        font-weight: bold;
      }
    }

    /*付款方式*/
    .payment {
      width: 95%;
      border-top: 1px dashed #e6e6e6;

      .text {
        font-size: 14px;
        width: 50%;
        margin: 20px auto 20px;
      }

      /*付款平台*/
      .pingtai {
        width: 50%;
        overflow: hidden;
        margin: 20px auto 20px;

        ul li {
          float: left;
          display: inline;
          position: relative;
          width: 177px;
          height: 50px;
          cursor: pointer;
          border: 1px solid #d2d1d1;
          margin-right: 18px;
          /*微信支付*/
          .wx {
            background: url(../../static/imgs/sprites-payment-logo.png)
              no-repeat;
            background-position: 0 -1880px;
            display: inline-block;
            width: 175px;
            height: 37px;
            vertical-align: middle;
            position: absolute;
            top: 7px;
            *top: 5px;
            left: 1px;
            z-index: 2;
          }

          /*支付宝支付*/
          .zfb {
            background: url(../../static/imgs/sprites-payment-logo.png)
              no-repeat;
            background-position: 0 -120px;
            display: inline-block;
            width: 175px;
            height: 37px;
            vertical-align: middle;
            position: absolute;
            top: 7px;
            *top: 5px;
            left: 1px;
            z-index: 2;
          }
        }
      }
    }

    .img {
      margin-right: 90px;
      display: flex;
      justify-content: center;
    }

    .btns {
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: #fbb244;
        font-size: 14px;
      }
    }

    p {
      text-align: center;
      color: #ff0000;
      margin-right: 90px;
    }
  }

  /*我的账单*/
  .bill {
    /*筛选条件*/
    .card-form {
      display: flex;
      line-height: 40px;
      margin: 20px auto;
      margin-right: 20px;
      justify-content: flex-end;

      .el-form-item {
        margin-bottom: 0px;
      }

      .el-form-item__label {
        font-size: 16px !important;
      }

      label {
        font-size: 16px !important;
      }

      div {
        margin: auto 5px;
      }
    }

    /*表格*/
    .table {
      margin-right: 0;
      border-top: 1px dashed #e6e6e6;
    }

    /*分页*/
    .pages {
      display: flex;
      margin: 20px 20px;
      justify-content: space-between;
    }
  }
}

.demo-ruleForm {
  ::v-deep .el-input {
    width: 100%;
  }
}

.orderInfo {
  display: flex;
}

// .el-form-item__content {
//   display: flex;
// }
.footer {
  float: right;
  margin-left: 10px;
}
</style>

<style lang="scss">
.table {
  .el-table .cell {
    text-align: center !important;
  }
}

.invoice {
  .el-row {
    text-align: center;
    margin: 50px 0;
  }
}

.invoice {
  .el-form {
    margin: 30px;
  }
}

.page {
  margin: 15px;
}
</style>
