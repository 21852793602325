<template>
  <div>
    <div class="card">
      <div class="card-left">
        <div class="top">
          <i class="iconfont icon-iconcopy"></i>
          <span>会员管理</span>
        </div>
        <ul class="infos">
          <li
            class="info-item"
            :class="checked === item.id ? 'checked' : ''"
            v-for="item in userInfoList"
            :key="item.id"
            @click="replace(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="card-right">
        <!-- 会员列表 -->
        <template v-if="checked === 0">
          <div class="card-top">
            <span></span>
            <h3>会员管理列表</h3>
          </div>
          <div class="query">
            <el-select v-model="pages.userLevel" placeholder="请选择会员级别">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="value2"
              class="input"
              v-if="userType === 1"
              placeholder="请选择客户或客服"
            >
              <el-option
                v-for="item in optionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              class="input"
              v-model="memberPhone"
              placeholder="请输入手机号"
            ></el-input>
            <el-input
              class="input"
              v-model="custName"
              placeholder="请输入企业名称"
            ></el-input>
            <el-button type="primary" class="btn" @click="queryMemberList"
              >查询</el-button
            >
            <el-button class="btn" @click="refresh">刷新</el-button>
            <el-button type="primary" @click="add">新增</el-button>
          </div>
          <div class="tab">
            <el-table
              :data="tableData"
              border
              style="width: 100%"
              key="memberTable"
            >
              <el-table-column
                prop="name"
                label="会员"
                header-align="center"
                align="center"
              >
                <!-- <template slot-scope="scope">
                <span>{{scope.row.name?scope.row.name:scope.row.username}}</span>
              </template> -->
              </el-table-column>
              <el-table-column
                  prop="extendColumn2"
                  label="联系人"
                  header-align="center"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                label="联系方式"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.phone ? scope.row.phone : scope.row.username
                  }}</span>
                  <i
                    class="el-icon-edit type"
                    @click="editPhone(scope.row)"
                  ></i>
                </template>
              </el-table-column>
              <el-table-column
                v-if="showRow"
                label="会员级别"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.userLevel }}</span>
                  <i
                    class="el-icon-edit type"
                    @click="editMember(scope.row)"
                  ></i>
                </template>
              </el-table-column>
              <el-table-column
                v-if="showRow"
                label="账期天数"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.userLevel == '账期客户'"
                    >{{ scope.row.paymentDays }}天</span
                  >
                  <span v-else>----</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="userType === 1 && showRow"
                label="所属客服"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.customerServiceName }}</span>
                  <i
                    class="el-icon-edit type"
                    @click="editService(scope.row)"
                  ></i>
                </template>
              </el-table-column>
              <!-- <el-table-column
              label="授信用户"
              width="180"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <span>授信金额:{{ scope.row.userFinance }}元</span>
                <i class="el-icon-edit type" @click="editMoney(scope.row)"></i>
              </template>
            </el-table-column> -->
              <el-table-column
                v-if="showRow"
                label="客户余额"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.balance }}元</span>
                  <i
                    class="el-icon-edit type"
                    @click="supplement(scope.row)"
                  ></i>
                </template>
              </el-table-column>
              <el-table-column
                label="注册时间"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="showBtn"
                label="操作"
                header-align="center"
                align="center"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button type="primary" @click="look(scope.row)"
                    >查看客户列表</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="page">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.currentPage"
                :page-sizes="[5, 10, 20, 30]"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
            <!-- 补款金额 -->
            <el-dialog title="补款" :visible.sync="supplementDig" width="30%">
              <div class="contents">
                <div class="top">
                  <span>补款金额:</span>
                  <!-- <el-input
                    type="number"
                    v-model.number="supplementFrom.money"
                  ></el-input>  -->
                  <el-input-number
                    v-model="supplementFrom.money"
                    :min="0"
                  ></el-input-number>
                </div>
                <div class="bom">
                  <span>添加备注:</span
                  ><el-input
                    class="input"
                    type="textarea"
                    :rows="2"
                    v-model="supplementFrom.remarks"
                  ></el-input>
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="supplementDig = false">取 消</el-button>
                <el-button
                  type="primary"
                  :loading="supplementLoading"
                  @click="supplementEditMoney"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- 修改会员对应客服弹框 -->
            <el-dialog
              title="修改当前客服"
              :visible.sync="dialogServer"
              width="30%"
              class="dig"
            >
              <el-select v-model="servers" filterable placeholder="请选择客服">
                <el-option
                  v-for="item in optionServer"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <span slot="footer" class="dialog-footer">
                <el-button @click="clearServe">取 消</el-button>
                <el-button type="primary" @click="sureEditServer"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- 修改会员等级弹框 -->
            <el-dialog
              title="修改会员等级"
              @close="clearDataMember"
              :visible.sync="dialogVisible"
              width="30%"
              class="dig"
            >
              <el-select
                v-model="value1"
                placeholder="请选择会员级别"
                @change="selectChangeEidt"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <div class="days" v-if="showEditDay">
                <!-- <span>账期天数：</span>
                <el-input-number
                  v-model="paymantDay"
                  controls-position="right"
                  :min="0"
                ></el-input-number> -->
                <el-select v-model="paymantDay" placeholder="请选择账期天数">
                  <el-option
                    v-for="item in seltDay"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="clearData">取 消</el-button>
                <el-button type="primary" @click="sureEditMember"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- 修改会员联系方式弹框 -->
            <el-dialog
              title="修改会员联系方式"
              :visible.sync="editPhones"
              width="30%"
              class="dig"
            >
              <el-input
                placeholder="请输入用户联系方式"
                v-model.trim="value3"
                clearable
              >
              </el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="clearData">取 消</el-button>
                <el-button
                  :loading="loading"
                  type="primary"
                  @click="sureEditPhone"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- 修改授信金额弹框 -->
            <el-dialog
              title="修改授信金额"
              :visible.sync="dialogVisible1"
              width="30%"
              class="dig"
            >
              <el-input
                placeholder="请输入授信金额"
                v-model.number="input"
                clearable
              >
              </el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="clearData">取 消</el-button>
                <el-button type="primary" @click="sureEditMoney"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- 客服的会员列表 -->
            <el-dialog
              :title="titles"
              :visible.sync="dialogVisible2"
              width="60%"
              class="dig"
            >
              <el-table
                :data="serviceList"
                border
                style="width: 100%"
                key="tabs"
              >
                <el-table-column
                  prop="name"
                  label="会员名称"
                  width="180"
                  header-align="center"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="extendColumn2"
                    label="联系人"
                    width="180"
                    header-align="center"
                    align="center"
                >
                </el-table-column>

                <!-- <el-table-column
                  prop="custName"
                  label="企业名称"
                  width="180"
                  header-align="center"
                  align="center"
                >
                </el-table-column> -->
                <el-table-column
                  prop="phone"
                  label="联系方式"
                  width="180"
                  header-align="center"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  label="会员级别"
                  width="200"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.userLevel }}</span>
                    <i
                      v-if="userType === 1"
                      class="el-icon-edit type"
                      @click="editMember(scope.row)"
                    ></i>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                label="授信用户"
                width="180"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span>授信金额:{{ scope.row.userFinance }}元</span>
                  <i class="el-icon-edit type" @click="editMoney(scope.row)"></i>
                </template>
              </el-table-column> -->
                <el-table-column
                  prop="createTime"
                  label="注册时间"
                  header-align="center"
                  align="center"
                  width="220"
                >
                </el-table-column>
              </el-table>
              <div class="page">
                <el-pagination
                  @size-change="handleSizeChange1"
                  @current-change="handleCurrentChange1"
                  :current-page="currentPage"
                  :page-sizes="[5, 10, 20, 30]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total1"
                >
                </el-pagination>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible2 = false"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
          </div>
          <!-- 新增会员弹框 -->
          <div class="dilogs">
            <el-dialog
              title="新增会员"
              :visible.sync="dialogFormVisible"
              @close="clearForm"
            >
              <el-form
                :model="form"
                ref="rulFrom"
                :rules="rule"
                label-width="100px"
                style="width: 80%; margin: 0 auto"
              >
                <el-form-item label="会员名称" prop="name">
                  <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="extendColumn2">
                  <el-input v-model="form.extendColumn2" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="联系方式" prop="tel">
                  <el-input v-model="form.tel" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="选择级别" prop="userLevel">
                  <el-select
                    @change="selectChange"
                    v-model="form.userLevel"
                    placeholder="请选择会员级别"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="账期天数" v-if="showDay" prop="paymantDay">
                  <!-- <el-input-number
                    v-model="form.paymantDay"
                    controls-position="right"
                    :min="0"
                  ></el-input-number> -->
                  <el-select v-model="form.paymantDay" placeholder="请选择">
                    <el-option
                      v-for="item in seltDay"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="收货地址" prop="addressCodeList">
                  <el-cascader
                    size="large"
                    :options="optionsList"
                    v-model="form.addressCodeList"
                    @change="handleChange1"
                  >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                  <el-input v-model.trim="form.address"></el-input>
                </el-form-item>
              </el-form>
              <div class="tableList" v-if="userType === 1">
                <span>选择客服： </span>
                <el-select
                  v-model="customer"
                  filterable
                  clearable
                  placeholder="请选择客服"
                >
                  <el-option
                    v-for="item in customerList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addCustomer">确 定</el-button>
              </div>
            </el-dialog>
          </div>
        </template>
        <!-- 客户对账 -->
        <div v-if="checked === 1">
          <div class="card-top">
            <span></span>
            <h3>客户对账</h3>
          </div>
          <div class="query1">
            <el-row :gutter="10">
              <el-col :span="8" class="colls">
                <el-date-picker
                  v-model="startTime"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                <div class="kuoz">-</div>
                <el-date-picker
                  v-model="endTime"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="8" class="selects">
                <el-select
                  class="select-one"
                  v-model="select2"
                  clearable
                  filterable
                  @change="changeUser"
                  placeholder="请选择客户"
                >
                  <el-option
                    v-for="item in selectList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="select1"
                  clearable
                  placeholder="请选择对账状态"
                >
                  <el-option
                    v-for="item in selectList1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="6">
                <el-button type="primary" class="btn" @click="getBill"
                  >查询</el-button
                >
                <el-button class="btn" @click="refresh1">刷新</el-button>
              </el-col>
            </el-row>
          </div>
          <!-- 表格 -->
          <div class="tables">
            <el-table
              :cell-style="tableRowStyle"
              :data="tableData1"
              border
              key="tables"
              style="width: 100%"
              @select="moreSelect"
              @selection-change="changeSelect"
            >
              <el-table-column
                type="selection"
                width="55"
                :selectable="selectEnable"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                header-align="center"
                label="订单详情"
                width="500"
              >
                <template slot-scope="scope">
                  <div class="top">
                    下单时间：<span>{{ scope.row.ordertime }}</span>
                    订单编号：<span>{{ scope.row.id }}</span>
                  </div>
                  <div class="box">
                    <div class="img">
                      <img :src="picUrls + scope.row.productimage" alt="" />
                    </div>
                    <div class="info">
                      <p class="p1">产品名称：{{ scope.row.ordername }}</p>
                      产品类型:<span>{{
                        scope.row.productid == 1038
                          ? "综合"
                          : scope.row.productid == 1036
                          ? "挂历"
                          : scope.row.productid == 1035
                          ? "台历"
                          : scope.row.productid == 1034
                          ? "精装书"
                          : scope.row.productid == 1032
                          ? "封套"
                          : scope.row.productid == 1028
                          ? "单页/海报"
                          : scope.row.productid == 1027
                          ? "教辅"
                          : scope.row.productid == 1024
                          ? "瓦楞彩箱"
                          : scope.row.productid == 1023
                          ? "单层卡纸彩盒"
                          : scope.row.productid == 1020
                          ? "画册/样本"
                          : scope.row.productid == 1018
                          ? "装帧加工"
                          : scope.row.productid == 1016
                          ? "印刷加工"
                          : scope.row.productid == 1015
                          ? "手提袋"
                          : ""
                      }}</span>
                      <p class="p3">
                        印刷数量：<span>{{ scope.row.quantitydemanded }}</span>
                      </p>
                    </div>
                    <div class="rights">
                      <p class="p3">
                        订单金额：<span>{{ scope.row.discountfee }}</span>
                      </p>
                      <p
                        class="p3"
                        v-if="scope.row.whetherFullPayment !== null"
                      >
                        付款方式：线下付款
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                label="订单状态"
                width="300"
              >
                <template slot-scope="scope">
                  <div class="top">
                    生产状态：<span>{{
                      scope.row.status === "-1"
                        ? "待核价"
                        : scope.row.status === "1"
                        ? "待审核"
                        : scope.row.status === "2"
                        ? "待排产"
                        : scope.row.status === "3"
                        ? "生产中"
                        : scope.row.status === "4"
                        ? "生产完成"
                        : scope.row.status === "5"
                        ? "待收货"
                        : scope.row.status === "6"
                        ? "订单完成"
                        : scope.row.status === "7"
                        ? "订单取消"
                        : scope.row.status === "8"
                        ? "订单完成(已评价)"
                        : scope.row.status === "9"
                        ? "待印前制作"
                        : scope.row.status === "10"
                        ? "待客确认"
                        : scope.row.status === "11"
                        ? "已收货"
                        : ""
                    }}</span>
                  </div>
                  <div class="box boxInfo"></div>
                </template>
              </el-table-column>
              <el-table-column header-align="center" label="交易状态">
                <template slot-scope="scope">
                  <div class="top">
                    <el-button
                      @click="track(scope.row)"
                      size="small"
                      type="primary"
                      ><i class="el-icon-time"></i>进度追踪
                    </el-button>
                  </div>
                  <div class="box types">
                    <el-link
                      @click="details(scope.row)"
                      :underline="false"
                      type="primary"
                      >订单详情
                    </el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="billBottom">
            <p>
              总计:<span class="all">{{ allMoney }}</span> 已选对账金额：<span
                >{{ billMoney }}</span
              >
            </p>
            <div class="right">
              <el-button
                type="primary"
                @click="reconciliation"
                :disabled="disabled"
                >{{ billText }}</el-button
              >
              <el-button type="primary" @click="lookInfo1"
                >当前客户对账记录</el-button
              >
            </div>
          </div>
          <div class="page">
            <el-pagination
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
              :current-page="pageNumber1"
              :page-sizes="[5, 10, 15, 20, 30]"
              :page-size="pageSize1"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total2"
            >
            </el-pagination>
          </div>
          <!--进度追踪弹出层-->
          <el-dialog :visible.sync="speed" title="订单进度追踪" width="30%">
            <el-timeline :reverse="true" v-if="activities.length > 0">
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :color="activity.color"
                :timestamp="activity.opTime"
              >
                {{ activity.opProject }}
              </el-timeline-item>
            </el-timeline>
            <p v-else>当前订单流程暂未更新，请谅解...</p>
            <span class="dialog-footer" slot="footer">
              <el-button @click="speed = false">取 消</el-button>
              <el-button @click="speed = false" type="primary">确 定</el-button>
            </span>
          </el-dialog>
          <!-- 订单详情弹框 -->
          <el-dialog
            :visible.sync="detail"
            class="detail"
            :title="title"
            width="55%"
          >
            <order-details
              v-bind:orderId="orderInfoId"
              :key="timer"
            ></order-details>
          </el-dialog>
          <!-- 对账弹框 -->
          <el-dialog title="对账确认" :visible.sync="billShow" width="30%">
            <div class="content">
              <p>
                客户名称：<span style="color: #409eff">{{ userName }}</span>
              </p>
              <div class="inputLabel">
                初收金额：<el-input
                  placeholder="请输入初收金额"
                  v-model.number="onceMoney"
                  clearable
                >
                </el-input>
              </div>
              <p v-if="selectListAll.length > 0">
                对账金额：<span style="color: #ff0000">{{ billMoney }}</span>
              </p>
              <div class="inputLabel">
                收款金额：<el-input
                  placeholder="收款金额"
                  v-model.number="twoMoney"
                  clearable
                >
                </el-input>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="billShow = false">取 消</el-button>
              <el-button type="primary" @click="determine">确 定</el-button>
            </span>
          </el-dialog>

          <!-- 对账流水弹框 -->
          <el-dialog title="对账记录" :visible.sync="dialogTableVisible">
            <div class="query2">
              <el-date-picker
                v-model="startTime2"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <div class="kuoz">-</div>
              <el-date-picker
                v-model="endTime2"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-button type="primary" class="btn" @click="infos"
                >查询</el-button
              >
              <el-button class="btn" @click="refresh3">刷新</el-button>
            </div>
            <el-table :data="gridData1" style="margin-bottom: 10px" key="buill">
              <el-table-column
                property="userName"
                label="客户名称"
              ></el-table-column>
              <el-table-column property="custLevelName" label="客户类型">
              </el-table-column>
              <el-table-column
                property="extendColumn1"
                label="初期应收"
              ></el-table-column>
              <el-table-column
                property="reconciliationMoney"
                label="本月销售"
              ></el-table-column>
              <el-table-column
                property="payMoney"
                label="收款"
              ></el-table-column>
              <el-table-column
                property="createTime"
                label="日期"
                width="200"
                align="center"
                header-align="center"
              ></el-table-column>
              <el-table-column label="欠款余额">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.reconciliationType == 1
                      ? `${scope.row.lastMoney}`
                      : `-${scope.row.lastMoney}`
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="page">
              <el-pagination
                @size-change="handleSizeChange4"
                @current-change="handleCurrentChange4"
                :current-page="pageNumber4"
                :page-sizes="[5, 10, 20, 30]"
                :page-size="pageSize4"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total5"
              >
              </el-pagination>
            </div>
          </el-dialog>
        </div>
        <!-- 对账记录 -->
        <div v-if="checked === 2">
          <div class="card-top">
            <span></span>
            <h3>对账记录</h3>
          </div>
          <div class="query1">
            <el-row :gutter="10">
              <el-col :span="4">
                <el-input
                  v-model="oneMember"
                  placeholder="请选择客户"
                  @focus="changeMember"
                ></el-input>
              </el-col>
              <el-col :span="4">
                <el-select
                  v-model="bills"
                  filterable
                  placeholder="请选择对账状态"
                >
                  <el-option
                    v-for="item in billsList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="colls">
                <el-date-picker
                  v-model="startTime1"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                <div class="kuoz">-</div>
                <el-date-picker
                  v-model="endTime1"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="6">
                <el-button type="primary" class="btn" @click="lookInfos"
                  >查询</el-button
                >
                <el-button class="btn" @click="refresh2">刷新</el-button>
                <el-button class="btn" type="primary" @click="ERPExport">
                  导出</el-button
                >
              </el-col>
            </el-row>
          </div>
          <el-table :data="gridData" style="margin-bottom: 10px" key="buill">
            <el-table-column prop="orderIds" label="订单号"></el-table-column>
            <el-table-column prop="orderTime" label="下单日期">
            </el-table-column>
            <el-table-column
              prop="orderName"
              label="订单名称"
            ></el-table-column>
            <el-table-column
              prop="customerName"
              label="客户名称"
            ></el-table-column>
            <el-table-column
              prop="quantitydemanded"
              label="数量"
              align="center"
              header-align="center"
            ></el-table-column>
            <el-table-column
              label="装订方式"
              prop="bindingType"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="invoiceCode"
              label="送货单号"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="invoiceTime"
              align="center"
              header-align="center"
              label="出库时间"
            >
            </el-table-column>
            <el-table-column
              prop="invoiceCount"
              align="center"
              header-align="center"
              label="签收数量"
            >
            </el-table-column>
            <el-table-column
              prop="discountFee"
              align="center"
              header-align="center"
              label="金额"
            >
            </el-table-column>
          </el-table>
          <div class="page">
            <el-pagination
              style="margin: 10px 0"
              @size-change="handleSizeChange3"
              @current-change="handleCurrentChange3"
              :current-page="pageNumber2"
              :page-sizes="[5, 10, 15, 20, 30]"
              :page-size="pageSize2"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total3"
            >
            </el-pagination>
          </div>
          <div class="dialogs">
            <el-dialog
              :close-on-click-modal="false"
              title="选择客户"
              :visible.sync="dialogVisible3"
              @close="closed"
            >
              <div class="queryMember">
                <el-row :gutter="10">
                  <el-col :span="8">
                    <el-input
                      v-model.trim="custName1"
                      placeholder="请输入用户名称"
                    ></el-input
                  ></el-col>
                  <el-col :span="5">
                    <el-button type="primary" @click="queryMember"
                      >查询</el-button
                    >
                    <el-button @click="refMember">刷新</el-button>
                  </el-col>
                </el-row>
              </div>
              <el-table
                :data="allList"
                border
                style="width: 100%"
                class="memberTable"
              >
                <el-table-column
                  header-align="center"
                  align="center"
                  width="100"
                >
                  <template scope="scope">
                    <el-radio
                      :label="scope.row.user_ID"
                      v-model="radio"
                      @change.native="getCurrentRow(scope.row)"
                    ></el-radio>
                  </template>
                </el-table-column>

                <el-table-column
                  property="name"
                  label="用户名称"
                  header-align="center"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  label="联系方式"
                  width="180"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.phone ? scope.row.phone : scope.row.username
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  property="createTime"
                  label="注册时间"
                  width="200"
                  header-align="center"
                  align="center"
                >
                </el-table-column>
              </el-table>

              <div class="page">
                <el-pagination
                  @size-change="handleSizeChange4"
                  @current-change="handleCurrentChange4"
                  :current-page="pageNumber3"
                  :page-sizes="[5, 10, 20, 30]"
                  :page-size="pageSize3"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total4"
                >
                </el-pagination>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible3 = false">取 消</el-button>
                <el-button type="primary" @click="sureChange">确 定</el-button>
              </span>
            </el-dialog>
          </div>
        </div>
        <!-- 报表管理 -->
        <div v-if="checked === 3">
          <order-report></order-report>
        </div>
<!--        &lt;!&ndash; 发票申请 &ndash;&gt;-->
<!--        <div v-if="checked === 4">-->
<!--          <shop-invoice ref="shopInvoices"></shop-invoice>-->
<!--        </div>-->
        <!-- 应收对账 -->
        <div v-if="checked === 5">
          <receivable ref="receivable"></receivable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
import orderReport from "@/components/admins/orderReport";
import shopInvoice from "../../components/admins/shopInvoice.vue";
import api from "../../api/webAdmin/webAdmin";
import apis from "../../api/invoice/invoice";
import picUrl from "../../api/picture";
import orderDetails from "../../components/OrderDetails/OrderDetails.vue";
import receivable from "../../components/personal/receivable.vue"; // 应收对账
import url from "../../api/picture";
export default {
  name: "members",
  components: {
    orderDetails,
    orderReport,
    shopInvoice,
    receivable,
  },
  data() {
    return {
      supplementLoading: false,
      supplementDig: false, // 补款
      supplementFrom: {
        money: "",
        remarks: "",
      },
      seltDay: [
        {
          value: "30",
          label: "30天",
        },
        {
          value: "45",
          label: "45天",
        },
        {
          value: "60",
          label: "60天",
        },
      ],
      showEditDay: false,
      showDay: false, // 展示输入框
      paymantDay: "", // 修改会员级别账期天数
      optionServer: [], // 客服下拉列表
      servers: "", // 选择的客服
      dialogServer: false, // 修改会员对应客服弹框
      queryMemberName: "", // 管理员查询客服的客户数据
      optionsList: regionData,
      total5: 0,
      pageNumber4: 1,
      pageSize4: 5,
      startTime2: "",
      endTime2: "",
      dialogTableVisible: false,
      radio: "",
      total4: 0,
      custName1: "", // 客户名称（筛选）
      pageSize3: 5, // 客户列表分页
      pageNumber3: 1,
      dialogVisible3: false, // 选择客户弹窗
      loading1: false, //客户查询
      bills: 0,
      billsList: [
        {
          value: 0,
          label: "未对账",
        },
        {
          value: 1,
          label: "对账中",
        },
        {
          value: 2,
          label: "对账完成",
        },
      ],
      oneMember: "", //选择的客户
      memberList: [],
      customer: "", // 选择的客服
      customerList: [], // 客服列表
      form: {
        name: "",
        extendColumn2: '',
        tel: "",
        addressCodeList: [],
        address: "",
        userLevel: "",
        paymantDay: "",
      },
      rule: {
        name: [{ required: true, message: "请输入会员名称", trigger: "blur" }],
        extendColumn2: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        tel: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          { min: 6, message: "长度至少 6 个及以上", trigger: "blur" },
        ],
        userLevel: [
          { required: true, message: "请选择会员级别", trigger: "blur" },
        ],
        paymantDay: [
          { required: true, message: "请输入账期天数", trigger: "blur" },
        ],
        addressCodeList: [
          { required: true, message: "请选择地址", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
      dialogFormVisible: false, // 新增会员弹框
      gridData: [], // 存在erp列表数据
      gridData1: [], // 无erp列表流水
      // lookInfo: false,
      twoMoney: "", // 对账收款
      onceMoney: "", // 初收款
      destructionMoney: 0, // 选择对账金额
      billShow: false,
      handleClose: false,
      disabled: false,
      billText: "开始对账",
      allMoney: 0,
      title: "",
      timer: "",
      orderInfoId: "",
      detail: false,
      speed: false,
      activities: [],
      tableData1: [],
      loading: false,
      value3: "", // 修改用户电话
      editPhones: false,
      select1: 0, // 对账状态
      selectList1: [
        {
          value: 0,
          label: "未对账",
        },
        {
          value: 1,
          label: "对账中",
        },
        {
          value: 2,
          label: "对账完成",
        },
      ],
      selectList: [], // 客户列表
      select2: "", // 客户选择
      startTime: "",
      endTime: "",
      dialogVisible2: false,
      serviceList: [], // 客服的会员
      total1: 0, // 客服的会员
      titles: "",
      showRow: true, // 默认展示会员级别和所属客服
      showBtn: false, // 选择客服展示操作按钮
      value2: "0", // 客服为2、客户为0(默认)
      optionList: [
        {
          value: "0",
          label: "客户",
        },
        {
          value: "2",
          label: "客服",
        },
      ],
      checked: 0,
      userInfo: [
        {
          id: 0,
          text: "会员列表",
        },
        {
          id: 1,
          text: "客户对账",
        },
        // {
        //   id: 2,
        //   text: "对账记录",
        // },
        {
          id: 3,
          text: "订单统计",
        },
        {
          id: 5,
          text: "收款统计",
        },
      ],
      custName: "", // 公司名称
      memberPhone: "", // 会员手机号
      input: "", // 授信金额
      value1: "", // 修改会员绑定
      dialogVisible: false, // 修改会员
      dialogVisible1: false, // 修改金额
      options: [],
      value: "", // 查询绑定
      pageSize: 5,
      currentPage: 1,
      pageSize1: 5, // 对账
      pageNumber1: 1,
      startTime1: "",
      endTime1: "",
      total2: 0, // 对账
      pageSize2: 5, // 对账流水
      pageNumber2: 1,
      total3: 0, // 对账流水
      pages: {
        pageSize: 5,
        currentPage: 1,
        userLevel: "",
      },
      total: 0,
      tableData: [],
      userId: "", // 用户id
      user_ID: "",
      userType: 0,
      role_ID: "",
      selectListAll: [],
      picUrls: "",
      CCID: "", // custID
      CCUID: "", // 客户userId
      userName: "",
      allList: [],
      memberId: "", // 客户id
      haveERP: "",
      addressCodeList: [],
      addressInfo: {},
      baseUrl: url.baseURL,
      serverCustomeId: "",
      loginName: "", //登录人姓名
    };
  },
  watch: {
    select1(v) {
      if (v === 0 || v === "") {
        this.billText = "开始对账";
        this.disabled = false;
      } else if (v === 1) {
        this.billText = "结束对账";
        this.disabled = false;
      } else {
        this.billText = "对账完成";
        this.disabled = true;
      }
    },
    allList(v) {
      if (v.length === 1) {
        let data = v[0];
        this.radio = data.user_ID;
        this.memberId = data.customerId;
        this.oneMember = data.name;
      }
    },
  },
  created() {
    this.picUrls = picUrl.picUrl;
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    let haveErp = JSON.parse(window.sessionStorage.getItem("userInfo"));
    if (obj) {
      this.userType = obj.userType;
      this.user_ID = obj.user_ID;
      this.loginName = obj.name;
    } else {
      return;
    }
    if (haveErp) {
      // 存在erp为1
      this.haveERP = haveErp.haveERP;
    }
    this.init();
  },
  computed: {
    billMoney() {
      return this.selectListAll.reduce((pav, next) => {
        return (pav += Number(next.discountfee));
      }, 0);
    },
    userInfoList() {
      if (this.haveERP) {
        // 不显示客户对账(有erp)
        return this.userInfo.filter((el) => el.id !== 1);
      } else {
        // 无erp显示客户对账
        return this.userInfo.filter((el) => el.id !== 2);
      }
    },
  },
  methods: {
    // 确定补款
    supplementEditMoney() {
      if (this.supplementFrom.money === "") {
        this.$message.warning("请输入补款金额");
        return;
      }
      let data = {
        operatorId: this.user_ID,
        userId: this.supplementUserId,
        money: this.supplementFrom.money,
        type: 1,
        incomeType: 3,
        extendColumn1: this.supplementFrom.remarks,
        extendColumn2: "1",
      };
      console.log(data);
      this.supplementLoading = true;
      api
        .transferReplenishment(data)
        .then((res) => {
          this.supplementLoading = false;
          if (res.success && res.code == 200) {
            this.$message.success("编辑成功");
            this.supplementDig = false;
            this.init();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          this.supplementLoading = false;
          console.error("error", error);
        });
    },
    supplement(item) {
      console.log("客户信息", item);
      this.supplementUserId = item.user_ID;
      this.supplementFrom.money = "";
      this.supplementDig = true;
      if (this.userType === 1) {
        this.supplementFrom.remarks = `(管理员${this.loginName})针对对公转账进行线上充值`;
      } else if (this.userType === 2) {
        this.supplementFrom.remarks = `(客服${this.loginName})针对对公转账进行线上充值`;
      }
    },
    // 关闭会员选择弹框清除参数
    clearDataMember() {
      this.value1 = "";
      this.showEditDay = false;
      this.paymantDay = "";
    },
    // 会员级别变更(修改操作)
    selectChangeEidt(e) {
      console.log("获取变更事件", e);
      if (e == "1025") {
        this.showEditDay = true;
      } else {
        this.showEditDay = false;
      }
    },
    // 会员级别变更(新增操作)
    selectChange(e) {
      console.log("获取变更事件", e);
      if (e == "1025") {
        this.showDay = true;
        this.rule = {
          name: [
            { required: true, message: "请输入企业名称", trigger: "blur" },
          ],
          tel: [
            { required: true, message: "请输入联系方式", trigger: "blur" },
            { min: 6, message: "长度至少 6 个及以上", trigger: "blur" },
          ],
          userLevel: [
            { required: true, message: "请选择会员级别", trigger: "blur" },
          ],
          paymantDay: [
            { required: true, message: "请输入账期天数", trigger: "blur" },
          ],
          addressCodeList: [
            { required: true, message: "请选择地址", trigger: "blur" },
          ],
          address: [
            { required: true, message: "请输入详细地址", trigger: "blur" },
          ],
        };
      } else {
        this.showDay = false;
        this.rule = {
          name: [
            { required: true, message: "请输入企业名称", trigger: "blur" },
          ],
          tel: [
            { required: true, message: "请输入联系方式", trigger: "blur" },
            { min: 6, message: "长度至少 6 个及以上", trigger: "blur" },
          ],
          userLevel: [
            { required: true, message: "请选择会员级别", trigger: "blur" },
          ],
          addressCodeList: [
            { required: true, message: "请选择地址", trigger: "blur" },
          ],
          address: [
            { required: true, message: "请输入详细地址", trigger: "blur" },
          ],
        };
      }
    },
    // 获取客服 （新接口）
    getServers() {
      api.getAllUserDontExistCustom().then((res) => {
        console.log("获取客服列表", res);
        if (res.success) {
          res.data.userPageInfo.forEach((el) => {
            el.label = el.name;
            el.value = el.user_ID;
          });
          this.optionServer = res.data.userPageInfo;
        }
      });
    },
    // 确定修改客服
    sureEditServer() {
      let data = {
        salesmanid: this.servers,
      };
      api.editSalesmanid(this.serverCustomeId, data).then((res) => {
        console.log("修改会员客服", res);
        if (res.success) {
          this.$message.success("修改成功");
          this.dialogServer = false;
          this.init();
        } else {
          this.$message.error("修改失败");
        }
      });
    },
    // 取消清楚参数
    clearServe() {
      this.servers = "";
      this.dialogServer = false;
    },
    // 管理员编辑客服
    editService(item) {
      this.servers = "";
      console.log(item);
      this.serverCustomeId = item.customerId;
      this.dialogServer = true;
      this.getServers();
    },
    // 导出按钮
    ERPExport() {
      if (this.radio === "") {
        this.$message.warning("请先选择需要对账的客户！");
        return;
      }
      let len = this.gridData.length;
      if (len <= 0) {
        this.$message.warning("当前列表暂无数据导出！");
        return;
      }
      let kpiUrl = this.baseUrl;
      let url = `/orderinfo/orderReconciliationERPExport?userId=${this.user_ID}&customerId=${this.memberId}&pageSize=${this.pageSize2}&pageNumber=${this.pageNumber2}&startTime=${this.startTime1}&endTime=${this.endTime1}&accStatus=${this.bills}`;
      window.location.href = `${kpiUrl}${url}`;
      // let app = document.getElementById("app");
      // let $form = document.createElement("form");
      // $form.setAttribute("action", `${kpiUrl}${url}`);
      // $form.setAttribute("method", "GET");
      // $form.setAttribute("target", "_blank");
      // app.appendChild($form);
      // console.log($form);
      // $form.submit();
      // app.removeChild($form);
      // api
      //   .orderReconciliationERPExport(
      //     this.user_ID,
      //     this.memberId,
      //     this.pageNumber2,
      //     this.pageSize2,
      //     this.startTime1,
      //     this.endTime1,
      //     this.bills
      //   )
      //   .then((res) => {
      //     console.log("导出接口调用", res);
      //     if (res.success) {
      //       this.$message.success("导出成功！");
      //     } else {
      //       this.$message.error("导出失败！");
      //     }
      //   });
    },
    // 地址反显
    handleChange1(value) {
      console.log(value);
      this.addressCodeList = [...value];
      this.addressInfo = {
        provinceZh: CodeToText[value[0]], // 江苏省
        cityZh: CodeToText[value[1]], // 南京市
        areaZh: CodeToText[value[2]], // 江宁区
      };
    },
    // 单选
    getCurrentRow(item) {
      console.log(item);
      this.memberId = item.customerId;
      this.oneMember = item.name;
    },
    // 确定选择
    sureChange() {
      if (this.radio === "") {
        this.$message.warning("请选择客户！");
        return;
      }
      this.dialogVisible3 = false;
      this.lookInfos();
    },
    // 弹窗关闭操作
    closed() {
      if (!this.dialogVisible3) {
        // this.dialogVisible3 = false;
        if (this.radio === "") {
          this.oneMember = "";
          this.memberId = "";
        }
      }
    },
    queryMember() {
      this.getCustomer();
    },
    refMember() {
      this.custName1 = "";
      this.pageNumber3 = 1;
      this.pageSize3 = 5;
      this.getCustomer();
    },
    // 选择客户
    changeMember() {
      this.custName1 = "";
      this.dialogVisible3 = true;
      this.getCustomer();
    },
    // 新增客户
    add() {
      this.dialogFormVisible = true;
      this.getService();
    },
    // 获取客户
    getCustomer() {
      api
        .queryUser(
          this.pageNumber3,
          this.pageSize3,
          "",
          "",
          this.custName1,
          "0"
        )
        .then((res) => {
          console.log("获取客户", res);
          if (res.success) {
            // this.allList = res.data.userPageInfo.list;
            this.allList = res.data.list;
            this.total4 = res.data.total;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 获取客服
    getService() {
      api.getAllUserDontExistCustom().then((res) => {
        console.log("获取客服", res);
        if (res.success) {
          res.data.userPageInfo.forEach((el) => {
            el.label = el.name;
            el.value = el.user_ID;
          });
          this.customerList = res.data.userPageInfo;
        }
      });
    },
    // 清除数据
    clearForm() {
      if (!this.dialogFormVisible) {
        this.showDay = false;
        this.customer = "";
        this.form = {
          name: "",
          tel: "",
          extendColumn2: "",
          address: "",
          addressCodeList: [],
        };
        this.addressInfo = {};
        this.form.addressCodeList = [];
        this.addressCodeList = [];
      }
    },
    // 新增客户
    addCustomer() {
      let data = this.addressInfo;
      let code = this.addressCodeList;
      console.log(data, code);
      this.$refs.rulFrom.validate((valid) => {
        if (valid) {
          // alert("submit!");
          let data1 = {
            userName: this.form.name,
            extendColumn2: this.form.extendColumn2,
            phone: this.form.tel,
            userId: this.user_ID,
            provinceCode: code[0],
            provinceZh: data.provinceZh,
            cityCode: code[1],
            cityZh: data.cityZh,
            areaCode: code[2],
            areaZh: data.areaZh,
            detailAddress: this.form.address,
            custLevel: this.form.userLevel,
            day: this.form.paymantDay,
          };
          if (this.userType === 1) {
            if (this.customer) {
              data1.userId = this.customer;
            }
          }
          console.log("新增会员数据", data1);
          api.addCustomers(data1).then((res) => {
            console.log("新增成功", res);
            if (res.success) {
              this.$message.success("新增客户成功");
              this.dialogFormVisible = false;
              this.init();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 刷新流水
    refresh2() {
      this.startTime1 = this.endTime1 = this.radio = this.oneMember = this.memberId = this.custName1 =
        "";
      this.bills = 0;
      this.total3 = 0;
      this.gridData = [];
      // this.lookInfos();
    },
    // 没有erp(查看流水按钮)
    lookInfo1() {
      this.dialogTableVisible = true;
      this.infos();
    },
    // 刷新
    refresh3() {
      this.startTime2 = this.endTime2 = "";
      this.infos();
    },
    // 获取商城对账流水
    infos() {
      api
        .mallofflineorderreconciliation(
          this.CCUID,
          this.pageNumber4,
          this.pageSize4,
          this.startTime2,
          this.endTime2
        )
        .then((res) => {
          if (res.success) {
            console.log(res);
            this.gridData1 = res.data.list;
            this.total5 = res.data.total;
          }
        });
    },
    // 查看流水存在erp
    lookInfos() {
      if (this.radio === "") {
        this.$message.warning("请先选择需要对账的客户！");
        return;
      }
      api
        .orderReconciliationERP(
          this.user_ID,
          this.memberId,
          this.pageNumber2,
          this.pageSize2,
          this.startTime1,
          this.endTime1,
          this.bills
        )
        .then((res) => {
          console.log("流水列表", res);
          if (res.success) {
            // this.lookInfo = true;
            this.startTime1 = res.data.startTime;
            this.endTime1 = res.data.endTime;
            this.gridData = res.data.orderInfoPageInfo.list;
            this.total3 = res.data.orderInfoPageInfo.total;
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 确定
    determine() {
      if (this.twoMoney === "") {
        this.$message.warning("收款金额必填，请确认是否填写!");
        return;
      }
      let len = this.selectListAll.length;
      let obj = this.selectListAll;
      let arr = [];
      for (let i = 0; i < len; i++) {
        let id = obj[i].id;
        arr.push(id);
      }
      console.log("处理后的订单低集合", arr);
      let data = {
        userId: this.CCUID,
        orderIds: arr.join(","),
        reconciliationMoney: this.billMoney,
        payMoney: this.twoMoney,
        extendColumn1: this.onceMoney,
      };
      api
        .endReconciliation(data)
        .then((res) => {
          console.log("结束对账", res);
          if (res.success) {
            this.$message.success("当次对账已成功完成");
            this.getBill();
            this.billShow = false;
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 客户选择
    changeUser() {
      let label = this.selectList.find((el) => el.user_ID === this.select2);
      console.log("当前客户", label);
      this.userName = label.label;
      this.CCID = label.customerId;
      this.CCUID = label.user_ID;
    },
    // 对账按钮
    reconciliation() {
      //0未对账  1 对账中，2 对账结束
      // if (this.selectListAll.length > 0) {
      if (this.select1 === 0) {
        if (this.selectListAll.length <= 0) {
          this.$message.warning("请选择需要对账的订单");
          return;
        } else {
          let len = this.selectListAll.length;
          let obj = this.selectListAll;
          let arr = [];
          for (let i = 0; i < len; i++) {
            let id = obj[i].id;
            arr.push(id);
          }
          console.log("处理后的订单低集合", arr);
          let data = {
            orderReconciliation: 1,
            id: arr.join(","),
          };
          api
            .startReconciliation(data)
            .then((res) => {
              console.log("开始对账", res);
              if (res.success) {
                this.getBill();
                this.$message.success("成功，请选取对账中列表，进行下一步操作");
              }
            })
            .catch((error) => {
              console.error("error", error);
            });
        }
      }
      if (this.select1 === 1) {
        this.billShow = true;
        console.log("调接口");
      }
      // } else {
      //   this.$message.warning("请选择需要对账的订单");
      //   return;
      // }
    },
    // 禁用条件
    selectEnable(row) {
      // console.log("禁用相关", row);
      if (row.orderReconciliation !== 2) {
        // if (row.orderIssueIsOpen === 0) {
        return true;
      } else {
        return false;
      }
    },
    moreSelect(selection, row) {
      console.log(selection, row);
    },
    changeSelect(selection) {
      console.log(selection);
      this.selectListAll = selection;
    },
    // 表格自定义样式调整
    tableRowStyle() {
      return "padding:0;";
    },
    // 进度追踪
    track(item) {
      console.log(item);
      this.speed = true;
      apis.oplogger(item.id).then((res) => {
        console.log("订单流程图", res);
        if (res.success) {
          if (res.data.length > 0) {
            res.data.forEach((el) => {
              el.color = "#0bbd87";
            });
          }
          this.activities = res.data;
        } else {
          this.$message.error("获取订单流程失败");
        }
      });
    },
    details(item) {
      this.timer = new Date().getTime();
      console.log(item);
      this.orderInfoId = item.id;
      this.title = "订单【" + item.id + "】详情";
      this.detail = true;
    },
    // 嵌套会员列表
    nesting() {
      // let data = {
      //   pageSize: this.pageSize,
      //   pageNumber: this.currentPage,
      //   name: this.queryMemberName,
      //   userId: this.role_ID,
      // };
      api
        .getCustomerClient(
          this.pageSize,
          this.currentPage,
          this.queryMemberName,
          this.role_ID
        )
        .then((res) => {
          console.log("获取会员列表", res);
          if (res.success) {
            this.dialogVisible2 = true;
            this.serviceList = res.data.list;
            this.total1 = res.data.total;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 查看客服的客户列表
    look(item) {
      this.titles = `${item.name}的客户列表`;
      console.log(item);
      this.role_ID = item.user_ID;
      this.nesting();
    },
    replace(item) {
      // localStorage.setItem("type", item.id); //存入本地，防止刷新页面丢失
      console.log(item);
      this.checked = item.id;
      if (this.checked === 0) {
        this.init();
      } else if (this.checked === 1) {
        this.getBill();
      }
      if (item.id === 4) {
        this.$nextTick(() => {
          this.$refs.shopInvoices.getAll();
        });
      }
      if (item.id === 5) {
        this.$nextTick(() => {
          this.$refs.receivable.init();
        });
      }
      // else if(this.checked === 3){

      // }
      //  else {
      //   this.getCustomer();
      // }
      // console.log(item);
    },
    // 对账列表
    getBill() {
      api
        .orderReconciliation(
          this.user_ID,
          this.pageSize1,
          this.pageNumber1,
          this.select1,
          this.CCID,
          this.select2
        )
        .then((res) => {
          console.log("对账列表", res);
          if (res.success) {
            if (res.data !== null) {
              if (res.data.userList) {
                res.data.userList.forEach((el) => {
                  el.label = el.name;
                  el.value = el.user_ID;
                });
              }
              this.$nextTick(() => {
                this.selectList = res.data.userList ? res.data.userList : [];
                this.select2 = res.data.user.user_ID;
                this.userName = res.data.user.name;
                this.CCID = res.data.user.customerId;
                this.CCUID = res.data.user.user_ID;
                this.total2 = res.data.orderInfoPageInfo.total;
                this.tableData1 = res.data.orderInfoPageInfo.list;
                this.allMoney = res.data.sumMoney ? res.data.sumMoney : 0;
              });
            }
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    clearData() {
      this.value1 = this.input = this.userId = this.value3 = "";
      this.dialogVisible = this.dialogVisible1 = this.dialogVisible2 = this.editPhones = false;
      this.showEditDay = false; // 关闭账期天数
      this.customer = "";
    },
    edit(id, type) {
      //(1:授信额度，2用户等级)
      this.loading = true;
      api
        .editMember(
          id,
          this.input,
          type,
          this.value1,
          this.value3,
          this.paymantDay
        )
        .then((res) => {
          console.log("修改用户授信额度或者会员等级", res);
          if (res.success) {
            this.$message.success("修改成功");
            this.clearData();
            this.init();
            this.loading = false;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 修改金额
    sureEditMoney() {
      if (this.input === "") {
        this.$message.warning("请输入需要调整的金额");
        return;
      }
      //(1:授信额度，2用户等级)
      this.edit(this.userId, 1);
    },
    sureEditPhone() {
      if (this.value3 === "") {
        this.$message.warning("请输入用户联系方式");
        return;
      }
      //(1:授信额度，2用户等级)
      this.edit(this.userId, 3);
    },
    // 修改会员等级
    sureEditMember() {
      if (this.value1 === "") {
        this.$message.warning("请输入需要调整的会员级别");
        return;
      }
      if (this.value1 == "1025" && this.paymantDay < 0) {
        this.$message.warning("请输入到期天数");
        return;
      }
      //(1:授信额度，2用户等级)
      this.edit(this.userId, 2);
    },
    // 修改授信金额
    editMoney(item) {
      console.log(item);
      this.dialogVisible1 = true;
      this.userId = item.user_ID;
    },
    // 修改联系方式
    editPhone(item) {
      this.editPhones = true;
      this.userId = item.user_ID;
    },
    // 修改会员级别
    editMember(item) {
      this.dialogVisible = true;
      this.userId = item.user_ID;
      console.log(item);
    },
    // 查询表格
    queryMemberList() {
      if (this.value2 == "0") {
        // 客户
        this.showRow = true;
        this.showBtn = false;
      } else {
        this.showBtn = true;
        this.showRow = false;
      }
      this.init();
    },
    // 刷新表格
    refresh() {
      this.pages.pageSize = 5;
      this.pages.currentPage = 1;
      this.pages.userLevel = this.memberPhone = this.custName = "";
      this.showRow = true;
      this.value2 = "0";
      this.init();
    },
    refresh1() {
      this.startTime = this.endTime = "";
      this.getBill();
    },
    //获取会员列表
    init() {
      // 获取会员列表
      api
        .queryUser(
          this.pages.currentPage,
          this.pages.pageSize,
          this.pages.userLevel,
          this.memberPhone,
          this.custName,
          this.value2
        )
        .then((res) => {
          // console.log("获取会员列表", res);
          if (res.success) {
            this.tableData = res.data.list;
            this.total = res.data.total;
            if (this.value2 === "2") {
              this.showBtn = true;
            } else {
              this.showBtn = false;
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
      // 获取所有等级
      api
        .getUserLeve()
        .then((res) => {
          // console.log("获取所有等级", res);
          if (res.success) {
            let arr = [
              {
                value: "",
                label: "请选择",
              },
            ];
            res.data.forEach((el) => {
              el.value = el.id;
              el.label = el.name;
            });
            this.options = arr.concat(res.data);
          } else {
            this.$message.error("获取会员等级失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.init();
    },
    handleSizeChange1(val) {
      this.pageSize = val;
      this.nesting();
    },
    handleCurrentChange1(val) {
      this.currentPage = val;
      this.nesting();
    },
    // 对账列表分页
    handleSizeChange2(val) {
      this.pageSize1 = val;
      this.getBill();
    },
    handleCurrentChange2(val) {
      this.pageNumber1 = val;
      this.getBill();
    },
    // 对账流水分页
    handleSizeChange3(val) {
      this.pageSize2 = val;
      this.lookInfos();
    },
    handleCurrentChange3(val) {
      this.pageNumber2 = val;
      this.lookInfos();
    },
    // 选择客户分页
    handleSizeChange4(val) {
      this.pageSize3 = val;
      this.getCustomer();
    },
    handleCurrentChange4(val) {
      this.pageNumber3 = val;
      this.getCustomer();
    },
    handleSizeChange5(val) {
      this.pageSize4 = val;
      this.infos();
    },
    handleCurrentChange5(val) {
      this.pageNumber4 = val;
      this.infos();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .card-left {
    width: 200px;

    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;

      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }

    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
      }

      li:last-child {
        border-bottom: none;
      }

      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .card-right {
    width: 980px;
    background-color: #fff;
  }
  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;
    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }
    h3 {
      color: #058af2;
    }
  }
  .query {
    display: flex;
    margin-top: 10px;
    padding: 0 10px;
    .input {
      width: 240px;
      margin-left: 10px;
    }
    .btn {
      margin-left: 10px;
    }
  }
  .query1 {
    margin: 10px;
    .colls {
      display: flex;
      line-height: 40px;
      margin-left: 10px;
      span {
        margin-right: 5px;
      }
      .kuoz {
        margin: 0 10px;
      }
    }
    .selects {
      display: flex;
      .select-one {
        margin-right: 10px;
      }
    }
  }
  .tab {
    padding: 10px;
    .btns {
      margin-bottom: 10px;
    }
    .type {
      margin-left: 15px;
    }
  }
  .page {
    margin-top: 20px;
  }
  .dig {
    text-align: center;
    .days {
      margin-top: 20px;
    }
  }
  .tables {
    margin: 10px 0;
    ::v-deep .el-table .cell {
      padding: 0;
    }

    ::v-deep .el-table .cell,
    ::v-deep .el-table--border td:first-child .cell {
      padding: 0;
    }

    ::v-deep .el-table_1_column_1 {
      padding: 0;
    }

    ::v-deep .el-table td {
      padding: 0;
    }

    .top {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #f8f8f8;
      color: #b4b4b4;

      span {
        color: #000000;
      }
    }

    .box {
      padding: 5px 10px;
      height: 102px;
      display: flex;
      justify-content: space-around;
      .img {
        margin: 4px 15px 0;
        width: 80px;
        height: 80px;

        img {
          display: inline-block;
          width: 80px;
          height: 100%;
        }
      }
      .info {
        width: 35%;
        .p1 {
          color: #409eff;
        }

        .p3 {
          span {
            color: #ff0000;
          }
        }
      }
      .rights {
        width: 30%;
        .p3 {
          span {
            color: #ff0000;
          }
        }
      }
    }

    .types {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        padding: 10px 0;
      }

      .btn {
        width: 50%;
      }
    }

    .boxInfo {
      display: flex;
      justify-content: center;
      align-items: center;

      ::v-deep .el-button {
        height: 40px;
      }
    }
  }
  .billBottom {
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 600;
      font-size: 16px;
      color: #ff0000;
      .all {
        margin-right: 50px;
      }
    }
  }
  .content {
    font-size: 15px;
    font-weight: 600;
    p {
      margin-bottom: 10px;
    }
    .inputLabel {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      ::v-deep .el-input {
        width: 77%;
      }
    }
  }
  ::v-deep .el-dialog {
    width: 60%;
  }
  .dilogs {
    width: 50%;
    ::v-deep .el-dialog {
      width: 35%;
    }
    .tableList {
      text-align: center;
    }
  }
  .memberTable {
    ::v-deep .el-radio__label {
      display: none;
    }
  }
}
.contents {
  .top,
  .bom {
    display: flex;
    align-items: center;
    ::v-deep .el-textarea {
      width: 70%;
    }
  }
  .top {
    margin-bottom: 20px;
  }
}
.query2 {
  margin: 10px;

  display: flex;
  line-height: 40px;
  margin-left: 10px;
  span {
    margin-right: 5px;
  }
  .kuoz {
    margin: 0 10px;
  }
  .btn {
    margin-left: 10px;
  }
}
.dialogs {
  width: 100%;
  .queryMember {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
