<template>
  <div>
    <div class="personalInfo">
      <div class="card-left">
        <div class="top">
          <i class="iconfont icon-iconcopy"></i>
          <span>会员信息</span>
        </div>
        <ul class="infos">
          <li
            class="info-item"
            :class="checked === item.id ? 'checked' : ''"
            v-for="item in userInfo"
            :key="item.id"
            @click="replace(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="card-right">
        <!-- 我的资料 -->
        <div class="info" v-if="checked === 0">
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>我的资料</h3>
              <p @click="cheshi" style="width: 80%; height: 100%"></p>
            </div>
            <div class="card-form">
              <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm1"
                label-width="100px"
                class="demo-ruleForm"
                size="mini"
                key="0"
              >
                <!--                <el-form-item label="手机号码" prop="phone">-->
                <!--                  <el-input-->
                <!--                    v-model.trim="ruleForm.phone"-->
                <!--                    autocomplete="off"-->
                <!--                  ></el-input>-->
                <!--                </el-form-item>-->
                <el-form-item label="联系人" prop="tel">
                  <el-input
                    v-model.trim="ruleForm.tel"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系邮箱" prop="email">
                  <el-input v-model.trim="ruleForm.email"></el-input>
                </el-form-item>
                <el-form-item label="QQ号码" prop="qq">
                  <el-input v-model.number="ruleForm.qq"></el-input>
                </el-form-item>
                <el-form-item label="企业名称" prop="name">
                  <el-input v-model.trim="ruleForm.name"></el-input>
                </el-form-item>
                <!-- <el-form-item label="公司地址" prop="address">
                  <el-input v-model.trim="ruleForm.address"></el-input>
                </el-form-item> -->
                <el-form-item label="收货地址" prop="address">
                  <el-cascader
                    size="large"
                    :options="options"
                    v-model="addressCodeList"
                    @change="handleChange1"
                  >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                  <el-input v-model.trim="ruleForm.address"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm"
                    v-loading.fullscreen.lock="fullscreenLoading"
                    >提交</el-button
                  >
                  <el-button @click="resetForm">重置</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <!-- 密码修改 -->
        <div class="info" v-if="checked === 1">
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>密码修改</h3>
            </div>
            <el-alert
              title="会员登录需要使用您注册的手机号码登录。 修改密码将同步更新通行证密码。"
              type="warning"
              center
              show-icon
            >
            </el-alert>
            <div class="card-form">
              <el-form
                :model="ruleFormPass"
                status-icon
                :rules="rulespass"
                ref="ruleForm2"
                label-width="100px"
                class="demo-ruleForm"
                size="mini"
                key="1"
              >
                <el-form-item label="我的账号" prop="phones">
                  <el-input
                    v-model.trim="ruleFormPass.phones"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="原密码" prop="pass">
                  <el-input
                    v-model.trim="ruleFormPass.pass"
                    autocomplete="off"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPass">
                  <el-input
                    v-model.trim="ruleFormPass.newPass"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="newPass2">
                  <el-input
                    v-model.trim="ruleFormPass.newPass2"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="submitForm"
                    v-loading.fullscreen.lock="fullscreenLoading"
                    >提交</el-button
                  >
                  <el-button @click="resetForm(ruleFormPass)">重置</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <!-- 地址管理 -->
        <div class="info" v-if="checked === 2">
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>地址管理</h3>
            </div>
            <!-- 地址列表 -->
            <div class="list">
              <div class="list-top">
                <span>已保存的收货地址</span>
                <span @click="addAddress">新增收货地址</span>
              </div>

              <div class="list-item" v-if="addressList.length > 0">
                <div
                  class="items"
                  v-for="item in addressList"
                  :key="item.id"
                  :class="item.isDefault === 1 ? 'items-bgd' : ''"
                >
                  <div class="border">
                    <p>
                      <i class="iconfont icon-yonghu"></i>
                      <span>{{ item.receiveName }}</span>
                    </p>
                    <p>
                      <i class="iconfont icon-dizhi"></i>
                      <span>{{ item.addressInfos }}</span>
                    </p>
                    <p>
                      <i class="iconfont icon-shouji"></i>
                      <span>{{ item.receivePhone }}</span>
                    </p>
                  </div>
                  <div class="top">
                    <div
                      class="top-df"
                      v-if="item.isDefault === 0"
                      @click="defaults(item.id)"
                    >
                      设为默认地址
                    </div>
                    <div
                      class="top-fda"
                      v-else
                      :class="item.isDefault === 1 ? 'active' : ''"
                    >
                      默认地址
                    </div>
                  </div>
                  <div class="bottom">
                    <p @click="edit(item)">修改</p>
                    <p @click="del(item.id)">删除</p>
                  </div>
                </div>
                <div class="items"></div>
              </div>
            </div>
            <!-- 地址表单 -->
            <div class="card-form" v-if="showAddressFrom">
              <el-form
                :model="ruleFormAddress"
                status-icon
                :rules="rulesAddress"
                ref="ruleForm3"
                label-width="100px"
                class="demo-ruleForm"
                size="mini"
                key="2"
              >
                <el-form-item label="收货人" prop="consignee">
                  <el-input v-model.trim="ruleFormAddress.consignee"></el-input>
                </el-form-item>
                <el-form-item label="所在地区" prop="address">
                  <el-cascader
                    size="large"
                    :options="options"
                    v-model="ruleFormAddress.address"
                    @change="handleChange"
                  >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="detailAddress">
                  <el-input
                    v-model.trim="ruleFormAddress.detailAddress"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                  <el-input v-model.number="ruleFormAddress.phone"></el-input>
                </el-form-item>
                <el-form-item label="固定电话">
                  <el-input v-model.number="ruleFormAddress.Gphone"></el-input>
                </el-form-item>
                <el-form-item label="邮政编码" prop="postCode">
                  <el-input
                    v-model.number="ruleFormAddress.postCode"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    v-if="addressId === ''"
                    @click="submitForm(addressId)"
                    >提交</el-button
                  >
                  <el-button
                    type="primary"
                    v-if="addressId"
                    @click="submitEditForm(addressId)"
                    >修改</el-button
                  >
                  <el-button @click="resetForm">重置</el-button>

                  <el-button @click="noEdit">返回</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
// import uuid from "uuid";
import api from "../../api/personal/personal";
import ce from "../../api/invoice/invoice";
export default {
  name: "personalInfo",
  data() {
    // 手机验证
    // var validateMobilePhone = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("手机号不可为空"));
    //   } else {
    //     if (value !== "") {
    //       var reg = /^1[3456789]\d{9}$/;
    //       if (!reg.test(value)) {
    //         callback(new Error("请输入有效的手机号码"));
    //       }
    //     }
    //     callback();
    //   }
    // };
    // 密码验证
    var validatePass = (rule, value, callback) => {
      const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
      if (!value) {
        return callback(new Error("请输入密码"));
      } else if (pwdReg.test(value)) {
        callback();
      } else {
        callback(new Error("请输入包含数字字母的不低于8位数的密码"));
      }
    };
    // 二次密码验证
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleFormPass.newPass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      addressCodeList: [],
      visible: false,
      showAddressFrom: true, // 地址表单显示
      addressList: [], // 地址列表
      options: regionData, //省市区三级联动数据
      // selectedOptions: [],
      // 资料表单内容
      ruleForm: {
        phone: "", // 手机号码
        tel: "", // 联系人
        email: "",
        qq: "",
        name: "",
        // selectedOptions: [],
        address: "", // 详细地址
      },
      rules: {
        phone: [{ required: true, message: "手机号不能为空", trigger: "blur" }],
        tel: [{ required: true, message: "联系人不能为空", trigger: "blur" }],
      },
      // 密码修改表单
      ruleFormPass: {
        phones: "",
        pass: "",
        newPass: "",
        newPass2: "",
      },
      // rules1: {
      //   tel: [
      //     {
      //       required: true,
      //       validator: validateMobilePhone,
      //       trigger: "blur",
      //     },
      //   ],
      //   passWord: [
      //     { required: true, validator: validatePass, trigger: "blur" },
      //   ],
      //   passWord1: [
      //     { required: true, validator: validatePass2, trigger: "blur" },
      //   ],
      //   verification: [
      //     { required: true, message: "验证码不能为空", trigger: "blur" },
      //   ],
      // },

      rulespass: {
        phones: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        pass: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur",
          },
        ],
        newPass: [
          {
            required: true,
            // message: "请输入新密码",
            validator: validatePass,
            trigger: "blur",
          },
        ],
        newPass2: [
          {
            required: true,
            // message: "请输入确认密码",
            validator: validatePass2,
            trigger: "blur",
          },
        ],
      },
      // 地址表单内容
      ruleFormAddress: {
        postCode: "", // 邮政编码
        Gphone: "", // 固定电话
        phone: "", // 联系人手机号
        consignee: "", // 收货人
        address: [], // 省市区
        detailAddress: "", // 详细地址
      },
      rulesAddress: {
        consignee: [
          {
            required: true,
            message: "请输入收货人",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入省市区",
            trigger: "blur",
          },
        ],
        detailAddress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        postCode: [
          {
            required: true,
            message: "请输入邮政编码",
            trigger: "blur",
          },
        ],
      },
      checked: 0, // 切换模块
      userInfo: [
        {
          id: 0,
          text: "我的资料",
        },
        {
          id: 1,
          text: "密码修改",
        },
        {
          id: 2,
          text: "地址管理",
        },
      ],
      // showAddress: "", // 地址code码反显为省市区字符串拼接
      addressCode: [], // 地址code码集合 修改时使用
      addressId: "", // 地址id
      addressInfo: {
        provinceZh: "",
        cityZh: "",
        areaZh: "",
      }, // 省市区中文
      isDefault: 0, // 是否为默认地址
      //指令加载方式
      fullscreenLoading: false,
      userId: "",
      userObj: {},
    };
  },
  async created() {
    // 类型切换
    // let type = localStorage.getItem("type");
    // if (type) {
    //   this.checked = Number(type);
    // } else {
    //   this.checked = 0;
    // }
    // getusername();
    let userObj = JSON.parse(window.sessionStorage.getItem("userInfo"));
    if (userObj) {
      this.userObj = userObj;
    } else {
      return;
    }
    this.getUserInfo();
    // this.fullscreenLoading = true;
    //查询用户信息
    let userInfo = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (userInfo !== "{}") {
      this.userId = userInfo.user_ID;
    }
    await this.init(); // 获取地址
    // this.addressUpde(); // 获取本地存储地址
    // console.log(this.addressList);
    if (this.addressList.length === 0) {
      // console.log(this.addressList.length);
      this.showAddressFrom = true;
    } else {
      // console.log("列表有长度", this.addressList.length);
      this.showAddressFrom = false;
    }
  },
  methods: {
    getUserInfo() {
      this.$http.get("/user/getInfoByMobile").then((res) => {
        console.log(res);
        // this.fullscreenLoading = false;
        if (res.data.code == 200) {
          let datas = this.ruleForm;
          let data = res.data.data;
          (datas.tel = data.contact == null ? "" : data.contact), //联系人
            (datas.email = data.email == null ? "" : data.email), //email
            (datas.qq = data.telephone1 == null ? "" : data.telephone1), //QQ
            (datas.name = data.custname == null ? "" : data.custname), //企业名称
            (datas.address = data.comaddress == null ? "" : data.comaddress); //公司地址
          let addressStr =
            data.provinceCode + "," + data.cityCode + "," + data.areaCode;
          this.addressCodeList = addressStr.split(",");
          let data1 = this.addressCodeList;
          this.addressInfo = {
            provinceZh: CodeToText[data1[0]], // 江苏省
            cityZh: CodeToText[data1[1]], // 南京市
            areaZh: CodeToText[data1[2]], // 江宁区
          };
          // this.$message({
          //   message: "成功查询用户信息",
          //   type: "success",
          // });
        } else {
          this.$message.error("查询用户信息失败");
        }
      });
    },
    // 测试
    cheshi() {
      ce.companyinformation().then((res) => {
        console.log(res);
      });
    },
    handleChange1(value) {
      console.log(value);
      // this.addressCodeList = [...value];
      this.addressInfo = {
        provinceZh: CodeToText[value[0]], // 江苏省
        cityZh: CodeToText[value[1]], // 南京市
        areaZh: CodeToText[value[2]], // 江宁区
      };
    },
    // 初始化获取地址列表
    async init() {
      await api
        .getAddressList()
        .then((res) => {
          console.log("获取地址列表", res);
          if (res.success) {
            res.data.forEach((el) => {
              el.addressInfos =
                el.provinceZh + el.cityZh + el.areaZh + el.detailAddress;
            });
            this.addressList = [...res.data].reverse();
            // console.log("处理过后的地址数据", this.addressList);
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
    // 不做修改（返回按钮）
    noEdit() {
      this.showAddressFrom = false;
      this.addressId = "";
      this.clearAddressFrom();
    },
    // 修改地址
    submitEditForm(id) {
      console.log(id);
      // console.log(this.ruleFormAddress);
      // console.log(this.addressCode);
      // console.log(this.addressInfo);
      this.$refs.ruleForm3.validate((valid) => {
        if (valid) {
          let data = this.ruleFormAddress; // 表单内容
          let data1 = this.addressCode; // 城市code码
          let data2 = this.addressInfo; // 省市区中文
          let obj = {
            provinceCode: data1[0], // 省code码
            provinceZh: data2.provinceZh, // 省中文
            cityCode: data1[1], // 市code码
            cityZh: data2.cityZh, // 市中文
            areaCode: data1[2], // 区code码
            areaZh: data2.areaZh, // 区中文
            detailAddress: data.detailAddress, // 详细地址
            postCode: data.postCode, // 邮政编码
            receiveName: data.consignee, // 收货人
            receivePhone: data.phone, // 手机号
            fixedNumber: data.Gphone, // 固定电话
            isDefault: this.isDefault,
          };
          // console.log(obj);
          api
            .editAddress(id, { ...obj })
            .then((res) => {
              if (res.success) {
                this.$message.success("修改成功");
                this.init();
                this.showAddressFrom = false; // 关闭form表单
              }
            })
            .catch((error) => {
              console.error("Error", error);
            });
        } else {
          this.$message.error("提交失败，请认真填写地址");
          return false;
        }
      });
    },
    // 编辑地址
    edit(item) {
      // console.log(item);
      this.addressId = "";
      this.addressId = item.id;
      this.isDefault = item.isDefault;
      api
        .queryAddress(item.id)
        .then((res) => {
          // console.log("查询地址详情", res);
          if (res.success) {
            this.showAddressFrom = true;
            this.submitForm(res.data);
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
    // 删除地址
    del(id) {
      console.log(id);
      api
        .delAddress(id)
        .then((res) => {
          // console.log("删除的接口", res);
          if (res.success) {
            this.$message.success("删除成功");
            this.init();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
    // 设置默认地址
    defaults(id) {
      console.log(id);
      api
        .editAddress(id, { isDefault: 1 })
        .then((res) => {
          // console.log("修改默认地址", res);
          if (res.success) {
            this.init();
          }
        })
        .catch((error) => {
          console.error("Error", error);
        });
    },
    clearAddressFrom() {
      this.ruleFormAddress = {
        postCode: "", // 邮政编码
        Gphone: "", // 固定电话
        phone: "", // 联系人手机号
        consignee: "", // 收货人
        address: [], // 省市区
        detailAddress: "", // 详细地址
      };
    },
    // 新增收货地址
    addAddress() {
      // console.log("点击了新增收货地址");
      this.showAddressFrom = true;
      this.addressId = "";
      this.clearAddressFrom(); // 没次新增先重置表单，防止修改遗留
      // this.$refs.ruleForm3.resetFields();
    },
    // 地址级联选择器
    handleChange(value) {
      // console.log(value);
      this.addressCode = [...value];
      // 地址反显
      this.showAddress = `${CodeToText[value[0]]}${CodeToText[value[1]]}${
        CodeToText[value[2]]
      }`;
      // 新增地址使用
      this.addressInfo = {
        provinceZh: CodeToText[value[0]], // 江苏省
        cityZh: CodeToText[value[1]], // 南京市
        areaZh: CodeToText[value[2]], // 江宁区
      };
      // console.log(this.showAddress);
      // console.log(this.addressCode);
      // console.log(this.addressInfo);
      // let id = uuid.v4();
      // console.log(id);
    },
    // 切换列表
    replace(item) {
      // localStorage.setItem("type", item.id); //存入本地，防止刷新页面丢失
      this.checked = item.id;
      // console.log(item);
      if (item.id === 0) {
        this.getUserInfo();
      }
      if (item.id === 2) {
        this.init();
      }
    },
    // 表单提交
    submitForm(item) {
      // console.log("修改和新增传参", item);
      // 表单验证(0:我的资料，1：修改密码，2：地址管理)
      if (this.checked === 0) {
        this.$refs.ruleForm1.validate((valid) => {
          if (valid) {
            let datas = this.ruleForm;
            let data1 = this.addressCodeList; // 城市code码
            let data2 = this.addressInfo; // 省市区中文
            let data = {
              custname: datas.name, //企业名称
              contact: datas.tel, //联系人
              telephone1: datas.qq, //QQ
              email: datas.email, //email
              comaddress: datas.address, //公司地址
              mobile: this.userObj.phone, //手机号码当前客户
              provinceCode: data1[0], // 省code码
              provinceZh: data2.provinceZh, // 省中文
              cityCode: data1[1], // 市code码
              cityZh: data2.cityZh, // 市中文
              areaCode: data1[2], // 区code码
              areaZh: data2.areaZh, // 区中文
            };
            console.log(data);

            this.fullscreenLoading = true;
            setTimeout(() => {
              this.$http.put("/user/updateCustData", data).then((res) => {
                // console.log(res);
                // console.log(data);
                this.fullscreenLoading = false;
                if (res.data.code == 200) {
                  // console.log(data);
                  this.$message({
                    message: "提交资料成功",
                    type: "success",
                  });
                  // this.$router.push({ path: "login" });
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "warning",
                  });
                }
              });
            }, 2000);
            // alert("提交资料成功");
          } else {
            // console.log("登录失败");
            // alert("提交资料失败");
            this.$message.error("提交资料失败");
            return false;
          }
        });
      } else if (this.checked === 1) {
        this.$refs.ruleForm2.validate((valid) => {
          if (valid) {
            let datas = this.ruleFormPass;
            let data = {
              phone: datas.phones,
              oldPwd: datas.pass,
              newPwd: datas.newPass,
            };
            // console.log(data);

            this.fullscreenLoading = true;
            setTimeout(() => {
              this.$http
                .put(
                  "/user/updatePwd?phone=" +
                    data.phone +
                    "&oldPwd=" +
                    data.oldPwd +
                    "&newPwd=" +
                    data.newPwd
                )
                .then((res) => {
                  // console.log(res);
                  // console.log(data);
                  this.fullscreenLoading = false;
                  if (res.data.code == 200) {
                    // console.log(data);
                    this.$message({
                      message: "提交密码成功",
                      type: "success",
                    });
                    // this.$router.push({ path: "index" ,query:{ID:0}});
                    // this.$router.push({name: "index",params:{ID:0}});
                    this.$refs.ruleForm2.resetFields();
                  } else {
                    this.$message({
                      message: res.data.message,
                      type: "warning",
                    });
                  }
                })
                .catch((error) => {
                  console.error("Error", error);
                });
            }, 2000);
            // alert("提交密码成功");
          } else {
            // alert("提交密码失败");
            this.$message.error("提交密码失败");
            return false;
          }
        });
      } else {
        // 地址管理
        if (item) {
          console.log("进入修改");
          let str =
            item.provinceCode + "," + item.cityCode + "," + item.areaCode;
          let arr = str.split(",");
          this.addressCode = arr;
          this.addressInfo = {
            provinceZh: CodeToText[arr[0]], // 江苏省
            cityZh: CodeToText[arr[1]], // 南京市
            areaZh: CodeToText[arr[2]], // 江宁区
          };
          // 表单反显
          this.ruleFormAddress = {
            postCode: item.postCode, // 邮政编码
            Gphone: item.fixedNumber ? item.fixedNumber : "", // 固定电话
            phone: item.receivePhone, // 联系人手机号
            consignee: item.receiveName, // 收货人
            address: arr, // 省市区
            detailAddress: item.detailAddress, // 详细地址
          };
          // console.log(this.ruleFormAddress);
          // console.log(this.addressCode);
        } else {
          // 新增
          console.log("进入新增");
          this.$refs.ruleForm3.validate((valid) => {
            if (valid) {
              // alert("提交地址成功");
              // console.log(this.ruleFormAddress);
              // console.log(this.addressCode);
              // console.log(this.addressInfo);
              let data = this.ruleFormAddress; // 表单内容
              let data1 = this.addressCode; // 城市code码
              let data2 = this.addressInfo; // 省市区中文
              let obj = {
                provinceCode: data1[0], // 省code码
                provinceZh: data2.provinceZh, // 省中文
                cityCode: data1[1], // 市code码
                cityZh: data2.cityZh, // 市中文
                areaCode: data1[2], // 区code码
                areaZh: data2.areaZh, // 区中文
                detailAddress: data.detailAddress, // 详细地址
                postCode: data.postCode, // 邮政编码
                receiveName: data.consignee, // 收货人
                receivePhone: data.phone, // 手机号
                fixedNumber: data.Gphone, // 固定电话
                isDefault: 0,
                operatorId: this.userId,
              };
              // console.log(obj);
              api
                .addAddress({ ...obj })
                .then((res) => {
                  // console.log(res);
                  if (res.success) {
                    this.$message.success("新增地址成功");
                    this.init();
                    this.clearAddressFrom();
                    this.showAddressFrom = !this.showAddressFrom;
                  }
                })
                .catch((error) => {
                  console.error("Error", error);
                });
            } else {
              this.$message.error("提交失败，请认真填写地址");
              return false;
            }
          });
        }
      }
    },
    // 更新本地存储地址
    // updateAddress(info) {
    //   localStorage.setItem("addressList", JSON.stringify(info));
    // },
    // 获取最新地址数据
    // addressUpde() {
    //   let address = JSON.parse(localStorage.getItem("addressList"));
    //   // console.log(address);
    //   if (address) {
    //     this.addressList = [...address];
    //   }
    // },
    // 重置表单
    resetForm() {
      if (this.checked === 0) {
        this.ruleForm.selectedOptions = [];
        this.$refs.ruleForm1.resetFields();
      } else if (this.checked === 1) {
        this.$refs.ruleForm2.resetFields();
      } else {
        this.clearAddressFrom();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.personalInfo {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .card-left {
    width: 200px;
    height: 320px;
    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;
      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }
    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
      }
      li:last-child {
        border-bottom: none;
      }
      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }
  }
  .card-right {
    width: 980px;
    background-color: #fff;
  }
  .info {
    width: 100%;
    box-sizing: border-box;
    // padding: 15px;
  }
  .card {
    width: 100%;
    .card-top {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #e6e6e6;
      padding: 0 30px;
      line-height: 50px;
      span {
        display: inline-block;
        width: 5px;
        height: 17px;
        background: #058af2;
        margin-right: 20px;
      }
      h3 {
        color: #058af2;
      }
    }
    .list {
      width: 100%;
      box-sizing: border-box;
      padding: 5px 30px;
      .list-top {
        display: flex;
        justify-content: space-between;
        span {
          color: #058af2;
        }
        span:last-child:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .list-item {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .items {
          margin-top: 10px;
          width: 255px;
          height: 155px;
          background: transparent url("../../static/imgs/beijing1.png")
            no-repeat scroll 0% 0%;
          position: relative;
          .border {
            box-sizing: border-box;
            padding: 15px;
            font-size: 14px;
            font-weight: 400;
            p {
              padding: 3px 0 5px 0;

              border-bottom: 1px solid #e6e6e6;
            }
            i {
              display: inline-block;
              margin-right: 10px;
            }
            p:nth-child(2) {
              height: 65px;
              display: flex;
              align-items: center;
              span {
                display: block;
                overflow: hidden;
                white-space: wrap;
                text-overflow: ellipsis;
              }
            }
            p:last-child {
              border-bottom: none;
            }
          }
          .top {
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            color: #fff;
            .top-df {
              display: none;
              background: #fbb244 none repeat scroll 0% 0%;
              padding: 0px 10px;
              border-radius: 0px 0px 5px 5px;
            }
          }
          .active {
            display: block;
            padding: 0 10px;
            background: #058af2 none repeat scroll 0% 0%;
          }
          .top:hover {
            cursor: pointer;
            text-decoration: underline;
          }
          .bottom {
            display: none;
            position: absolute;
            right: 20px;
            bottom: 8px;
            cursor: pointer;
            font-size: 14px;
            p {
              float: left;
              border: 1px solid #d4d4d4;
              margin-left: 5px;
              height: 25px;
              line-height: 25px;
              width: 40px;
              text-align: center;
              border-radius: 3px;
              background-color: #fff;
            }
            p:hover {
              color: #058af2;
            }
          }
        }
        .items-bgd {
          background: transparent url("../../static/imgs/beijing2.png")
            no-repeat scroll 0% 0%;
        }
        .items:hover .top-df {
          display: block;
        }
        .items:hover .bottom {
          display: block;
        }
        .items:nth-last-child(-n + 1) {
          height: 0;
          background: none;
        }
      }
    }
    .card-form {
      width: 50%;
      margin: 30px auto;
    }
  }
}
</style>
