<template>
  <div>
    <div class="card">
      <div class="card-top">
        <span></span>
        <h3>发票申请历史</h3>
      </div>
      <div class="query">
        <el-row :gutter="10">
          <el-col :span="10" class="colls">
            <el-date-picker
              v-model="startTime"
              type="date"
              placeholder="选择开始日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <div class="kuoz">-</div>
            <el-date-picker
              v-model="endTime"
              type="date"
              placeholder="选择结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>

          <el-col :span="4" class="coll">
            <span class="span">状态:</span>
              <el-select
                   v-model="invoicingStuts"
                   filterable
                    clearable
                   placeholder="请选择发票状态"
           >
                            <el-option
                              label="待审核"
                              value="0"
                            />
                            <el-option
                                label="已开票"
                                value="1"
                            />
                        <el-option
                            label="驳回"
                            value="2"
                        />
           </el-select>
          </el-col>
          <div class="box">
            <el-col :span="2">
              <el-button type="primary" @click="queryList">查询</el-button>
            </el-col>

<!--            <el-col :span="2" v-if="userType === 2">-->
<!--              <el-button type="primary" @click="addInvoice">新增</el-button>-->
<!--            </el-col>-->
          </div>
        </el-row>
        <!-- <el-row :gutter="10" style="margin-left: 15px"> -->

        <!-- </el-row> -->
      </div>
      <div class="tabList">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            prop="invoicingApplicantName"
            label="申请人"
            align="center"
            header-align="center"
          ></el-table-column>
          <el-table-column
              prop="createTime"
              label="申请时间"
              align="center"
              header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="unitName"
            label="开票资料"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
               <el-link
                  @click.native="IncreaseInfo(JSON.parse(scope.row.extendColumn2).id)"
                  type="primary"
              >{{ JSON.parse(scope.row.extendColumn2).increaseTicketType }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="invoicingMoney"
            label="开票金额"
            align="center"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="状态"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.invoicingStuts === 0 ? "待开票" : scope.row.invoicingStuts === 1 ? "已开票": scope.row.invoicingStuts === 2 ? "驳回": "" }}
            </template>
          </el-table-column>


            <el-table-column
                label="发票号码"
                align="center"
                header-align="center"
            >
              <template slot-scope="scope">

                <span  >{{ scope.row.invoicingNumber }}</span>
              </template>
            </el-table-column>

          <el-table-column
            prop="updateTime"
            label="包含订单"
            align="center"
            width="120"
            header-align="center"
          >
            <template slot-scope="scope">
              <span v-for="item  in scope.row.extendColumn3.split(',')">
              <el-link
                  @click.native="details(item)"
                  type="primary"
               >{{item}}
              </el-link>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="pageNumber1"
          :page-sizes="[5, 10, 20, 30]"
          :page-size="pageSize1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total1"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 内层客户选择弹框 -->
    <div class="dialogOther">
      <el-dialog
        title="选择客户"
        :visible.sync="dialogTableVisible"
        :close-on-click-modal="false"
      >
        <div class="queryMember">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-input
                v-model.trim="custName"
                placeholder="请输入用户名称"
              ></el-input
            ></el-col>
            <el-col :span="6">
              <el-button type="primary" @click="queryMemberDig(1)"
                >查询</el-button
              >
              <el-button @click="refMember(2)">刷新</el-button>
            </el-col>
          </el-row>
        </div>
        <el-table
          :data="allList"
          border
          style="width: 100%"
          class="memberTable"
        >
          <el-table-column header-align="center" align="center" width="100">
            <template scope="scope">
              <el-radio
                :label="scope.row.user_ID"
                v-model="radio"
                @change.native="getCurrentRow(scope.row, 1)"
              ></el-radio>
            </template>
          </el-table-column>

          <el-table-column
            property="name"
            label="用户名称"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="联系方式"
            width="180"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.phone ? scope.row.phone : scope.row.username
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            property="createTime"
            label="注册时间"
            width="200"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogTableVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <!-- 筛选条件客户弹框 -->
    <div class="dialogOther">
      <el-dialog
        title="选择客户"
        :visible.sync="showDialog"
        :close-on-click-modal="false"
      >
        <div class="queryMember">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-input
                v-model.trim="custName1"
                placeholder="请输入用户名称"
              ></el-input
            ></el-col>
            <el-col :span="6">
              <el-button type="primary" @click="queryMemberDig(2)"
                >查询</el-button
              >
              <el-button @click="refMember(2)">刷新</el-button>
            </el-col>
          </el-row>
        </div>
        <el-table
          :data="allList"
          border
          style="width: 100%"
          class="memberTable"
        >
          <el-table-column header-align="center" align="center" width="100">
            <template scope="scope">
              <el-radio
                :label="scope.row.user_ID"
                v-model="radio1"
                @change.native="getCurrentRow(scope.row, 2)"
              ></el-radio>
            </template>
          </el-table-column>

          <el-table-column
            property="name"
            label="用户名称"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="联系方式"
            width="180"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.phone ? scope.row.phone : scope.row.username
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            property="createTime"
            label="注册时间"
            width="200"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="pageNumber2"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize2"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showDialog = false">取 消</el-button>
          <el-button type="primary" @click="showDialog = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>


    <el-dialog
        title="发票详情"
        :visible.sync="dialogVisibleInrecerse"
        width="30%"
    >
      <increase-info
          :increaseId="increaseInfoId"
          :key="infoTime"
      ></increase-info>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleInrecerse = false">关闭</el-button>
      </span>
    </el-dialog>


    <el-dialog
        :visible.sync="detail"
        class="detail"
        :title="title"
        width="55%"
    >
      <order-details
          ref="orderDetails"
          v-bind:orderId="orderInfo.id"
          :key="infoTime"
      ></order-details>
    </el-dialog>

  </div>
</template>

<script>
import api from "../../api/webAdmin/webAdmin";
import increaseInfo from "@/components/personal/increaseInfo";
import OrderDetails from "@/components/OrderDetails/OrderDetails";
export default {
  name: "invoiceHis",
  components: {
    OrderDetails,
    increaseInfo,
  },
  data() {
    return {
      approvalFrom: {
        invoicingStuts: "", //开票状态  0未开票 1：已开票. 2：驳回
        invoicingNumber: "" //发票号
      },
      detail: false,
      incoMoney: 0, // 可开金额
      useMoney: 0, //已开金额
      notOpenMoney: 0, //未开金额
      dialogVisible: false,
      pageNumber2: 1,
      pageSize2: 5,
      radio1: "",
      custName1: "",
      showDialog: false, //筛选客户弹框
      total1: 0, // 发票申请列表
      pageNumber1: 1,
      pageSize1: 10,
      totalMoney: 0, //总开票金额
      oneMoney: 0, //已开票金额
      twoMoney: 0, //可开票金额
      form: {
        name: "",
        money: "",
      },
      rules: {
        name: [{ required: true, message: "请选择客户名称", trigger: "blur" }],
        money: [{ required: true, message: "请输入开票金额", trigger: "blur" }],
      },
      radio: "",
      total: 0, // 新增开票 客户弹窗数据
      pageNumber: 1,
      pageSize: 5,
      custName: "", // 弹框搜索用户名称
      allList: [], // 客户列表
      dialogTableVisible: false, // 选择客户弹框
      dialogFormVisible: false, // 新增发票弹框
      formLabelWidth: "80px",
      oneMember: "", //客户名称
      startTime: "", //  开始时间
      endTime: "", // 结束时间
      userType: "",
      customer: "", // 选择客服
      customerList: [], // 客服下拉列表
      tableData: [], // 发票表格数据
      recordId: "", // 发票申请ID
      invoicingStuts: "0",
      increaseInfoId: "",
      dialogVisibleInrecerse: false,
      infoTime: "",
      orderInfo: {},
      title: ""
    };
  },
  created() {
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (obj) {
      this.userType = obj.userType;
      if (obj.userType === 1) {
        this.customer = "";
      } else {
        this.customer = obj.user_ID;
      }
    } else {
      return;
    }
    // this.getAll();
  },
  methods: {
    details(item) {
      this.infoTime = new Date().getTime();
      this.orderInfo = {id: item};
      this.title = `订单【${this.orderInfo.id}】详情`;
      this.detail = true;
    },
    IncreaseInfo(id) {

      this.infoTime = new Date().getTime();
      this.increaseInfoId = id;
      this.dialogVisibleInrecerse = true;
    },

    // 已开票列表高亮
    tableRowClassName(row) {
      console.log("获取当前开票行数据", row);
      if (row.row.invoicingStuts === 1) {
        return "success-row";
      } else {
        return "";
      }
    },
    getAll() {

      this.init(); // 获取列表
    },
    // 开票申请列表查询
    queryList() {
      this.init();
    },
    // 开票申请列表刷新
    refList() {
      this.startTime = this.endTime = this.radio1 = this.oneMember = "";
      if (this.userType === 1) {
        // 管理员
        this.customer = "";
      }
      this.init();
    },
    // 确定开票
    determines() {
      if(!this.approvalFrom.invoicingStuts){
        this.$message.warning("请选中审批类型!");
        return;
      }
      if (this.approvalFrom.invoicingStuts  == 1 && !this.approvalFrom.invoicingNumber) {
        this.$message.warning("请填写发票号码!");
        return;
      }
      let data = {
        invoicingNumber: this.approvalFrom.invoicingNumber,
        invoicingStuts: this.approvalFrom.invoicingStuts,
      };
      api.requestaninvoicePut(this.recordId, data).then((res) => {
        console.log("确认开票", res);
        if (res.success) {
          this.$message.success("申请通过");
          this.dialogVisible = false;
          this.init();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //编辑发票号码（管理员）
    editInvoiceNumber(item) {
      console.log(item);
      this.recordId = item.id;
      this.approvalFrom.invoicingNumber = "";
      this.approvalFrom.invoicingStuts = ""
      this.dialogVisible = true;
    },
    // 确定新增
    determineAdd() {
      this.$refs.rulesFrom.validate((valid) => {
        if (valid) {
          if (Number(this.form.money) > this.twoMoney) {
            this.$message.warning("当前开票金额不可超过可开票金额！");
            return;
          }
          let data = {
            invoicingUnit: this.radio,
            invoicingMoney: this.form.money,
          };
          api.requestaninvoice(data).then((res) => {
            console.log("申请开票", res);
            if (res.success) {
              this.dialogFormVisible = false;
              this.$message.success("申请开票成功");
              this.init();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消新增
    cancel() {
      this.clearData();
      this.dialogFormVisible = false;
    },
    // 弹框清空参数
    clearData() {
      this.form = {
        name: "",
        money: "",
      };
      this.custName = this.radio = "";
    },
    // 选择客户（单选）
    getCurrentRow(item, type) {
      if (type === 1) {
        // this.recordId = item.customerId;
        this.form.name = item.name;
      } else {
        this.oneMember = item.name;
      }
    },
    // 新增客户弹框查询
    queryMemberDig(type) {
      if (type === 1) {
        this.radio = "";
      } else {
        this.radio1 = "";
      }
      this.getCustomer();
    },
    // 新增客户弹框刷新
    refMember(type) {
      // 新增客户查询
      if (type === 1) {
        this.pageNumber = 1;
        this.pageSize = 5;
        this.custName = this.radio = this.form.name = "";
      } else {
        // 列表客户查询
        this.pageNumber2 = 1;
        this.pageSize2 = 5;
        this.custName1 = this.radio1 = "";
      }
      this.getCustomer();
    },
    // 新增选择客户（获取焦点事件）
    changeMemberDialog() {
      this.getUser(1);
      this.dialogTableVisible = true;
    },
    // 新增发票申请
    addInvoice() {
      this.clearData();
      this.dialogFormVisible = true;
      this.getUser(1);
    },
    // 选择客户(筛选条件)
    changeMember() {
      this.showDialog = true;
      this.getCustomer();
    },
    // 获取客服
    getService() {
      api.getAllUserDontExistCustom().then((res) => {
        console.log("获取客服", res);
        if (res.success) {
          res.data.userPageInfo.forEach((el) => {
            el.label = el.name;
            el.value = el.user_ID;
          });
          this.customerList = res.data.userPageInfo;
        }
      });
    },



    getCustomer() {
      this.showDialog = true;
      api
          .queryUser(
              this.pageNumber2,
              this.pageSize2,
              "",
              "",
              this.custName1,
              "0"
          )
          .then((res) => {
            console.log("获取客户", res);
            if (res.success) {
              // this.allList = res.data.userPageInfo.list;
              this.allList = res.data.list;
              this.total = res.data.total;

            } else {
              this.$message.error(res.message);
            }
          });
    },



    // 获取发票申请列表
    init() {
      // customer： 申请人id（客服），oneMember：客户id（userId）
      api
        .requestaninvoiceMyList(
          this.pageSize1,
          this.pageNumber1,
          this.startTime,
          this.endTime,
           this.invoicingStuts
        )
        .then((res) => {
          console.log("获取发票申请列表", res);
          if (res.success) {
            this.tableData = res.data.rows;
            this.total1 = res.data.total;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 客户分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.getCustomer();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getCustomer();
    },
    //筛选客户条件分页
    handleSizeChange2(val) {
      this.pageSize2 = val;
      this.getCustomer();
    },
    handleCurrentChange2(val) {
      this.pageNumber2 = val;
      this.getCustomer();
    },
    // 发票列表分页
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.init();
    },
    handleCurrentChange1(val) {
      this.pageNumber1 = val;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  .labels {
    font-weight: 700;
    margin-right: 10px;
  }
  .moneys {
    font-weight: 700;
    margin-right: 10px;
    color: #ff0000;
  }
}
.card {
  width: 100%;
  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;
    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }
    h3 {
      color: #058af2;
    }
  }
  .query {
    margin-top: 10px;
    .colls {
      display: flex;
      line-height: 40px;
      margin: 15px 0 15px 20px;
      span {
        margin-right: 5px;
      }
      .kuoz {
        margin: 0 10px;
      }
    }
    .coll {
      display: flex;
      margin-top: 15px;
      .span {
        width: 95px;
        line-height: 40px;
      }
    }
    .rows {
      margin-left: 15px;
    }
    .box {
      margin-top: 15px;
    }
  }
  .tabList {
    margin: 10px 0;
    ::v-deep .el-table .success-row {
      background: #f0f9eb;
    }
  }
}
.froms {
  width: 75%;
  margin: auto;
}
.dialogOther {
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
  }
  .queryMember {
    margin: 10px 0;
  }
  ::v-deep .el-radio__label {
    display: none;
  }
}
.centers {
  display: flex;
  justify-content: center;
  p {
    margin-left: 10px;
  }
}
.font {
  color: #ff0000;
  font-weight: 600;
}
.invoiceNumber {
  display: flex;
  align-items: center;
  ::v-deep .el-input {
    width: 70%;
  }
}
</style>
