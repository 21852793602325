<template>
  <div>
    <div class="personalInfo">

      <div class="card-right">
        <div class="info bill"  >
          <div class="card">

            <!--筛选条件-->
            <div class="screen">
              <el-row :gutter="10">
                <el-col :span="12" style="display: inline-flex">
                  <el-input clearable v-model="orderId"  placeholder="请输入订单编号" ></el-input>
                  <el-button @click="query" class="btn" type="primary"
                      >查询
                      </el-button>
                    <el-button @click="sureBill" class="btn">开票申请</el-button>

                  <el-button @click="sureBillHis" type="text"  >开票历史记录</el-button>

                </el-col>
              </el-row>

            </div>
            <!-- 表格 -->
            <div class="tables" style="margin-top: 10px;">
              <el-table
                  :cell-style="tableRowStyle"
                  :data="tableData"
                  border
                  ref="tables"
                  style="width: 100%"
                  @select="moreSelect"
                  @selection-change="changeSelect"
              >
                <el-table-column
                    type="selection"
                    width="55"
                    align="center"
                    header-align="center"
                    :selectable="selectCheck"
                >
                </el-table-column>
                <el-table-column
                    header-align="center"
                    label="订单详情"
                    width="500"
                >
                  <template slot-scope="scope">
                    <div class="top">
                      下单时间：<span>{{ scope.row.ordertime }}</span>
                      订单编号：<span>{{ scope.row.id }}</span>
                    </div>
                    <div class="box">
                      <div class="img">
                        <img :src="picUrls + scope.row.productimage" alt="" />
                      </div>
                      <div class="info">
                        <p class="p1">产品名称：{{ scope.row.ordername }}</p>
                        <p class="p1">客户名称：{{ scope.row.customername }}</p>
                        产品类型: <span> {{
                          scope.row.productid == 1038
                              ? "综合"
                              : scope.row.productid == 1036
                                  ? "挂历"
                                  : scope.row.productid == 1035
                                      ? "台历"
                                      : scope.row.productid == 1034
                                          ? "精装书"
                                          : scope.row.productid == 1032
                                              ? "封套"
                                              : scope.row.productid == 1028
                                                  ? "单页/海报"
                                                  : scope.row.productid == 1027
                                                      ? "教辅"
                                                      : scope.row.productid == 1024
                                                          ? "瓦楞彩箱"
                                                          : scope.row.productid == 1023
                                                              ? "单层卡纸彩盒"
                                                              : scope.row.productid == 1020
                                                                  ? "画册/样本"
                                                                  : scope.row.productid == 1018
                                                                      ? "装帧加工"
                                                                      : scope.row.productid == 1016
                                                                          ? "印刷加工"
                                                                          : scope.row.productid == 1015
                                                                              ? "手提袋"
                                                                              : scope.row.productid == 2028
                                                                                  ? "单页"
                                                                                  : scope.row.productid == 2037
                                                                                      ? "不干胶"
                                                                                      : scope.row.productid == 2020
                                                                                          ? "普通画册"
                                                                                          : scope.row.productid == 2034
                                                                                              ? "精装画册"
                                                                                              : scope.row.productid == 2026
                                                                                                  ? "挂历"
                                                                                                  : scope.row.productid == 2015
                                                                                                      ? "手提袋"
                                                                                                      : scope.row.productid == 2039
                                                                                                          ? "吊旗"
                                                                                                          : scope.row.productid == 2035
                                                                                                              ? "台历"
                                                                                                              : scope.row.productid == 2032
                                                                                                                  ? "封套"
                                                                                                                  : scope.row.productid == 2040
                                                                                                                      ? "写真"
                                                                                                                      : scope.row.productid == 2041
                                                                                                                          ? "X型展架"
                                                                                                                          : scope.row.productid == 2042
                                                                                                                              ? "易拉宝"
                                                                                                                              : scope.row.productid == 2043
                                                                                                                                  ? "名片"
                                                                                                                                  : ""
                        }}</span>
                        <p class="p3">
                          印刷数量：<span>{{
                            scope.row.quantitydemanded
                          }}</span>
                        </p>
                      </div>
                      <div class="rights">
                        <p class="p3">
                          订单金额：<span>{{ scope.row.discountfee }}</span>
                        </p>
                        <p class="p3">
                          发票类型：<span>{{scope.row.invoiceName}}</span>
                        </p>

                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    header-align="center"
                    label="订单状态"
                    width="300"
                >
                  <template slot-scope="scope">
                    <div class="top">
                      生产状态：<span>{{ typeFunc(scope.row.status) }}</span
                    >支付状态：<span>{{
                        scope.row.payStruts === 3 ? "待支付" : "已支付"
                      }}</span>
                    </div>

                    <div class="box boxInfo">

                    </div>

                  </template>
                </el-table-column>
                <el-table-column header-align="center" label="交易状态">
                  <template slot-scope="scope">

                    <div class="box types">
                      <el-link
                          @click="details(scope.row)"
                          :underline="false"
                          type="primary"
                      >订单详情
                      </el-link>

                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>


      <el-dialog title="选择开票资料" :visible.sync="increasingShow">
              <div class="dialogs">
                <p>
              <span class="label">开票总金额:</span
              ><span class="money">{{ discountfeeTotal }}</span>
                    </p>
              </div>
              <el-table
                  class="dialogsTable"
                  :data="increaseList"
                  border
                  style="width: 100%"
              >
                <el-table-column text-align="center" align="center">
                  <template scope="scope">
                    <el-radio
                        :label="scope.row.id"
                        v-model="extendColumn1"
                        @change.native="getCurrentRow(scope.row)"
                    ></el-radio>
                  </template>
                </el-table-column>
                <el-table-column label="发票类型" text-align="center" align="center">
                  <template slot-scope="scope">
            <span>
              {{
                scope.row.increaseTicketType
              }}
            </span>
              </template>
                </el-table-column>
                <el-table-column label="开票类型" text-align="center" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.extendColumn1 === "1" ? "个人" : "单位" }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="个人抬头" text-align="center" align="center">
                  <template slot-scope="scope">
            <span>{{
                scope.row.increaseTicketTitle
                    ? scope.row.increaseTicketTitle
                    : "----"
              }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="单位名称" text-align="center" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.unitName ? scope.row.unitName : "----" }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="收票人" text-align="center" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.recipientSName }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="收票地址" text-align="center" align="center">
                  <template slot-scope="scope">
            <span>{{
                scope.row.recipientSProvinceZh +
                scope.row.recipientSCityZh +
                scope.row.recipientSAreaZh +
                scope.row.recipientSAdress
              }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" text-align="center" align="center">
                  <template slot-scope="scope">
                    <!-- <el-button @click="Invoicing(scope.row)" size="mini" type="primary"
                      >开票</el-button
                    > -->
                    <el-button
                        @click="IncreaseInfo(scope.row)"
                        size="mini"
                        type="primary"
                    >详情</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>

              <span slot="footer" class="dialog-footer">
        <el-button type="primary"   @click="submitBill"
        >提交申请</el-button
        >
      </span>
            </el-dialog>

            <el-dialog
                :visible.sync="invoiceHisDialogVisible"
                class="detail"
                title="开票历史"
                width="400"
            >
              <invoiceHis ></invoiceHis>
            </el-dialog>

            <el-dialog
                title="发票详情"
                :visible.sync="dialogVisibleInrecerse"
                width="30%"
            >
              <increase-info
                  :increaseId="increaseInfoId"
                  :key="infoTime"
              ></increase-info>
              <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleInrecerse = false">关闭</el-button>
      </span>
            </el-dialog>
            <!-- 订单详情 -->
            <el-dialog
                :visible.sync="detail"
                class="detail"
                :title="title"
                width="55%"
            >
              <order-details
                  ref="orderDetails"
                  v-bind:orderId="orderInfo.id"
                  :key="timer"
              ></order-details>
            </el-dialog>


            <!--分页-->
            <div class="pages">
              <el-pagination
                  :current-page="currentPage"
                  :page-size="pageSize"
                  :page-sizes="[5, 10, 20, 30]"
                  :total="total"
                  @current-change="handleCurrentChange"
                  @size-change="handleSizeChange"
                  layout="total, sizes, prev, pager, next, jumper"
              >
              </el-pagination>
              <!-- <el-button
                @click="applyInvoice"
                class="btn"
                size="mini"
                type="primary"
                >发票申请
              </el-button> -->
            </div>
          </div>
        </div>


      </div>
    </div>


  </div>
</template>

<script>

import api from "../../api/invoice/invoice";
import apiWab from "../../api/webAdmin/webAdmin";
import OrderDetails from "../OrderDetails/OrderDetails";
import picUrl from "../../api/picture";
import increaseInfo from "@/components/personal/increaseInfo";
import invoiceHis from "@/components/personal/invoiceHis";

export default {
  name: "order",
  // components: {
  //   pages,
  // },
  components: {
    OrderDetails,
    increaseInfo,
    invoiceHis
  },
  data() {
    return {

      invoiceHisDialogVisible: false,
      extendColumn1: "", // 发票id
      increasingShow: false,
      dialogVisibleInrecerse: false,
      reverse: true,
      routes: [],
      logisticsdialogVisible:false,
      radioMember: "",
      dialogVisible3: false, // 客户弹框
      allmemberList: [], // 弹框客户列表
      pageNumber3: 1,
      increaseInfoId: "",
      infoTime: "",
      pageSize3: 5,
      total4: 0,
      custName1: "", // 客户弹窗筛选
      customerID: "", //筛选条件客服id
      orderUserId: "", // 筛选条件的客户id
      orderuserName: "", // 客户名称
      isPayment: "", // 是否为账期 1 是
      payLoading: false, // 支付loading
      allTotalMoney: 0, // 当前页 订单总金额
      whetherFullPayment: "",
      activities: [], // 订单进度追踪
      showSuer: false, // 支付按钮
      canMoney: 0, // 可开票金额
      increaseList: [],
      // 发票列表
      increases: {
        pageSize: 10,
        currentPage: 1,
        extendColumn4: 0,
      },
      option2: [
        {
          value: "0",
          label: "尚未审核",
        },
        {
          value: "1",
          label: "审核通过",
        },
        {
          value: "2",
          label: "审核拒绝",
        },
        {
          value: "3",
          label: "已开发票",
        },
      ],
      querys: "", // 订单号
      value2: "", // 发票状态
      currentPage1: 1,
      pageSize1: 10,
      total1: 0,
      invoiceData: [], // 开票记录表
      // discountfeeTotal: "", // 订单总金额
      picUrls: "", // 通用网络地址
      fileConfig: false,
      payInfo: {
        // 支付参数
        orderId: "",
        userId: "",
        userfatherid: "",
        paymentProportion: "",
        haveOtherPayment: "",
        paymentChooses: "",
        haveOtherPaymentChooses: "",
      },
      paySuccess: false, // 微信、支付宝 支付成功
      orderMoney: 0, //(实际订单金额)
      orderPay: 0, // 订单实付金额
      credit: 0, //信用额度
      wallet: 0, // 钱包余额
      showPayType: false, //展示支付方式
      showPsBtn: false, //展示引导按钮
      showText: "", //展示文本
      img: "", // 二维码图片
      disabled1: true, //钱包
      disabled2: true, //其他
      disabled3: true, //信用
      checkList: [], // 复选框
      radio: "", // 单选
      input: "", //支付密码
      changeType: "0", // 是否余额抵扣(0:否，1是)
      payPop: false, // 支付弹窗打开
      fileList: [], // 上传文件列表
      rechargeFrom: {
        ordeRnumber: "",
        type: "",
        userId: "",
        orderId: ""
      },
      fileData: [
        // {
        //   createTime: "2021-06-03",
        //   orderFileName: "dafd.png",
        //   orderFileStruts: "1",
        // },
      ], // 文件上传列表
      startTime1: "",
      endTime1: "", // 文件上传时间
      orderInfo: {}, // 订单详情
      billdInfo: {}, //发票信息
      billing: false, // 发票详情弹框
      // orderType: "", // 订单生产状态筛选
      orderPayType: "", // 订单支付状态筛选
      options1: [
        // 订单支付状态
        {
          value: "3",
          label: "待支付",
        },
        {
          value: "5",
          label: "已支付",
        },
      ],
      options2: [
        // 订单支付状态
        {
          value: "0",
          label: "全款",
        },
        {
          value: "1",
          label: "30%预付款",
        },
        {
          value: "2",
          label: "线下付款",
        },
      ],
      options: [
        // 客服
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      checked: 0, // 切换模块
      userInfo: [
        {
          id: 0,
          text: "订单查询",
        },
        {
          id: 2,
          text: "发票申请",
        },
      ],
      // 充值金额
      dynamicValidateForm: {
        recharge: "",
      },
      rules: {
        recharge: [
          { required: true, message: "请输入充值金额", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
      },

      //开始时间
      starttime: "",
      //结束时间
      endtime: "",
      //产品ID
      cpid: "",

      activeName: "first",

      // 表格数据
      tableData: [],

      // tabbar切换下标
      index: "0",

      //上传文件
      dialogVisible: false,

      //进度追踪
      speed: false,
      userId: "",

      detail: false, // 订单详情弹窗打开
      feedetail: false,
      title: "", //订单详情弹窗打开标题
      // OrderDetails:'',//订单详情
      timer: "",
      phone: "", // 当前用户手机号
      selectList: [],
      complaints: false, // 投诉弹窗打开
      complaintsList: [], //投诉列表
      //投诉分类
      ComplaintClassification: [
        { name: "质量", id: 0 },
        { name: "数量", id: 1 },
        { name: "交期", id: 2 },
        { name: "物流", id: 3 },
        { name: "包装", id: 4 },
        { name: "服务", id: 5 },
        { name: "印前", id: 6 },
        { name: "其他", id: 7 },
      ],
      //投诉内容
      form: {
        text: "",
        types: [],
      },
      rule: {
        types: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个投诉分类",
            trigger: "change",
          },
        ],
        text: [{ required: true, message: "请填写投诉内容", trigger: "blur" }],
      },

      obsAccessKeyId: "", //key   初始化使用
      obsBucketName: "", //桶名称
      obsFileDir: "", //文件夹
      obsSecretAccessKey: "", //秘钥   初始化使用
      obsServer: "", //服务器地址  初始化使用
      posterURL: "", //上传的文件名
      userType: 0, // 当前账户角色
      payPass: false, // 支付密码是否校验成功
      checks: false, //有支付密码
      showMessage: true,
      newPwdReadOnly: false,
      threePay: false, //余额加信用不足

      //上传文件第几页
      currentPagefile: 1,
      //上传文件每页条数
      pageSizefile: 10,
      //上传文件总条数
      filetotal: 0,
      //上传文件_时间戳
      Filename: "",
      //上传文件_加载遮罩层
      loading: "",
      //浏览地址
      BrowseUrl: "",
      offlineOrderMoney: "",
      upStre: 1,
      customerName: "",
      orderId: "",
      orderIds: "",
      invoice: ''
    };
  },
  watch: {

  },
  created() {
    this.picUrls = picUrl.picUrl;
    this.init();

  },
  computed: {
    discountfeeTotal() {
      if (this.selectList.length > 0) {
        return this.selectList.reduce((pav, next) => {
          return pav + Number(next.discountfee);
        }, 0);
      } else {
        return 0;
      }
    },
  },
  filters: {
    numbers(value) {
      let relval = 0;
      if (!isNaN(value) && value !== "") {
        relval = parseFloat(value).toFixed(2);
      } else {
        relval = 0;
      }
      return relval;
    },
  },
  methods: {

    selectCheck(row,index){
      if(this.selectList.length >0){
        if(row.invoice === this.selectList[0].invoice){
          return true  //可勾选
        }else{
          return false  //不可勾选
        }
      } else {
        return true  //可勾选
      }
    },
    submitBill(){
      if (!this.extendColumn1 || !this.discountfeeTotal) {
        this.$message.error("请确认已选择了开票资料");
        return;
      }

      let data = {
        invoiceFeeTotal: this.discountfeeTotal,
        orderIssueIsOpen: '1',
        orderIds: this.orderIds,
        invoiceInfo: this.extendColumn1
      }
      this.loading = true;
      api
          .editInvoiceStatus(data)
          .then((res) => {
            console.log("开票申请", res);
            if (res.success) {
              this.loading = false;
              this.$message.success("开票申请成功");
              this.increasingShow = false;
              this.init()
            } else {
              this.$message.error("开票申请失败");
              this.loading = false;
            }
          })
          .catch((error) => {
            console.log("error", error);
            this.loading = false;
          });
    },
    // 判断生产状态
    typeFunc(v) {
      let text = "";
      if (v === "-1") {
        return (text = "待核价");
      } else if (v === "1") {
        return (text = "待审核");
      } else if (v === "2") {
        return (text = "待排产");
      } else if (v === "3") {
        return (text = "生产中");
      } else if (v === "4") {
        if (this.userType === 0) {
          return (text = "生产完成");
        } else {
          return (text = "待入库");
        }
      } else if (v === "5") {
        if (this.userType === 0) {
          return (text = "待发货");
        } else {
          return (text = "已入库");
        }
      } else if (v === "6") {
        if (this.userType === 0) {
          return (text = "已发货");
        } else {
          return (text = "待签收");
        }
      } else if (v === "7") {
        return (text = "订单取消");
      } else if (v === "8") {
        return (text = "订单完成(已评价)");
      } else if (v === "9") {
        return (text = "待印前制作");
      } else if (v === "10") {
        return (text = "待客确认");
      } else if (v === "11") {
        return (text = "已收货");
      } else {
        return text;
      }
    },


    // 刷新开票记录
    refreshs() {
      this.value2 = this.querys = "";
      this.getInvoice();
    },
    // 获取开票记录
    getInvoice() {
      api
          .invoiceList(
              this.pageSize1,
              this.currentPage1,
              this.querys,
              this.value2
          )
          .then((res) => {
            console.log("获取用户开票列表", res);
            if (res.success) {
              this.invoiceData = res.data.list;
              this.total1 = res.data.total;
            }
          });
    },
    sureBillHis(){
      this.invoiceHisDialogVisible = true
    },
    // 开发票
    sureBill() {
      if (this.selectList.length <= 0) {
        this.$message.warning("请至少选择一个订单)");
        return;
      }
      let len = this.selectList.length;
      let obj = this.selectList;
      let arr = [];
      for (let i = 0; i < len; i++) {
        let id = obj[i].id;
        arr.push(id);
      }
      if (arr.length === 1) {
        arr[0] = arr[0] + ",";
      }
      console.log("处理后的订单低集合", arr);
      this.orderIds = arr.join(",")
      this.invoice =  this.selectList[0].invoiceName
      this.getList();
      this.extendColumn1 = ''

      this.increasingShow  = true

    },
    getList() {
      let data = this.increases;
      if (this.userType === 0) {
        data.extendColumn4 = 0;
      } else {
        data.extendColumn4 = 1;
      }
      api
          .getinvoiceListAll(data.currentPage, data.pageSize,this.invoice, data.extendColumn4)
          .then((res) => {
            if (res.success) {
              this.increaseList = res.data.list;
            }
          });
    },

    IncreaseInfo(item) {
      console.log("详情", item);
      this.infoTime = new Date().getTime();
      this.increaseInfoId = item.id;
      this.dialogVisibleInrecerse = true;
    },

    moreSelect(selection, row) {
      console.log(selection, row);
    },
    changeSelect(selection) {
      console.log(selection);
      this.selectList = selection;
    },
    getCurrentRow(item) {
      console.log("单选", item);
    },



    //浏览
    browse(row) {
      console.log(row);
      this.BrowseUrl = picUrl.BrowseUrl + row.orderFileDir;
    },


    // 订单详情
    details(item) {
      this.timer = new Date().getTime();
      this.orderInfo = item;
      // let level = "";
      // this.$nextTick(() => {
      //   level = this.$refs.orderDetails.returnLevel();
      //   console.log("获取会员级别", level);
      // });
      this.title = `订单【${this.orderInfo.id}】详情`;
      this.detail = true;
    },





    // 表格自定义样式调整
    tableRowStyle() {
      return "padding:0;";
    },
    //获取订单列表
    init() {
      api
          .queryInvoiceOrder(
              this.starttime,
              this.endtime,
              this.customerID,
              this.pageSize,
              this.currentPage,
              this.orderUserId,
              this.orderPayType,
              this.orderId,
              this.whetherFullPayment
          )
          .then((res) => {
            console.log("获取订单列表", res);
            if (res.success) {
              this.total = res.data.total;
              let data = res.data.list;

              this.tableData = data;
            } else {
              this.$message.error("获取订单列表失败");
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
    },



    // 查询
    query() {
      //   if (
      //     this.starttime === "" &&
      //     this.endtime === "" &&
      //     this.cpid === "" &&
      //     this.orderType === "" &&
      //     this.orderPayType === ""
      //   ) {
      //     this.$message.warning("请输入查询参数");
      //     return;
      //   }
      this.init();
    },
    // 选择客户分页
    handleSizeChange4(val) {
      this.pageSize3 = val;
      this.getCustomer();
    },
    handleCurrentChange4(val) {
      this.pageNumber3 = val;
      this.getCustomer();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.init();
    },
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.getInvoice();
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.getInvoice();
    },
    handleSizefileChange(val) {
      this.pageSizefile = val;
      this.getUpLoadFilesList();
    },
    handleCurrentfileChange(val) {
      this.currentPagefile = val;
      this.getUpLoadFilesList();
    },
    //tabbar切换
    handleClick(tab, event) {
      console.log(tab, event);
      console.log(tab.index);
      this.index = tab.index;
    },
  },
};
</script>

<style lang="scss" scoped>
.personalInfo {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .card-left {
    width: 200px;
    height: 320px;

    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;

      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }

    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
      }

      li:last-child {
        border-bottom: none;
      }

      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }
  }

  .card-right {
    width: 980px;
    background-color: #fff;
  }

  .info {
    width: 100%;
    box-sizing: border-box;
    // padding: 15px;
  }

  .card {
    width: 100%;

    .screen {
      margin-top: 10px;

      .colls {
        display: flex;
        line-height: 40px;
        margin: 15px 0;

        span {
          margin-right: 5px;
          width: 100px;
        }

        .kuoz {
          margin: 0 10px;
        }
      }
      .moneyTotal {
        line-height: 40px;
        margin-top: 15px;
      }

      .coll {
        display: flex;
        justify-content: space-around;
        .ipt {
          width: 25%;
        }
      }
      .btn {
        margin-left: 20px;
      }
    }

    .card-top {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #e6e6e6;
      padding: 0 30px;
      line-height: 50px;

      span {
        display: inline-block;
        width: 5px;
        height: 17px;
        background: #058af2;
        margin-right: 20px;
      }

      h3 {
        color: #058af2;
      }
    }

    .list {
      width: 100%;
      box-sizing: border-box;
      padding: 5px 30px;

      .list-top {
        display: flex;
        justify-content: space-between;

        span {
          color: #058af2;
        }

        span:last-child:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .list-item {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .items {
          margin-top: 10px;
          width: 255px;
          height: 155px;
          background: transparent url("../../static/imgs/beijing1.png")
          no-repeat scroll 0% 0%;
          position: relative;

          .border {
            box-sizing: border-box;
            padding: 15px;
            font-size: 14px;
            font-weight: 400;

            p {
              padding: 3px 0 5px 0;

              border-bottom: 1px solid #e6e6e6;
            }

            i {
              display: inline-block;
              margin-right: 10px;
            }

            p:nth-child(2) {
              height: 65px;
              display: flex;
              align-items: center;

              span {
                display: block;
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
              }
            }

            p:last-child {
              border-bottom: none;
            }
          }

          .top {
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            color: #fff;

            .top-df {
              display: none;
              background: #fbb244 none repeat scroll 0% 0%;
              padding: 0px 10px;
              border-radius: 0px 0px 5px 5px;
            }
          }

          .active {
            display: block;
            padding: 0 10px;
            background: #058af2 none repeat scroll 0% 0%;
          }

          .top:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          .bottom {
            display: none;
            position: absolute;
            right: 20px;
            bottom: 8px;
            cursor: pointer;
            font-size: 14px;

            p {
              float: left;
              border: 1px solid #d4d4d4;
              margin-left: 5px;
              height: 25px;
              line-height: 25px;
              width: 40px;
              text-align: center;
              border-radius: 3px;
              background-color: #fff;
            }

            p:hover {
              color: #058af2;
            }
          }
        }

        .items-bgd {
          background: transparent url("../../static/imgs/beijing2.png")
          no-repeat scroll 0% 0%;
        }

        .items:hover .top-df {
          display: block;
        }

        .items:hover .bottom {
          display: block;
        }

        .items:nth-last-child(-n + 1) {
          height: 0;
          background: none;
        }
      }
    }
  }

  /* 查询条件 */
  .query {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .input {
      width: 30%;
      margin: 0 10px;
    }

    .btn {
      margin-left: 10px;
    }
  }

  /* 开票记录 */
  .invoices {
    .pages {
      margin: 10px;
    }
  }
  .invoiceListClass {
    padding: 10px;
  }

  /*账户充值*/
  .recharge {
    .card-form {
      width: 50%;
      margin: 30px auto;
      /*账户充值_金额数目*/
      .money {
        color: red;
        padding: 0 5px;
        font-weight: bold;
      }
    }

    /*付款方式*/
    .payment {
      width: 95%;
      border-top: 1px dashed #e6e6e6;

      .text {
        font-size: 14px;
        width: 50%;
        margin: 20px auto 20px;
      }

      /*付款平台*/
      .pingtai {
        overflow: hidden;

        ul li {
          float: left;
          display: inline;
          position: relative;
          width: 177px;
          height: 50px;
          cursor: pointer;
          border: 1px solid #d2d1d1;
          margin-right: 18px;
          /*微信支付*/
          .wx {
            background: url(../../static/imgs/sprites-payment-logo.png)
            no-repeat;
            background-position: 0 -1880px;
            display: inline-block;
            width: 175px;
            height: 37px;
            vertical-align: middle;
            position: absolute;
            top: 7px;
            *top: 5px;
            left: 1px;
            z-index: 2;
          }

          /*支付宝支付*/
          .zfb {
            background: url(../../static/imgs/sprites-payment-logo.png)
            no-repeat;
            background-position: 0 -120px;
            display: inline-block;
            width: 175px;
            height: 37px;
            vertical-align: middle;
            position: absolute;
            top: 7px;
            *top: 5px;
            left: 1px;
            z-index: 2;
          }
        }
      }
    }
  }

  /*上传文件*/
  .bill {
    /*筛选条件*/
    .card-form {
      display: flex;
      line-height: 40px;
      margin: 20px auto;
      margin-right: 20px;
      justify-content: flex-end;

      div {
        padding: 0 5px;
      }
    }

    /*表格*/
    .table {
      margin-right: 0;
      border-top: 1px dashed #e6e6e6;

      table {
        width: 100%;
        border-collapse: collapse; //去除td之间的空隙

        tr {
          th:first-of-type {
            width: 40%;
          }

          td {
            font-size: 13px;

            .grey {
              color: #b4b4b4;
              margin-right: 5px;
            }

            .bottom {
              height: 90px;
              padding-top: 10px;
            }

            .bottom > p {
              line-height: initial;
              margin: 5px 0;
            }

            /*图文部分*/
            .picture {
              display: flex;
              font-size: 13px;
              line-height: initial;

              img {
                margin-left: 15px;
                margin-bottom: 10px;
                width: 80px;
                height: 80px;
              }

              .text {
                margin-left: 15px;
                margin-top: 0;
                margin-bottom: 0;
                display: grid;

                p {
                  color: #999;

                  a {
                    color: #1d94d1;
                    text-decoration: none;
                  }

                  .c1 {
                    color: #e56315;
                  }
                }
              }
            }
          }

          /*订单状态/进度追踪*/
          .zhuangtai,
          .jindu {
            text-align: center;

            i {
              font-weight: bold;
            }
          }
        }

        .list {
          border: 1px solid #f8f8f8;

          td > div:first-of-type {
            background-color: #f8f8f8;
          }
        }
      }
    }

    /*分页*/
    .pages {
      display: flex;
      margin: 20px 20px;
      justify-content: space-between;
    }
  }

  /*发票申请*/
  /*.invoice{*/
  /*    !*.card-top{*!*/
  /*        a{*/
  /*            font-size: 14px;*/
  /*            color: #1D94D1;*/
  /*            cursor: pointer;*/
  /*            text-decoration: none;*/
  /*        }*/
  /*    !*}*!*/
  /*}*/
}
.dialogs {
  width: 100%;
  .queryMember {
    width: 100%;
    margin-bottom: 10px;
  }
  .memberTable {
    ::v-deep .el-radio__label {
      display: none;
    }
  }
}
.tables {
  ::v-deep .el-table .cell {
    padding: 0;
  }

  ::v-deep .el-table .cell,
  ::v-deep .el-table--border td:first-child .cell {
    padding: 0;
  }

  ::v-deep .el-table_1_column_1 {
    padding: 0;
  }

  ::v-deep .el-table td {
    padding: 0;
  }

  .top {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #f8f8f8;
    color: #b4b4b4;

    span {
      color: #000000;
    }
  }

  .box {
    padding: 5px 10px;
    height: 102px;
    display: flex;
    justify-content: space-around;
    .img {
      margin: 4px 15px 0;
      width: 80px;
      height: 80px;

      img {
        display: inline-block;
        width: 80px;
        height: 100%;
      }
    }
    .info {
      width: 35%;
      .p1 {
        color: #409eff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .p3 {
        span {
          color: #ff0000;
        }
      }
    }
    .rights {
      width: 35%;
      .p3 {
        span {
          color: #ff0000;
        }
      }
    }
  }

  .types {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      padding: 10px 0;
    }

    .btn {
      width: 50%;
    }
  }

  .boxInfo {
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep .el-button {
      height: 40px;
    }
  }
}

.content {
  .orderInfo {
    margin-bottom: 10px;

    p {
      text-indent: 2em;
    }
  }
}

.upFile {
  text-align: center;

  .top {
    margin-bottom: 10px;

    .date {
      margin-top: 10px;

      .btn {
        margin-left: 10px;
      }
    }
  }

  .tableList {
    margin-bottom: 10px;

    .success {
      color: #058af2;
    }

    .error {
      color: #ff0000;
    }
  }

  .noTable {
    text-align: center;
    color: #66b1ff;
    margin: 15px;
  }

  .upBtn {
    margin-top: 15px;
  }
}

.payContent {
  /*text-align: center;*/

  .orderInfo {
    margin: 10px 0;
  }

  .top {
    margin: 10px 0;

    .check-group {
      display: flex;
      flex-direction: column;
      line-height: 30px;
    }
  }

  .ipt {
    height: 40px;
    line-height: 40px;
    display: flex;
    margin: 15px 0px;

    .inputs {
      width: 200px;
    }
  }

  .payTypes {
    /*付款平台*/
    .pingtai {
      overflow: hidden;
      margin-left: 125px;

      ul li {
        float: left;
        display: inline;
        position: relative;
        width: 177px;
        height: 50px;
        cursor: pointer;
        border: 1px solid #d2d1d1;
        margin-right: 18px;
        /*微信支付*/
        .wx {
          background: url(../../static/imgs/sprites-payment-logo.png) no-repeat;
          background-position: 0 -1880px;
          display: inline-block;
          width: 175px;
          height: 37px;
          vertical-align: middle;
          position: absolute;
          top: 7px;
          *top: 5px;
          left: 1px;
          z-index: 2;
        }

        /*支付宝支付*/
        .zfb {
          background: url(../../static/imgs/sprites-payment-logo.png) no-repeat;
          background-position: 0 -120px;
          display: inline-block;
          width: 175px;
          height: 37px;
          vertical-align: middle;
          position: absolute;
          top: 7px;
          *top: 5px;
          left: 1px;
          z-index: 2;
        }
      }
    }

    .imgs {
      display: flex;
      justify-content: center;
    }

    p {
      text-align: center;
      color: #ff0000;
    }
  }

  .successBtn {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/*投诉 表格*/
.complaints {
  table {
    border: 1px solid #ebeef5;
    width: 100%;
    /*tr{*/
    /*    border-bottom: 1px solid #EBEEF5;*/
    /*}*/
    /*tr:last-of-type{*/
    /*    border-bottom: 0;*/
    /*}*/
    tr {
      th {
        color: #909399;
        font-size: 14px;
        font-weight: bold;
        padding: 12px 10px;
        text-align: center;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
      }

      th:last-of-type {
        border-right: 0;
      }

      td {
        color: #606266;
        font-size: 14px;
        padding: 12px 10px;
        text-align: center;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
      }

      td:last-of-type {
        border-right: 0;
      }

      .orange {
        color: #eb6d00;
        font-size: 20px;
      }
    }

    tr:last-of-type th {
      border-bottom: 0;
    }

    tr:last-of-type td {
      border-bottom: 0;
    }
  }
}

/*文件上传弹框*/
.file {
  ::v-deep .el-dialog {
    overflow: auto;
    //height: 700px;
  }
}

/*操作_浏览按钮*/
.browse {
  padding: 0;

  .liulan {
    color: white;
    text-decoration: none;
    height: 100%;
    padding: 7px 15px;
    display: block;
    text-align: center;
  }
}

/*!*文件上传加载遮罩层*!*/
/*.el-popup-parent--hidden{*/
/*    ::v-deep .el-loading-mask{*/
/*        z-index: 99999;*/
/*    }*/
/*}*/
</style>

<style lang="scss">
.el-checkbox__inner,
.el-radio__inner {
  border: 1px solid #232430;
}
/*tabbar切换  */
.el-tabs {
  display: block;
  width: 100%;
  /*tabbar分布*/
  .el-tabs__nav {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .el-tabs__active-bar {
      display: none;
    }

    .is-active {
      border-bottom: 2px solid #409eff;
    }

    .el-tabs__item {
      margin: 0 20px;
      padding: 0;
    }
  }
}

.table {
  .list {
    .bottom {
      /*上传文件按钮*/
      .el-button--text {
        color: white;
        border-radius: 5px;
        padding: 12px 15px;
        background-color: #409eff;
      }
    }

    .jindu {
      /*进度追踪按钮*/
      .el-button--text {
        color: white;
        border-radius: 5px;
        padding: 10px 8px;
        background-color: #409eff;
      }
    }
  }
}

.el-input__icon:after {
  height: auto;
}

/*  !*表格样式  *!*/
/*    .table {*/
/*        .el-table .cell {*/
/*            text-align: center !important;*/
/*        }*/
/*    }*/

/*!*  发票申请（按钮样式）  *!*/
/*  .invoice{*/
/*      .el-row{*/
/*          display: flex;*/
/*          margin: 10% auto;*/
/*          button{*/
/*              margin: auto;*/
/*          }*/
/*      }*/

/*  !*  申请发票表单  *!*/
/*      .el-form{*/
/*          margin: 20px auto;*/
/*          width: 50%;*/
/*      }*/
/*  }*/

.card {
  .detail {
    .el-dialog {
      height: 75% !important;
      overflow: auto;

      .el-dialog__header {
        padding: 20px 20px 20px;
        // position: fixed;
        background: #fff;
        width: calc(55% - 40px);
      }

      // .el-dialog__body {
      //   margin-top: 64px;
      // }
    }
  }
}
</style>
