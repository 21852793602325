<template>
  <div>
    <div class="personalInfo">
      <div class="card-left">
        <div class="top">
          <i class="iconfont icon-iconcopy"></i>
          <span>订单管理</span>
        </div>
        <ul class="infos">
          <li
            :class="checked === item.id ? 'checked' : ''"
            :key="item.id"
            @click="replace(item)"
            class="info-item"
            v-for="item in userInfo"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="card-right">
        <!-- 订单查询 -->
        <!-- 上传文件 -->
        <div class="info bill" v-if="checked === 0">
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>订单查询</h3>
            </div>
            <!--筛选条件-->
            <div class="screen">
              <el-row :gutter="10">
                <el-col :span="12" class="colls">
                  <span>按日期查询:</span>
                  <el-date-picker
                    format="yyyy-MM-dd"
                    placeholder="选择日期"
                    type="date"
                    v-model="starttime"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                  <div class="kuoz">-</div>
                  <el-date-picker
                    format="yyyy-MM-dd"
                    placeholder="选择日期"
                    type="date"
                    v-model="endtime"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-col>
                <el-col :span="6" style="line-height: 40px; margin-top: 15px">
                  <el-select
                    placeholder="请选择订单支付方式"
                    v-model="whetherFullPayment"
                    clearable
                  >
                    <el-option
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      v-for="item in options2"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <p class="moneyTotal">
                    订单总金额:<span style="color: #ff0000">{{
                      allTotalMoney
                    }}</span>
                  </p>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="5" class="coll" v-if="userType !== 0">
                  <el-input
                    placeholder="请选择客户"
                    v-model="orderuserName"
                    @focus="changeMember"
                  ></el-input>
                  <!-- <el-select
                    placeholder="请选择客户"
                    v-model="orderType"
                    clearable
                  >
                    <el-option
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      v-for="item in options"
                    >
                    </el-option>
                  </el-select> -->
                </el-col>
                <el-col :span="5">
                  <el-select
                    placeholder="请选择订单支付状态"
                    v-model="orderPayType"
                    clearable
                  >
                    <el-option
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      v-for="item in options1"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-form>
                  <el-col :span="5">
                    <el-form-item v-if="userType === 1">
                      <el-input
                        placeholder="请输入订单编号"
                        clearable
                        v-model="orderId"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item v-if="userType === 1">

                      <el-select
                          filterable
                          placeholder="请选择客服"
                          v-model="customerID"
                          clearable
                      >
                        <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in options"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" style="display: flex">
                    <el-form-item>
                      <el-button @click="query" class="btn" type="primary"
                        >查询
                      </el-button>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="refresh" class="btn">刷新</el-button>
                    </el-form-item>
                  </el-col>
                </el-form>
              </el-row>
            </div>
            <!-- 表格 -->
            <div class="tables">
              <el-table
                :cell-style="tableRowStyle"
                :data="tableData"
                border
                ref="tables"
                style="width: 100%"
                @select="moreSelect"
                @selection-change="changeSelect"
              >
                <!-- <el-table-column
                  type="selection"
                  width="55"
                  :selectable="selectEnable"
                  align="center"
                  header-align="center"
                >
                </el-table-column> -->
                <el-table-column
                  header-align="center"
                  label="订单详情"
                  width="500"
                >
                  <template slot-scope="scope">
                    <div class="top">
                      下单时间：<span>{{ scope.row.ordertime }}</span>
                      订单编号：<span>{{ scope.row.id }}</span>
                    </div>
                    <div class="box">
                      <div class="img">
                        <img :src="picUrls + scope.row.productimage" alt="" />
                      </div>
                      <div class="info">
                        <p class="p1">产品名称：{{ scope.row.ordername }}</p>
                        <p class="p1">客户名称：{{ scope.row.customername }}</p>
                        产品类型: <span> {{
                          scope.row.productid == 1038
                            ? "综合"
                            : scope.row.productid == 1036
                            ? "挂历"
                            : scope.row.productid == 1035
                            ? "台历"
                            : scope.row.productid == 1034
                            ? "精装书"
                            : scope.row.productid == 1032
                            ? "封套"
                            : scope.row.productid == 1028
                            ? "单页/海报"
                            : scope.row.productid == 1027
                            ? "教辅"
                            : scope.row.productid == 1024
                            ? "瓦楞彩箱"
                            : scope.row.productid == 1023
                            ? "单层卡纸彩盒"
                            : scope.row.productid == 1020
                            ? "画册/样本"
                            : scope.row.productid == 1018
                            ? "装帧加工"
                            : scope.row.productid == 1016
                            ? "印刷加工"
                            : scope.row.productid == 1015
                            ? "手提袋"
                                                                              : scope.row.productid == 2028
                                                                                  ? "单页"
                                                                                  : scope.row.productid == 2037
                                                                                      ? "不干胶"
                                                                                      : scope.row.productid == 2020
                                                                                          ? "普通画册"
                                                                                          : scope.row.productid == 2034
                                                                                              ? "精装画册"
                                                                                              : scope.row.productid == 2026
                                                                                                  ? "挂历"
                                                                                                  : scope.row.productid == 2015
                                                                                                      ? "手提袋"
                                                                                                      : scope.row.productid == 2039
                                                                                                          ? "吊旗"
                                                                                                          : scope.row.productid == 2035
                                                                                                              ? "台历"
                                                                                                              : scope.row.productid == 2032
                                                                                                                  ? "封套"
                                                                                                                  : scope.row.productid == 2040
                                                                                                                      ? "写真"
                                                                                                                      : scope.row.productid == 2041
                                                                                                                          ? "X型展架"
                                                                                                                          : scope.row.productid == 2042
                                                                                                                              ? "易拉宝"
                                                                                                                              : scope.row.productid == 2043
                                                                                                                                  ? "名片"
                            : ""
                        }}</span>
                        <p class="p3">
                          印刷数量：<span>{{
                            scope.row.quantitydemanded
                          }}</span>
                        </p>
                      </div>
                      <div class="rights">
                        <p class="p3">
                          订单金额：<span>{{ scope.row.discountfee }}</span>
                        </p>
                        <p class="p3">
                          开票类型：<span>{{  scope.row.invoiceName }}</span>
                        </p>

                        <p
                          class="p3"
                          v-if="scope.row.whetherFullPayment !== null"
                        >
                          付款方式：<span v-if="scope.row.payStruts === 5">{{
                            scope.row.whetherFullPayment === 0
                              ? "全款"
                              : scope.row.whetherFullPayment === 1
                              ? `30% 预付款(剩余部分请前往费用中心进行补款)`
                              : "线下付款"
                          }}</span>
                          <span v-else>未支付</span>
                        </p>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  header-align="center"
                  label="订单状态"
                  width="300"
                >
                  <template slot-scope="scope">
                    <div class="top">
                      生产状态：<span>{{ typeFunc(scope.row.status) }}</span
                      >支付状态：<span>{{
                        scope.row.payStruts === 3 ? "待支付" : "已支付"
                      }}</span>
                    </div>
                    <div class="box boxInfo">
                      <el-button
                        @click="paySet(scope.row)"
                        type="primary"
                        v-if="scope.row.payStruts !== 5"
                      > {{userType === 0?'支付':"待客支付"}}
                      </el-button>
                      <el-button  v-if="scope.row.status === '6' " @click="logistics(scope.row)"  type="primary"  >物流信息</el-button>
                      <el-button    v-if="fileConfig" @click="upLoadFile(scope.row)" type="primary"
                        >{{
                          scope.row.uploadEntry > 0
                            ? "已上传文件"
                            : "待上传文件"
                        }}
                      </el-button>
                      <el-button  v-if="!fileConfig" @click="uploadFileVisible = true" type="primary">上传文件
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column header-align="center" label="交易状态">
                  <template slot-scope="scope">
                    <div class="top">
                      <el-button
                        @click="track(scope.row)"
                        size="small"
                        type="primary"
                        ><i class="el-icon-time"></i>进度追踪
                      </el-button>
                    </div>
                    <div class="box types">
                      <el-link
                        @click="details(scope.row)"
                        :underline="false"
                        type="primary"
                        >订单详情
                      </el-link>
                      <el-link
                          v-if = "userType === 1"
                          @click="feeDetails(scope.row)"
                          :underline="false"
                          type="primary"
                      >报价明细
                      </el-link>
                      <el-link
                        :disabled="userType === 1"
                        @click="complaint(scope.row)"
                        :underline="false"
                        type="primary"
                        >{{ scope.row.complaintEntry > 0 ? "已投诉" : "投诉" }}
                      </el-link>
                      <el-link
                        v-show="scope.row.payStruts === 3"
                        @click="delleteOrder(scope.row)"
                        :underline="false"
                        type="primary"
                      >
                        删除订单
                      </el-link>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 上传文件弹出层 -->
            <el-dialog
              :visible.sync="dialogVisible"
              title="上传并关联文件"
              width="55%"
              class="file"
            >
              <div class="upFile">
                <div class="top">
                  <p>订单编号：{{ orderInfo.id }}</p>
                  <p>产品名称：{{ orderInfo.ordername }}</p>
                  <div class="date">
                    <span>文件上传日期：</span>
                    <el-date-picker
                      format="yyyy-MM-dd"
                      placeholder="起始时间"
                      type="date"
                      v-model="startTime1"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <span>-</span>
                    <el-date-picker
                      format="yyyy-MM-dd"
                      placeholder="终止时间"
                      type="date"
                      v-model="endTime1"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <el-button class="btn" type="primary" @click="queryList"
                      >查询
                    </el-button>
                  </div>
                </div>
                <div class="tableList" v-if="fileData.length > 0">
                  <el-table :data="fileData" border style="width: 100%">
                    <el-table-column
                      align="center"
                      header-align="center"
                      label="上传时间"
                      prop="createTime"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      header-align="center"
                      label="文件名"
                      prop="orderFileName"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      header-align="center"
                      label="状态"
                    >
                      <template slot-scope="scope">
                        <span
                          :class="
                            scope.row.orderFileStruts == '1'
                              ? 'success'
                              : 'error'
                          "
                          >{{
                            scope.row.orderFileStruts == "1"
                              ? "已上传"
                              : "上传失败"
                          }}</span
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      header-align="center"
                      label="操作"
                    >
                      <template slot-scope="scope">
                        <el-button
                          @click="delFile(scope.row)"
                          size="mini"
                          type="danger"
                          >删除
                        </el-button>
                        <el-button
                          @click="browse(scope.row)"
                          size="mini"
                          type="warning"
                          class="browse"
                        >
                          <a class="liulan" :href="BrowseUrl" target="_blank"
                            >浏览</a
                          >
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="tableList" v-else>
                  <el-table :data="fileData" border style="width: 100%">
                    <el-table-column
                      align="center"
                      header-align="center"
                      label="上传时间"
                      prop="createTime"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      header-align="center"
                      label="文件名"
                      prop="orderFileName"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      header-align="center"
                      label="状态"
                    >
                      <template slot-scope="scope">
                        <span
                          :class="
                            scope.row.orderFileStruts == '1'
                              ? 'success'
                              : 'error'
                          "
                          >{{
                            scope.row.orderFileStruts == "1"
                              ? "已上传"
                              : "上传失败"
                          }}</span
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      header-align="center"
                      label="操作"
                    >
                      <template slot-scope="scope">
                        <el-button
                          @click="delFile(scope.row)"
                          size="mini"
                          type="danger"
                          >删除
                        </el-button>
                        <el-button
                          @click="browse(scope.row)"
                          size="mini"
                          type="warning"
                          class="browse"
                        >
                          <a class="liulan" href="" target="_blank">浏览</a>
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="noTable">暂无文件,点击下方按钮上传文件吧！</div>
                </div>
                <!-- <el-button type="primary" class="upBtn" @click="upLoad">上传文件</el-button> -->

                <!--分页-->
                <div class="pages">
                  <el-pagination
                    :current-page="currentPagefile"
                    :page-size="pageSizefile"
                    :page-sizes="[5, 10, 20, 30]"
                    :total="filetotal"
                    @current-change="handleCurrentfileChange"
                    @size-change="handleSizefileChange"
                    layout="total, sizes, prev, pager, next, jumper"
                  >
                  </el-pagination>
                </div>

                <el-upload
                  :auto-upload="false"
                  :file-list="fileList"
                  :on-preview="handlePreview"
                  :on-change="handChange"
                  :http-request="customUpload"
                  :on-success="onSuccess"
                  :on-progress="onProgress"
                  :before-remove="befRemove"
                  action="#"
                  class="upload-demo"
                  id="upload-demo"
                  ref="upload"
                >
                  <el-button size="small" slot="trigger" type="primary"
                    >选取文件
                  </el-button>
                  <el-button
                    @click="submitUpload"
                    size="small"
                    style="margin-left: 10px"
                    type="success"
                    >上传到服务器
                  </el-button>
                </el-upload>
              </div>
              <!--<span class="dialog-footer" slot="footer">-->
              <!--  <el-button @click="dialogVisible = false">取 消</el-button>-->
              <!--  <el-button @click="dialogVisible = false" type="primary">确 定</el-button>-->
              <!--</span>-->
            </el-dialog>
            <!--进度追踪弹出层-->
            <el-dialog :visible.sync="speed" title="订单进度追踪" width="30%">
              <el-timeline :reverse="true" v-if="activities.length > 0">
                <el-timeline-item
                  v-for="(activity, index) in activities"
                  :key="index"
                  :color="activity.color"
                  :timestamp="activity.opTime"
                >
                  {{ activity.opProject }}
                </el-timeline-item>
              </el-timeline>
              <p v-else>当前订单流程暂未更新，请谅解...</p>
              <span class="dialog-footer" slot="footer">
                <el-button @click="speed = false">取 消</el-button>
                <el-button @click="speed = false" type="primary"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- 发票弹出层 -->
            <el-dialog :visible.sync="billing" title="开票信息" width="30%">
              <div class="content">
                <div class="orderInfo">
                  <p>
                    订单金额：<span style="color: #ff0000">{{
                      discountfeeTotal
                    }}</span>
                  </p>
                </div>
                <div class="fapiao">
                  <p>
                    发票类型：<span>{{
                      billdInfo.increaseTicketType
                    }}</span>
                  </p>
                  <p>
                    开票类型：<span>{{
                      billdInfo.extendColumn1 === "1" ? "个人" : "单位"
                    }}</span>
                  </p>
                  <p>收票人姓名：{{ billdInfo.recipientSName }}</p>
                  <p>收票人电话：{{ billdInfo.recipientSPhone }}</p>
                  <p v-if="billdInfo.extendColumn1 === '1'">
                    收票抬头：{{ billdInfo.increaseTicketTitle }}
                  </p>
                  <div class="danwei" v-if="billdInfo.extendColumn1 === '2'">
                    <p>收票单位：{{ billdInfo.unitName }}</p>
                    <p>发票税号：{{ billdInfo.increaseTicketNumber }}</p>
                    <p>注册地址：{{ billdInfo.increaseTicketAdress }}</p>
                    <p>注册电话：{{ billdInfo.increaseTicketPhone }}</p>
                    <p>开户银行：{{ billdInfo.increaseTicketBank }}</p>
                    <p>银行账号：{{ billdInfo.increaseTicketBankNumber }}</p>
                  </div>
                  <p>
                    收票地址：{{ billdInfo.recipientSProvinceZh }}
                    {{ billdInfo.recipientSCityZh }}
                    {{ billdInfo.recipientSAreaZh }}
                    {{ billdInfo.recipientSAdress }}
                  </p>
                </div>
              </div>
              <span class="dialog-footer" slot="footer">
                <el-button @click="billing = false">取 消</el-button>
                <el-button @click="sureBill" type="primary">确 定</el-button>
              </span>
            </el-dialog>
            <!-- 支付弹出层 -->
            <el-dialog
              title="订单支付"
              :visible.sync="payPop"
              width="50%"
              @close="closeDlg"
            >
              <div class="payContent">
                <div class="orderInfo">
                  <p v-if="userType !== 0">
                    客户名称：<span style="color: #058af2">{{
                      customerName
                    }}</span>
                  </p>
                  <p>
                    产品名称：<span style="color: #058af2">{{
                      orderInfo.ordername
                    }}</span>
                  </p>
                  <p>
                    订单金额：<span style="color: #ff0000">{{
                      orderInfo.discountfee
                    }}</span>
                  </p>
                  <p>
                    订单编号：<span>{{ orderInfo.id }}</span>
                  </p>
                  <p v-if="radio !== '3'">
                    实付金额：<span style="color: #ff0000">{{
                      orderPay | numbers
                    }}</span>
                  </p>
                  <p v-if="radio === '3'">
                    线下实付金额：<span style="color: #ff0000">{{
                      offlineOrderMoney | numbers
                    }}</span>
                  </p>
                </div>
                <div class="feetype">
                  <span>付款方式：</span>
                  <el-radio
                    v-model="radio"
                    label="1"
                    :disabled="orderInfo.payStruts === 4"
                    >首付30%
                  </el-radio>
                  <el-radio v-model="radio" label="2">全款</el-radio>
                  <el-radio v-if="isPayment == '1'" v-model="radio" label="3"
                    >线下付款</el-radio
                  >
                </div>
                <div class="top">
                  <el-checkbox-group
                    v-model="checkList"
                    class="check-group"
                    @change="checkGroup"
                  >
                    <el-checkbox label="1" :disabled="disabled1">
                      <span v-if="userType !== 0"
                        >{{ customerName }}的钱包余额：</span
                      >
                      <span v-else>钱包余额:</span>
                      <span style="color: #ff0000">{{ wallet }}</span
                      >元 (选择抵扣)
                    </el-checkbox>
                    <el-checkbox label="2" :disabled="disabled2"
                      >其他
                    </el-checkbox>
                    <!--                    <el-checkbox label="3" :disabled="disabled3"-->
                    <!--                      >信用付(<span style="color: #ff0000">{{ credit }}</span-->
                    <!--                      >元 额度)-->
                    <!--                    </el-checkbox>-->
                  </el-checkbox-group>
                </div>
                <div class="ipt" v-show="changeType === '1'">
                  <span>支付密码：</span>
                  <el-input
                    @input="wacthChange"
                    @blur="blurs($event)"
                    @focus="newPwdFocus($event)"
                    class="inputs"
                    v-model="input"
                    placeholder="请输入支付密码"
                    :type="
                      (newPwdReadOnly && input) || input ? 'password' : 'text'
                    "
                    :readonly="newPwdReadOnly"
                    auto-complete="new-password"
                    name="person.user.new_password"
                    ref="inputPs"
                  ></el-input>
                </div>
                <div class="payTypes" v-show="showPayType">
                  <div class="pingtai">
                    <ul>
                      <li>
                        <div class="wx" @click="bills('wx')"></div>
                      </li>
                      <li>
                        <div class="zfb" @click="bills('zfb')"></div>
                      </li>
                    </ul>
                  </div>
                  <div class="imgs" v-show="img !== ''">
                    <vue-qr :text="img" :size="200"></vue-qr>
                  </div>
                  <p v-if="showText === 1">请用微信支付</p>
                  <p v-if="showText === 2">请用支付宝支付</p>
                </div>
                <div class="successBtn" v-show="showPsBtn">
                  <!-- <el-button type="primary" @click="surePay"
                                      >是否支付成功
                                    </el-button>
                                    <i class="el-icon-warning" style="color: #00c800"
                                      >如果支付成功请点击此按钮</i
                                    > -->
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="clearPayData">取 消</el-button>
                <el-button
                  :loading="payLoading"
                  type="primary"
                  @click="comitPay"
                  :disabled="showSuer"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- 订单详情 -->
            <el-dialog
              :visible.sync="detail"
              class="detail"
              :title="title"
              width="55%"
            >
              <order-details
                ref="orderDetails"
                v-bind:orderId="orderInfo.id"
                :key="timer"
              ></order-details>
            </el-dialog>
            <!-- 报价明细 -->
            <el-dialog
                :visible.sync="feedetail"
                class="detail"
                :title="title"
                width="60%"
            >
              <fee-details
                  ref="feeDetails"
                  v-bind:orderId="orderInfo.id"
                  v-bind:userfatherid="orderInfo.userfatherid"
                  v-bind:orderfee="orderInfo.orderfee"
                  :key="timer"
              ></fee-details>
            </el-dialog>
            <!-- 投诉 -->
            <el-dialog
              :visible.sync="complaints"
              class="complaints"
              title="订单投诉"
              width="55%"
            >
              <table>
                <tr>
                  <th>订单号</th>
                  <th>印件名称</th>
                  <th>产品数量</th>
                  <th>订单金额</th>
                </tr>
                <tr>
                  <td>{{ complaintsList.id }}</td>
                  <td>{{ complaintsList.ordername }}</td>
                  <td>{{ complaintsList.quantitydemanded }}</td>
                  <td>{{ complaintsList.discountfee }}</td>
                </tr>
              </table>

              <!--投诉分类-->
              <el-form
                ref="form"
                :rules="rule"
                :model="form"
                label-width="100px"
                class="fwb"
              >
                <el-form-item label="投诉分类：" prop="types">
                  <el-checkbox-group
                    v-model="form.types"
                    @change="CheckedChange"
                  >
                    <el-checkbox
                      v-for="item in ComplaintClassification"
                      :label="item"
                      :value="item.id"
                      :key="item.id"
                      >{{ item.name }}
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <!--投诉内容-->
                <!--                <el-form ref="rule" :model="form" label-width="100px" class="fwb">-->
                <el-form-item label="投诉内容：" prop="text">
                  <editor
                    v-model.trim="form.text"
                    @change="change"
                    :info="form.text"
                  ></editor>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="submitForm('form')"
                    >立即投诉
                  </el-button>
                  <el-button @click="resetForm('form')">重置</el-button>
                </el-form-item>
              </el-form>
            </el-dialog>
            <!--分页-->
            <div class="pages">
              <el-pagination
                :current-page="currentPage"
                :page-size="pageSize"
                :page-sizes="[5, 10, 20, 30]"
                :total="total"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                layout="total, sizes, prev, pager, next, jumper"
              >
              </el-pagination>
              <!-- <el-button
                @click="applyInvoice"
                class="btn"
                size="mini"
                type="primary"
                >发票申请
              </el-button> -->
            </div>
          </div>
        </div>
        <!-- 开票记录 -->
        <div class="invoices" v-if="checked === 1">
          <!-- 开票列表 -->
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>开票记录表</h3>
            </div>
            <div class="query">
              <el-input
                class="input"
                v-model="querys"
                placeholder="请输入订单号"
              ></el-input>
              <el-select v-model="value2" placeholder="请选择" clearable>
                <el-option
                  v-for="item in option2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-button class="btn" type="primary" @click="getInvoice"
                >查询
              </el-button>
              <el-button @click="refreshs">刷新</el-button>
            </div>
            <el-table :data="invoiceData" border style="width: 100%">
              <el-table-column prop="createTime" label="申请日期" width="180">
              </el-table-column>
              <el-table-column prop="userName" label="申请人">
              </el-table-column>
              <el-table-column prop="orderId" label="订单编号" width="180">
              </el-table-column>
              <el-table-column prop="name" label="发票状态">
                <template slot-scope="scope">
                  {{
                    scope.row.issueStruts === "1"
                      ? "审核通过"
                      : scope.row.issueStruts === "2"
                      ? "审核拒绝"
                      : scope.row.issueStruts === "3"
                      ? "已开发票"
                      : "尚未审核"
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="extendColumn1" label="发票总金额">
              </el-table-column>
              <el-table-column prop="address" label="地址"></el-table-column>
            </el-table>
            <!-- 开票分页 -->
            <div class="pages">
              <el-pagination
                :current-page="currentPage1"
                :page-size="pageSize1"
                :page-sizes="[5, 10, 20, 30]"
                :total="total1"
                @current-change="handleCurrentChange1"
                @size-change="handleSizeChange1"
                layout="total, sizes, prev, pager, next, jumper"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- 发票申请 -->
        <div class="invoiceListClass" v-if="checked === 2 && userType == 0">
          <div class="card">
            <div class="card-top">
              <span></span>
              <h3>可开票申请的订单列表</h3>
            </div>
            <increase-list ref="increaseRef"></increase-list>
          </div>
        </div>
      </div>
    </div>



    <el-dialog
        title="物流跟踪信息"
        :visible.sync="logisticsdialogVisible"
        width="30%" >

      <el-timeline :sreverse="reverse">
        <el-timeline-item
            v-for="(activity, index) in routes"
            :color="index == routes.length-1?'#0bbd87':''"
            :key="index"
            :timestamp="activity.acceptTime">
          {{activity.remark}}
        </el-timeline-item>
      </el-timeline>

     <span slot="footer" class="dialog-footer">
     <el-button type="primary" @click="logisticsdialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>

    <el-dialog
        title="上传文件须知"
        :visible.sync="uploadFileVisible"
         width="30%" >
        <div>
          <span>1. 为保证更加高效的服务,上传文件前,请选仔细阅读完稿须知,按照要求,上传合格的印刷文件.</br></span>
          <span>2. 点击网站右侧的QQ图像,添加客服QQ好友,报上订单编号,把文件传给客服人员。</span>
        </div>


         <span slot="footer" class="dialog-footer">
           <el-button type="primary" @click="uploadFileVisible = false">知 道 了</el-button>
        </span>
    </el-dialog>
    <!-- 客户弹框 -->
    <div class="dialogs">
      <el-dialog
        :close-on-click-modal="false"
        title="选择客户"
        :visible.sync="dialogVisible3"
        @close="closed"
      >
        <div class="queryMember">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-input
                v-model.trim="custName1"
                placeholder="请输入用户名称"
              ></el-input
            ></el-col>
            <el-col :span="8">
              <el-button type="primary" @click="queryMember">查询</el-button>
              <el-button @click="refMember">刷新</el-button>
            </el-col>
          </el-row>
        </div>
        <el-table
          :data="allmemberList"
          border
          style="width: 100%"
          class="memberTable"
        >
          <el-table-column header-align="center" align="center" width="100">
            <template scope="scope">
              <el-radio
                :label="scope.row.user_ID"
                v-model="radioMember"
                @change.native="getCurrentRow(scope.row)"
              ></el-radio>
            </template>
          </el-table-column>

          <el-table-column
            property="name"
            label="用户名称"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="联系方式"
            width="180"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.phone ? scope.row.phone : scope.row.username
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            property="createTime"
            label="注册时间"
            width="200"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination
            @size-change="handleSizeChange4"
            @current-change="handleCurrentChange4"
            :current-page="pageNumber3"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageSize3"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total4"
          >
          </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelChange">取 消</el-button>
          <el-button type="primary" @click="sureChange">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import pages from "../../components/pages/pagniation";
// import  handleClose from "element-ui/lib/index";

import api from "../../api/invoice/invoice";
import apiWab from "../../api/webAdmin/webAdmin";
import vueQr from "vue-qr";
import OrderDetails from "../OrderDetails/OrderDetails";
import FeeDetails from "../OrderDetails/feeDetails";
import picUrl from "../../api/picture";
import Editor from "../../components/wangeditor/index";
import md5 from "js-md5";
import increaseList from "@/components/personal/increaseList";
import ObsClient from "../../static/js/esdk-obs-browserjs-3.19.9.min";
import apiList from "@/api/invoice/invoice";

var obsClient; // obs实例

export default {
  name: "order",
  // components: {
  //   pages,
  // },
  components: {
    vueQr,
    OrderDetails,
    FeeDetails,
    Editor,
    increaseList,
  },
  data() {
    return {
      uploadFileVisible: false,
      reverse: true,
      routes: [],
      logisticsdialogVisible:false,
      radioMember: "",
      dialogVisible3: false, // 客户弹框
      allmemberList: [], // 弹框客户列表
      pageNumber3: 1,
      pageSize3: 5,
      total4: 0,
      custName1: "", // 客户弹窗筛选
      customerID: "", //筛选条件客服id
      orderUserId: "", // 筛选条件的客户id
      orderuserName: "", // 客户名称
      isPayment: "", // 是否为账期 1 是
      payLoading: false, // 支付loading
      allTotalMoney: 0, // 当前页 订单总金额
      whetherFullPayment: "",
      activities: [], // 订单进度追踪
      showSuer: false, // 支付按钮
      option2: [
        {
          value: "0",
          label: "尚未审核",
        },
        {
          value: "1",
          label: "审核通过",
        },
        {
          value: "2",
          label: "审核拒绝",
        },
        {
          value: "3",
          label: "已开发票",
        },
      ],
      querys: "", // 订单号
      value2: "", // 发票状态
      currentPage1: 1,
      pageSize1: 10,
      total1: 0,
      invoiceData: [], // 开票记录表
      // discountfeeTotal: "", // 订单总金额
      picUrls: "", // 通用网络地址
      fileConfig: false,
      payInfo: {
        // 支付参数
        orderId: "",
        userId: "",
        userfatherid: "",
        paymentProportion: "",
        haveOtherPayment: "",
        paymentChooses: "",
        haveOtherPaymentChooses: "",
      },
      paySuccess: false, // 微信、支付宝 支付成功
      orderMoney: 0, //(实际订单金额)
      orderPay: 0, // 订单实付金额
      credit: 0, //信用额度
      wallet: 0, // 钱包余额
      showPayType: false, //展示支付方式
      showPsBtn: false, //展示引导按钮
      showText: "", //展示文本
      img: "", // 二维码图片
      disabled1: true, //钱包
      disabled2: true, //其他
      disabled3: true, //信用
      checkList: [], // 复选框
      radio: "", // 单选
      input: "", //支付密码
      changeType: "0", // 是否余额抵扣(0:否，1是)
      payPop: false, // 支付弹窗打开
      fileList: [], // 上传文件列表
      rechargeFrom: {
        ordeRnumber: "",
        type: "",
        userId: "",
        orderId: ""
      },
      fileData: [
        // {
        //   createTime: "2021-06-03",
        //   orderFileName: "dafd.png",
        //   orderFileStruts: "1",
        // },
      ], // 文件上传列表
      startTime1: "",
      endTime1: "", // 文件上传时间
      orderInfo: {}, // 订单详情
      billdInfo: {}, //发票信息
      billing: false, // 发票详情弹框
      // orderType: "", // 订单生产状态筛选
      orderPayType: "", // 订单支付状态筛选
      options1: [
        // 订单支付状态
        {
          value: "3",
          label: "待支付",
        },
        {
          value: "5",
          label: "已支付",
        },
      ],
      options2: [
        // 订单支付状态
        {
          value: "0",
          label: "全款",
        },
        {
          value: "1",
          label: "30%预付款",
        },
        {
          value: "2",
          label: "线下付款",
        },
      ],
      options: [
        // 客服
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      checked: 0, // 切换模块
      userInfo: [
        {
          id: 0,
          text: "订单查询",
        }
      ],
      // 充值金额
      dynamicValidateForm: {
        recharge: "",
      },
      rules: {
        recharge: [
          { required: true, message: "请输入充值金额", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
      },

      //开始时间
      starttime: "",
      //结束时间
      endtime: "",
      //产品ID
      cpid: "",

      activeName: "first",

      // 表格数据
      tableData: [],

      // tabbar切换下标
      index: "0",

      //上传文件
      dialogVisible: false,

      //进度追踪
      speed: false,
      userId: "",

      detail: false, // 订单详情弹窗打开
      feedetail: false,
      title: "", //订单详情弹窗打开标题
      // OrderDetails:'',//订单详情
      timer: "",
      phone: "", // 当前用户手机号
      selectList: [],
      complaints: false, // 投诉弹窗打开
      complaintsList: [], //投诉列表
      //投诉分类
      ComplaintClassification: [
        { name: "质量", id: 0 },
        { name: "数量", id: 1 },
        { name: "交期", id: 2 },
        { name: "物流", id: 3 },
        { name: "包装", id: 4 },
        { name: "服务", id: 5 },
        { name: "印前", id: 6 },
        { name: "其他", id: 7 },
      ],
      //投诉内容
      form: {
        text: "",
        types: [],
      },
      rule: {
        types: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个投诉分类",
            trigger: "change",
          },
        ],
        text: [{ required: true, message: "请填写投诉内容", trigger: "blur" }],
      },

      obsAccessKeyId: "", //key   初始化使用
      obsBucketName: "", //桶名称
      obsFileDir: "", //文件夹
      obsSecretAccessKey: "", //秘钥   初始化使用
      obsServer: "", //服务器地址  初始化使用
      posterURL: "", //上传的文件名
      userType: 0, // 当前账户角色
      payPass: false, // 支付密码是否校验成功
      checks: false, //有支付密码
      showMessage: true,
      newPwdReadOnly: false,
      threePay: false, //余额加信用不足

      //上传文件第几页
      currentPagefile: 1,
      //上传文件每页条数
      pageSizefile: 10,
      //上传文件总条数
      filetotal: 0,
      //上传文件_时间戳
      Filename: "",
      //上传文件_加载遮罩层
      loading: "",
      //浏览地址
      BrowseUrl: "",
      offlineOrderMoney: "",
      upStre: 1,
      customerName: "",
      orderId: ""
    };
  },
  watch: {
    radio(v) {
      console.log("监听单选", v);
      this.checkList = [];
      // this.checkGroup();
      // this.input = "";
      if (this.radio === "0") {
        console.log("执行了这里");
        this.disabled1 = this.disabled2 = this.disabled3 = true;
        this.showSuer = true;
      } else if (v === "3") {
        this.disabled1 = this.disabled2 = this.disabled3 = true;
        this.showSuer = false;
        this.offlineOrderMoney = this.orderMoney * this.upStre;
      } else if (v === "1") {
        this.orderPay = this.orderMoney * 0.3;
        this.disabled1 = this.disabled2 = this.disabled3 = false;
      } else if (v === "2") {
        this.orderPay = this.orderMoney;
        this.disabled1 = this.disabled2 = this.disabled3 = false;
      }
      if (this.wallet <= 0) {
        this.disabled1 = true;
      }
      if (this.credit <= 0) {
        this.disabled3 = true;
      }
    },
    checkList(v) {
      console.log("复选框变化", v);
      if (v.length > 0) {
        let other = v.some((el) => el === "2");
        let pay = v.some((el) => el === "3");
        let balance = v.some((el) => el === "1");
        console.log("存在其他", other);
        if (other && v.length > 1) {
          this.img = "";
          this.showText = 0;
          this.showPsBtn = false;
          this.showSuer = false;
        }
        if (balance) {
          // 余额
          if (this.wallet >= this.orderPay) {
            this.disabled2 = true;
            let index = v.findIndex((el) => el === "2");
            console.log("获取下标", index);
            if (index !== -1) {
              this.checkList.splice(index, 1);
            }
            if (this.userType !== 0) {
              // 代客支付 不需要支付验证
              this.changeType = "0";
              this.showSuer = false;
            }
          } else {
            if (v.length === 1) {
              this.$message.warning("当前余额不足,请配合其他方式支付！");
              this.showSuer = true;
            }
          }
          if (this.credit <= 0) {
            this.disabled3 = true;
          }
        }
        if (pay) {
          // 信用
          if (this.credit >= this.orderPay) {
            this.disabled2 = true;
            let index = v.findIndex((el) => el === "2");
            console.log("获取下标", index);
            if (index !== -1) {
              this.checkList.splice(index, 1);
            }
          } else {
            if (v.length === 1) {
              this.$message.warning("当前信用额度不足,请配合其他方式支付！");
              this.showSuer = true;
            }
          }
        } else {
          console.log("执行了else");
          this.disabled1 = this.disabled2 = false;
          this.changeType = "0";
          if (this.wallet <= 0) {
            this.disabled1 = true;
          }
        }
        if (pay && balance) {
          if (this.wallet + this.credit >= this.orderPay) {
            this.disabled2 = true;
            let index = v.findIndex((el) => el === "2");
            console.log("获取下标", index);
            if (index !== -1) {
              this.checkList.splice(index, 1);
            }
          } else {
            this.disabled2 = false;
            this.showSuer = true;
            this.threePay = true;
          }
        } else {
          if (balance) {
            if (this.wallet >= this.orderPay) {
              this.disabled2 = true;
            } else {
              this.disabled2 = false;
              this.showSuer = true;
              if (this.userType !== 0) {
                // 代客支付 不需要支付验证
                this.changeType = "0";
              }
            }
          }
          if (pay) {
            if (this.credit >= this.orderPay) {
              this.disabled2 = true;
            } else {
              this.disabled2 = false;
              this.showSuer = true;
            }
          }
        }
        if (pay || balance) {
          if (this.userType !== 0) {
            // 代客支付 不需要支付验证
            this.changeType = "0";
          } else {
            this.changeType = "1";
          }
        } else {
          this.changeType = "0";
        }
        if (other) {
          this.showPayType = true;
          if (this.changeType === "1") {
            if (this.payPass) {
              // 支付密码正确
              this.showSuer = false;
            }
          } else {
            this.showSuer = false;
          }
        } else {
          this.showPayType = false; //隐藏付款方式
          this.showPsBtn = false; // 隐藏引导按钮
          this.img = "";
          this.showText = "";
        }
      } else {
        // if (this.radio && this.radio !== "3") {
        //   this.disabled1 = this.disabled2 = this.disabled3 = false;
        // }
        console.log("复选框为0");
        if (this.wallet <= 0) {
          this.disabled1 = true;
        }
        if (this.credit <= 0) {
          this.disabled3 = true;
        }
        if (this.radio !== "3" && this.radio !== "0") {
          console.log("执行了这里");
          this.disabled2 = false;
          this.showSuer = true;
        }
        this.showPayType = false; //隐藏付款方式
        this.showPsBtn = false; // 隐藏引导按钮
        this.img = "";
        this.showText = "";
        this.changeType = "0";
      }
    },
    changeType(v) {
      if (v === "1") {
        this.checks = true;
        if (this.payPass) {
          this.showSuer = true;
        }
      } else {
        this.checks = false;
      }
    },
    input(v) {
      if (this.changeType === "1") {
        if (v === "") {
          this.newPwdReadOnly = true;
          this.newPwdFocus(null);
        }
      }
    },
  },
  created() {
    this.picUrls = picUrl.picUrl;
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    // console.log("获取用户信息", obj);
    if (obj) {
      this.userId = obj.user_ID;
      this.phone = obj.phone;
      this.userType = obj.userType;
      // 管理员展示不同文案
      if (obj.userType === 0) {
        this.userInfo.push(
            {
              id: 2,
              text: "发票申请",
            })
      }
    } else {
      return;
    }
    //上传文件日期_默认当前日期
    // let year = new Date().getFullYear(); //得到年份
    // let month = new Date().getMonth(); //得到月份
    // let date = new Date().getDate(); //得到日期
    // month = month + 1;
    // if (month < 10) month = "0" + month;
    // if (date < 10) date = "0" + date;
    // this.endTime1 = year + "-" + month + "-" + date;

    this.$route.params.jump;
    console.log(this.$route.params.jump);
    console.log(this.$route.params.jumptext);
    let jumptext = this.$route.params.jumptext;
    if (jumptext) {
      this.orderType = this.$route.params.jumptext;
    }
    this.getService();
    this.init();
    this.getConfig();
    //this.getInvoiceConfig();
    // this.getUserInfo();
  },
  computed: {
    discountfeeTotal() {
      if (this.selectList.length > 0) {
        return this.selectList.reduce((pav, next) => {
          return pav + Number(next.discountfee);
        }, 0);
      } else {
        return 0;
      }
    },
  },
  filters: {
    numbers(value) {
      let relval = 0;
      if (!isNaN(value) && value !== "") {
        relval = parseFloat(value).toFixed(2);
      } else {
        relval = 0;
      }
      return relval;
    },
  },
  methods: {
    getConfig() {
      api
          .getConfig()
          .then((res) => {
            if (res.success) {
              this.fileConfig = res.data
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
    },
    // 删除订单
    delleteOrder(item) {
      console.log("订单详情", item, this.userId);
      // 当前登录人id和订单userid对比
      if (this.userId !== item.userid) {
        this.$message.warning("当前订单不是本人订单,阁下无权删除");
        return;
      }
      // 订单状态  payStruts = 3 可删除
      if (item.payStruts !== 3) {
        this.$message.warning("当前订单暂时无法删除");
        return;
      }
      this.$confirm("此操作将永久删除该订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.deleOrder(item.id).then((res) => {
            console.log("删除当前订单", res);
            if (res.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              // 刷新表格
              this.init();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    cancelChange() {
      this.radioMember = "";
      this.orderUserId = "";
      this.orderuserName = "";
      this.dialogVisible3 = false;
    },
    sureChange() {
      if (this.radioMember === "") {
        this.$message.warning("请选择客户！");
        return;
      }
      this.dialogVisible3 = false;
    },
    // 单选
    getCurrentRow(item) {
      console.log(item);
      this.orderUserId = item.customerId;
      this.orderuserName = item.name;
    },
    queryMember() {
      this.getCustomer();
    },
    refMember() {
      this.custName1 = "";
      this.pageNumber3 = 1;
      this.pageSize3 = 5;
      this.getCustomer();
    },
    // 弹窗关闭操作
    closed() {
      if (!this.dialogVisible3) {
        // this.dialogVisible3 = false;
        if (this.radioMember === "") {
          this.orderUserId = ""; // 选择客户id
          this.custName1 = ""; // 筛选条件清空
          this.orderuserName = ""; //选择客户名称
        }
      }
    },
    // 获取客户
    getCustomer() {
      apiWab
        .queryUser(
          this.pageNumber3,
          this.pageSize3,
          "",
          "",
          this.custName1,
          "0"
        )
        .then((res) => {
          console.log("获取客户", res);
          if (res.success) {
            // this.allList = res.data.userPageInfo.list;
            this.allmemberList = res.data.list;
            this.total4 = res.data.total;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 选择客户
    changeMember() {
      this.custName1 = "";
      this.dialogVisible3 = true;
      this.radioMember = "";
      this.getCustomer();
    },
    // 获取所有客服
    getService() {
      apiWab.getAllUserDontExistCustom().then((res) => {
        console.log("获取客服", res);
        if (res.success && res.code == 200) {
          res.data.userPageInfo.forEach((el) => {
            el.label = el.name;
            el.value = el.user_ID;
          });
          this.options = res.data.userPageInfo;
        } else {
          this.$message.error("获取客服失败");
        }
      });
    },
    // 判断生产状态
    typeFunc(v) {
      let text = "";
      if (v === "-1") {
        return (text = "待核价");
      } else if (v === "1") {
        return (text = "待审核");
      } else if (v === "2") {
        return (text = "待排产");
      } else if (v === "3") {
        return (text = "生产中");
      } else if (v === "4") {
        if (this.userType === 0) {
          return (text = "生产完成");
        } else {
          return (text = "待入库");
        }
      } else if (v === "5") {
        if (this.userType === 0) {
          return (text = "待发货");
        } else {
          return (text = "已入库");
        }
      } else if (v === "6") {
        if (this.userType === 0) {
          return (text = "已发货");
        } else {
          return (text = "待签收");
        }
      } else if (v === "7") {
        return (text = "订单取消");
      } else if (v === "8") {
        return (text = "订单完成(已评价)");
      } else if (v === "9") {
        return (text = "待印前制作");
      } else if (v === "10") {
        return (text = "待客确认");
      } else if (v === "11") {
        return (text = "已收货");
      } else {
        return text;
      }
    },

    refresh() {
      this.whetherFullPayment = this.starttime = this.endtime = this.orderPayType =
        "";
      this.customerID = "";
      this.orderUserId = "";
      this.orderuserName = "";
      this.init();
    },
    // 关闭支付弹窗
    closeDlg() {
      this.input = "";
      this.orderPay = 0;
      this.stopPass();
    },
    wacthChange(value) {
      if (value.length === 6) {
        this.$refs.inputPs.blur();
      }
    },
    // 阻止密码框自动填充
    stopPass() {
      console.log("调用了回调");
      if (this.$refs.inputPs) {
        console.log("执行了判断");
        this.$refs.inputPs.$refs.input.onmousedown = (evt) => {
          console.log("执行了点击", evt);
          if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
          }
          if (this.input === "" || this.newPwdReadOnly) {
            this.$refs.inputPs.$refs.input.blur();
            setTimeout(() => {
              this.$refs.inputPs.$refs.input.focus();
            }, 0);
          }
          return false;
        };
      }
    },
    newPwdFocus(evt, isNew = true) {
      console.log("获取焦点");
      if (evt) {
        evt.stopPropagation();
        evt.preventDefault();
      }
      setTimeout(() => {
        if (isNew) {
          this.newPwdReadOnly = false;
        } else {
          this.rePwdReadOnly = false;
        }
      }, 100);
    },
    // 失去焦点触发事件（支付密码）
    blurs(evt, isNew = true) {
      if (evt) {
        evt.stopPropagation();
      }
      if (isNew) {
        this.newPwdReadOnly = true;
      } else {
        this.rePwdReadOnly = true;
      }
      if (this.threePay) {
        this.$message.warning("当前金额不足,请配合其他方式支付！");
        return;
      }
      // console.log(event);
      if (this.changeType === "1") {
        if (this.input === "") {
          this.$message.error(
            "请输入支付密码!如若未设置或者忘记，请到费用中心设置支付密码!"
          );
          this.showSuer = true; // 支付弹窗按钮禁用
          this.payPass = false;
          return;
        } else {
          // let ps = md5(this.input);
          let data = {
            userName: this.userId,
            pwd: md5(this.input),
          };
          api
            .checkPayPassWord(data)
            .then((res) => {
              if (res.success) {
                this.showSuer = false;
                this.payPass = true;
              } else {
                this.$message.error(res.message);
                this.payPass = false;
                this.showSuer = true;
                this.showMessage = false;
              }
            })
            .catch((error) => {
              console.error("error", error);
              this.payPass = false;
              this.showSuer = true;
              this.showMessage = false;
            });
        }
      } else {
        this.showSuer = false;
      }
    },
    // 刷新开票记录
    refreshs() {
      this.value2 = this.querys = "";
      this.getInvoice();
    },
    // 获取开票记录
    getInvoice() {
      api
        .invoiceList(
          this.pageSize1,
          this.currentPage1,
          this.querys,
          this.value2
        )
        .then((res) => {
          console.log("获取用户开票列表", res);
          if (res.success) {
            this.invoiceData = res.data.list;
            this.total1 = res.data.total;
          }
        });
    },
    // 确认开发票
    sureBill() {
      if (this.selectList.length <= 0) {
        this.$message.warning("请选择订单列表前的多选框(仅限当前页可选订单)");
        return;
      }
      let len = this.selectList.length;
      let obj = this.selectList;
      let arr = [];
      for (let i = 0; i < len; i++) {
        let id = obj[i].id;
        arr.push(id);
      }
      if (arr.length === 1) {
        arr[0] = arr[0] + ",";
      }
      console.log("处理后的订单低集合", arr);
      let data = {
        userId: this.userId,
        orderId: arr.join(","),
      };
      api.applyKai(data).then((res) => {
        console.log("开票成功", res);
        if (res.success) {
          this.$message.success("开票成功");
          this.billing = false;
          this.init();
        } else {
          this.$message.error("开票失败");
        }
      });
      console.log(data);
    },
    // 多选框禁用
    selectEnable(row) {
      // console.log("禁用相关", row);
      // 未开发票，并且已经签收的订单
      if (row.orderIssueIsOpen === 0 && row.invoiceStatus === "1") {
        // if (row.orderIssueIsOpen === 0) {
        return true;
      } else {
        return false;
      }
    },
    moreSelect(selection, row) {
      console.log(selection, row);
    },
    changeSelect(selection) {
      console.log(selection);
      this.selectList = selection;
    },

    // 获取用户详情
    getUserInfo(id) {
      api
        .getUserMoneyAndCredits(1, id)
        .then((res) => {
          // console.log("获取用户详情", res);
          if (res.success) {
            this.credit = Number(res.data.credits); //信用额度
            this.wallet = Number(res.data.balance); // 钱包余额
            this.upStre = res.data.upRate ? Number(res.data.upRate) : 1;
            this.customerName = res.data.user.name;
            this.isPayment = res.data.user.isPayment;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 清除支付参数
    clearPayData() {
      this.payInfo = {
        orderId: "",
        userId: "",
        userfatherid: "",
        paymentProportion: "",
        haveOtherPayment: "",
        paymentChooses: "",
        haveOtherPaymentChooses: "",
      };
      this.payPop = false;
      this.img = "";
      this.showPsBtn = false;
      this.showText = 0;
      this.showPayType = false;
      this.changeType = "0";
      this.checkList = [];
      this.radio = "";
      this.input = "";
    },
    // 支付弹窗确定按钮(未使用扫码支付)
    comitPay() {
      this.payData();
      this.payLoading = true;
      if (this.showPayType) {
        if (this.img === "") {
          this.$message.warning("请选择支付方式，微信或支付宝");
          this.payLoading = false;
          return;
        }
        this.surePay(); // 确认是否支付成功
        if (this.paySuccess) {
          this.clearPayData();
        } else {
          // this.$message.warning("请确定是否扫码支付成功？");
          return;
        }
        console.log(this.payInfo);
      } else {
        if (this.changeType === "1") {
          if (this.payPass) {
            console.log("验证成功");
          } else {
            if (this.showMessage) {
              this.$message.warning("请输入支付密码！");
              return;
            }
            return;
          }
        }
        if (this.radio === "3") {
          // this.payInfo.paymentProportion = 1;
          this.payInfo.orderMoney = this.offlineOrderMoney;
        }
        console.log("支付参数", this.payInfo);
        api
          .orderpayinfo(this.payInfo)
          .then((res) => {
            this.payLoading = false;
            console.log("单独支付成功", res);
            if (res.success) {
              this.$message.success("支付成功");
              this.clearPayData();
              this.init();
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
        console.log(this.payInfo);
      }
      // this.payPop = false;
    },
    // 处理参数
    payData() {
      let info = this.orderInfo;
      let data = this.payInfo;
      data.orderId = info.id;
      data.userId = info.userid;
      data.userfatherid = info.userfatherid;
      // 付款方式
      if (this.radio === "1") {
        data.paymentProportion = 1; // 首付30%
      } else if (this.radio === "2") {
        data.paymentProportion = 2; // 全款支付
      } else if (this.radio === "3") {
        data.paymentProportion = 3; // 线下付款
        data.haveOtherPayment = "";
        data.paymentChooses = "";
        data.haveOtherPaymentChooses = "";
      }
      // 支付方式
      let arr = [...this.checkList];
      console.log(arr, data);
      if (arr.length === 1) {
        console.log("单选");
        if (arr[0] === "1") {
          // 余额支付
          data.paymentChooses = 1; // 余额
          data.haveOtherPayment = 1; // 不需要支付宝
        } else if (arr[0] === "2") {
          // 其他支付
          data.haveOtherPayment = 3; // 单独支付宝
          data.paymentChooses = "";
        } else {
          // 信用支付
          data.haveOtherPayment = 1; // 不需要支付宝
          data.paymentChooses = 2; // 信用
        }
      }
      if (arr.length === 2) {
        console.log("执行了多选");
        console.log(arr.join("."));
        let str = arr.join(".");
        if (str === "1.2" || str === "2.1") {
          console.log("余额加支付宝");
          data.haveOtherPayment = 2; // 需要支付宝
          data.paymentChooses = 1; // 余额
        } else if (str === "1.3" || str === "3.1") {
          console.log("二合一");
          data.haveOtherPayment = 1; // 不需要支付宝
          data.paymentChooses = 3;
        } else {
          console.log("信用加支付宝");
          data.haveOtherPayment = 2; // 需要支付宝
          data.paymentChooses = 2; // 信用
        }
      }
      if (arr.length === 3) {
        data.haveOtherPayment = 2; // 需要支付宝
        data.paymentChooses = 3; // 信用，余额二合一
      }
    },
    //确定支付成功(查询是否支付成功)
    surePay() {
      if (this.changeType === "1") {
        if (this.payPass) {
          // this.$message.success("支付密码正确");
        } else {
          this.$message.warning("支付密码验证未通过,请重新确认支付密码");
          return;
        }
      }
      api
        .queryPassType(this.rechargeFrom)
        .then((res) => {
          this.payLoading = false;
          // console.log("查询支付状态", res);
          if (res.success) {
            if (
              res.data.result === "TRADE_SUCCESS" ||
              res.data.result === "SUCCESS"
            ) {
              this.paySuccess = true;
              this.$message.success("支付成功");
              // this.stopPass();
              this.clearPayData();
              this.init();
            } else {
              this.$message.warning("未查询到支付成功，请确认支付是否成功");
            }
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 支付选择
    bills(str) {
      this.payData();
      if (this.checks) {
        if (this.payPass) {
          console.log("验证成功");
        } else {
          this.$message.warning("请先验证支付密码!");
          return;
        }
      }
      // 微信
      if (str === "wx") {
        console.log("微信支付");
        this.payInfo.haveOtherPaymentChooses = 2;
      } else {
        // 支付宝
        console.log("支付宝支付");
        this.payInfo.haveOtherPaymentChooses = 1;
      }
      console.log("调整后的参数数据", this.payInfo);
      api
        .orderpayinfo(this.payInfo)
        .then((res) => {
          console.log("调取获取二维码", res);
          if (res.success) {
            this.img = res.data.qrCode;
            this.showPsBtn = true;
            if (this.payInfo.haveOtherPaymentChooses === 2) {
              // 微信
              this.showText = 1;
              this.rechargeFrom = {
                ordeRnumber: res.data.otherPayOrderId,
                type: 2,
                userId: this.payInfo.userId,
                orderId: this.payInfo.orderId
              };
            } else {
              // 支付宝
              this.showText = 2;
              this.rechargeFrom = {
                ordeRnumber: res.data.otherPayOrderId,
                type: 1,
                userId: this.payInfo.userId,
                orderId: this.payInfo.orderId
              };
            }
            // console.log(this.rechargeFrom);
          } else {
            this.$message.error("获取支付二维码失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },
    // 复选框内容
    checkGroup(val) {
      console.log(val);
      // if (val) {
      //   let balance = val.some((el) => el === "1"); // 钱包抵扣
      //   let other = val.some((el) => el === "2"); // 其他
      //   let credit1 = val.some((el) => el === "3"); // 信用
      //   console.log("是否选择了余额抵扣", balance);
      //   console.log("是否选择了其他", other);
      //   console.log("是否选择了信用", credit1);
      //   // console.log("是否选择了组合支付", towPay);
      //   let sure = false; // 余额满足
      //   let sure1 = false; // 信用满足
      //   if (balance) {
      //     //选择了余额抵扣
      //     this.changeType = "1";
      //     if (this.wallet > this.orderPay) {
      //       sure = true;
      //     }
      //     if (sure) {
      //       console.log("执行了禁用", sure);

      //       this.disabled2 = this.disabled3 = true;
      //     }
      //   } else {
      //     sure = false;
      //     if (!sure) {
      //       this.disabled2 = this.disabled3 = false;
      //     }
      //     this.changeType = "0"; // 隐藏支付密码
      //   }
      //   if (other) {
      //     // 选择了其他
      //     this.showPayType = true;
      //   } else {
      //     this.showPayType = false; //隐藏付款方式
      //     this.showPsBtn = false; // 隐藏引导按钮
      //     this.img = "";
      //     this.showText = "";
      //   }
      //   if (credit1) {
      //     //选择了信用抵扣

      //     this.changeType = "1";
      //     if (this.credit > this.orderPay) {
      //       sure1 = true;
      //     }
      //     if (sure1) {
      //       this.disabled1 = this.disabled2 = true;
      //     }
      //   } else {
      //     if (sure) {
      //       this.changeType = "1"; // 显示支付密码
      //       this.disabled2 = this.disabled3 = true;
      //     } else {
      //       sure1 = false;
      //       if (!sure1) {
      //         this.disabled2 = this.disabled1 = false;
      //       }
      //     }
      //   }
      // } else {
      //   this.changeType = "0";
      //   this.showPayType = false;
      //   this.img = "";
      //   this.showPsBtn = false;
      // }
    },

    //点击文件列表中已上传的文件时的钩子
    handlePreview(file) {
      console.log(file);
    },

    //删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。
    befRemove(file, fileList) {
      console.log(file, fileList);
      if (file.status == "uploading") {
        this.$message({
          message: "文件正在上传中，请勿关闭",
          type: "warning",
        });
        return false;
      }
    },

    //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    handChange(file, fileList) {
      console.log(file, fileList);
      if (fileList.length > 1) {
        this.$message({
          message: "请选择单个文件进行上传",
          type: "warning",
        });
      }
      console.log(file.raw);
      //删除文件列表除最后一个文件
      fileList.splice(0, fileList.length - 1);
      console.log(file, URL.createObjectURL(file.raw));
      this.fileList = fileList;
      let fileName = file.name;
      // let regex = /(.jpg|.jpeg|.gif|.png|.bmp)$/;
      // if (regex.test(fileName.toLowerCase())) {
      this.posterURL = file.raw; // 获取URL
      console.log(this.posterURL);
      console.log(this.posterURL.name.match(/\.([^\.]+)$/)[0]);
      // } else {
      //   this.$message.error("请选择图片文件");
      // }
    },

    //文件上传时的钩子
    onProgress(event, file, fileList) {
      console.log(event, file, fileList);
      if (event.percent != 100) {
        this.loading = this.$loading({
          lock: true,
          text: "拼命上传中",
          spinner: "el-icon-loading",
          // background: 'rgba(0, 0, 0, 0.7)'
          background: "rgb(255, 255, 255, 0.5)",
          zIndex: "20004!important",
        });

        this.$nextTick(() => {
          let loadingNode = document.getElementsByClassName("el-loading-mask");
          console.log(loadingNode);
          for (let i = 0; i < loadingNode.length; i++) {
            loadingNode[i].style.zIndex = 20006;
          }
        });
      } else if (event.percent == 100) {
        this.loading.close();
      }
    },

    // 文件上传成功时的钩子_调用本地新增
    onSuccess(response, file, fileList) {
      console.log(response, file, fileList);
      if (fileList[0].status == "success") {
        // this.$refs.upload.submit();
        console.log("上传服务器成功");
        this.$refs.upload.clearFiles();

        let data = {
          //文件名
          orderFileName:
            this.Filename + this.posterURL.name.match(/\.([^\.]+)$/)[0],
          //服务器物理路径
          orderFileDir:
            this.obsFileDir +
            "/" +
            this.orderInfo.id +
            "/" +
            this.Filename +
            this.posterURL.name.match(/\.([^\.]+)$/)[0],
          //图片展示路径
          orderFilePath:
            this.obsFileDir +
            "/" +
            this.orderInfo.id +
            "/" +
            this.Filename +
            this.posterURL.name.match(/\.([^\.]+)$/)[0],
          //0：正在上传 1：已上传
          orderFileStruts: 1,
          //订单ID
          orderId: this.orderInfo.id,
        };
        console.log(data);
        //新增
        this.$http.post("/orderfileinfo", data).then((res) => {
          console.log("新增数据", res);
          if (res.data.code == 200) {
            this.$message({
              message: "文件上传成功",
              type: "success",
            });
            //获取文件列表分页
            this.getUpLoadFilesList();
            //获取订单列表
            this.init();
          }
        });
      }
    },

    //覆盖默认的上传行为，可以自定义上传的实现
    customUpload(file) {
      // let FormDatas = new FormData();
      console.log(file);
      // FormDatas.append('file', file.file);
      // console.log(FormDatas)

      // 创建ObsClient实例
      obsClient = new ObsClient({
        access_key_id: this.obsAccessKeyId,
        secret_access_key: this.obsSecretAccessKey,
        server: this.obsServer,
      });

      // obsClient.initLog({
      //     name: 'test',
      //     // file_full_path:'./logs/OBS-SDK.log',
      //     // file_full_path:'../../static/OBS-SDK.text',
      //     file_full_path: 'D:\\1.log',
      //     max_log_size: 10 * 1024 * 1024, //10MB
      //     backups: 10,
      //     level: 'debug',
      //     log_to_console: true
      // });

      this.Filename = Date.now(); //1603009495724,精确到毫秒，实际上是new Date().getTime()
      console.log(this.Filename);

      var cp;
      var hook;
      obsClient.uploadFile(
        {
          Bucket: this.obsBucketName,
          // Key : this.obsFileDir,
          Key:
            this.obsFileDir +
            "/" +
            this.orderInfo.id +
            "/" +
            this.Filename +
            this.posterURL.name.match(/\.([^\.]+)$/)[0],
          // SourceFile : document.getElementById('input-file').files[0],
          SourceFile: this.posterURL,
          PartSize: 9 * 1024 * 1024,
          //获取上传进度的回调函数。
          ProgressCallback: function (
            transferredAmount,
            totalAmount,
            totalSeconds
          ) {
            console.log((transferredAmount * 1.0) / totalSeconds / 1024);
            console.log(
              "进度" + (transferredAmount * 100.0) / totalAmount + "%"
            );
            //上传进度
            let num = (transferredAmount * 100.0) / totalAmount; //百分比
            file.onProgress({ percent: num }); //进度条
            // if(hook && (transferredAmount / totalAmount) > 0.5){
            //   // 暂停断点续传任务
            //   hook.cancel();
            // }
          },
          //获取上传事件的回调函数。
          EventCallback: function (eventType, eventParam, eventResult) {
            // 处理事件响应
          },
          //获取断点续传控制参数的回调函数。
          ResumeCallback: function (resumeHook, uploadCheckpoint) {
            // 获取取消断点续传上传任务控制参数
            hook = resumeHook;
            // 记录断点
            cp = uploadCheckpoint;
          },
        },
        function (err, result) {
          console.error("Error-->" + err);
          // 出现错误，再次调用断点续传接口，继续上传任务
          if (err) {
            obsClient.uploadFile(
              {
                //断点续传记录对象，可通过ResumeCallback回调函数获取到。
                UploadCheckpoint: cp,
                //获取上传进度的回调函数。
                ProgressCallback: function (
                  transferredAmount,
                  totalAmount,
                  totalSeconds
                ) {
                  console.log((transferredAmount * 1.0) / totalSeconds / 1024);
                  console.log(
                    "进度" + (transferredAmount * 100.0) / totalAmount + "%"
                  );
                  //上传进度
                  let num = (transferredAmount * 100.0) / totalAmount; //百分比
                  file.onProgress({ percent: num }); //进度条
                },
                //获取上传事件的回调函数。
                EventCallback: function (eventType, eventParam, eventResult) {
                  // 处理事件响应
                },
              },
              function (err, result) {
                if (err) {
                  console.error("Error-->" + err);
                } else {
                  if (result.CommonMsg.Status < 300) {
                    console.log(
                      "RequestId-->" + result.InterfaceResult.RequestId
                    );
                    console.log("Bucket-->" + result.InterfaceResult.Bucket);
                    console.log("Key-->" + result.InterfaceResult.Key);
                    console.log(
                      "Location-->" + result.InterfaceResult.Location
                    );
                  } else {
                    console.log("Code-->" + result.CommonMsg.Code);
                    console.log("Message-->" + result.CommonMsg.Message);
                  }
                }
              }
            );
          } else {
            console.log("Status-->" + result.CommonMsg.Status);

            //上传成功
            if (result.CommonMsg.Status == 200) {
              console.log("1111");
              console.log("2222");
              file.onSuccess(); //上传成功(打钩的小图标)
              this.$refs.upload.submit();
              this.$refs.upload.clearFiles();
            }
            if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
              console.log("RequestId-->" + result.InterfaceResult.RequestId);
            }
          }
        }
      );

      // this.$axios({
      //     url: 'http://192.168.1.5:8889/upload',
      //     method: 'post',
      //     data: FormDatas,
      //     //上传进度
      //     onUploadProgress: (progressEvent) => {
      //         let num = progressEvent.loaded / progressEvent.total * 100 | 0;  //百分比
      //         file.onProgress({percent: num})     //进度条
      //     }
      // }).then(data => {
      //     file.onSuccess(); //上传成功(打钩的小图标)
      // })
    },

    //文件上传所需内容_上传到服务器
    submitUpload() {
      // this.customUpload(file)
      console.log(this.fileList);
      if (this.fileList.length == 0) {
        this.$message({
          message: "请选择文件进行上传",
          type: "warning",
        });
      }
      console.log(this.orderInfo.userfatherid);
      this.$refs.upload.submit();
    },

    // 上传文件
    upLoad() {
      let data = {
        date: "2021-06-03",
        name: "dafd.png",
        type: "1",
      };
      this.fileData.push(data);
    },


    // 打开上传文件
    upLoadFile(item) {
      this.dialogVisible = true;
      console.log(item);
      if (item.status == 11) {
        document.getElementById("upload-demo").style.display = "none"; //先导航到要改的标签
      }
      this.orderInfo = item;

      //获取文件列表分页
      this.getUpLoadFilesList();

      //获取obs基础配置信息
      this.$http.get("/orderfileinfo/getBaseInfo").then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          //key   初始化使用
          this.obsAccessKeyId = res.data.data.obsAccessKeyId;
          //桶名称
          this.obsBucketName = res.data.data.obsBucketName;
          //文件夹
          this.obsFileDir = res.data.data.obsFileDir;
          //秘钥   初始化使用
          this.obsSecretAccessKey = res.data.data.obsSecretAccessKey;
          //服务器地址  初始化使用
          this.obsServer = res.data.data.obsServer;
        }
      });
    },

    logistics(item) {
      this.logisticsdialogVisible = true
      let data = {
        "language": "0",
        "trackingType": "1",
        "trackingNumber": [
          item.expressTrackingNumber
      ],
        "methodType": "1",
        "checkPhoneNo": item.contacttel.substring(item.contacttel.length -4,item.contacttel.length)
      }
      apiWab
          .querySfOrder(data).then((res) => {
           if(res.apiResultCode ==='A1000' &&  JSON.parse(res.apiResultData).errorCode ==='S0000') {
              this.routes = JSON.parse(res.apiResultData).msgData.routeResps[0].routes
            }
      });
    },

    // 获取文件列表分页
    getUpLoadFilesList() {
      this.$http
        .get(
          "/orderfileinfo?pageSize=" +
            this.pageSizefile +
            "&pageNumber=" +
            this.currentPagefile +
            "&orderId=" +
            this.orderInfo.id +
            "&startTime=" +
            this.startTime1 +
            "&endTime=" +
            this.endTime1
        )
        .then((res) => {
          console.log("获取文件列表分页", res);
          console.log(res.data.data.list);
          this.fileData = res.data.data.list;
          this.filetotal = res.data.data.total;
        });
    },

    //文件查询
    queryList() {
      //获取文件列表分页
      this.getUpLoadFilesList();
    },

    //删除
    delFile(row) {
      console.log(row);
      console.log(row.id);
      // 创建ObsClient实例
      var obsClient = new ObsClient({
        access_key_id: this.obsAccessKeyId,
        secret_access_key: this.obsSecretAccessKey,
        server: this.obsServer,
      });

      obsClient.deleteObject(
        {
          Bucket: this.obsBucketName,
          Key: row.orderFileDir,
        },
        function (err, result) {
          if (err) {
            console.log("Error-->" + err);
          } else {
            console.log("Status-->" + result.CommonMsg.Status);
          }
        }
      );

      // this.$http.get("/orderfileinfo/"+row.id).then((res) => {
      this.$http.delete(`/orderfileinfo/${row.id}`).then((res) => {
        console.log("删除", res);
        if (res.data.code == 200) {
          //获取订单列表
          this.init();
          //获取文件列表分页
          this.getUpLoadFilesList();
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },

    //浏览
    browse(row) {
      console.log(row);
      this.BrowseUrl = picUrl.BrowseUrl + row.orderFileDir;
    },

    // 支付相关
    paySet(item) {
      this.radio = "0";
      console.log(item);
      this.orderInfo = item;
      this.orderMoney = Number(item.discountfee);
      this.payPop = true;
      this.getUserInfo(item.id);
      // this.payData();
    },
    // 订单详情
    details(item) {
      this.timer = new Date().getTime();
      this.orderInfo = item;
      // let level = "";
      // this.$nextTick(() => {
      //   level = this.$refs.orderDetails.returnLevel();
      //   console.log("获取会员级别", level);
      // });
      this.title = `订单【${this.orderInfo.id}】详情`;
      this.detail = true;
    },
    feeDetails(item){
      this.timer = new Date().getTime();
      this.orderInfo = item;
      this.title = `订单【${item.id}】报价明细`;
      this.feedetail = true;

    },
    //投诉
    complaint(item) {
      console.log(item);
      this.complaints = true;
      this.complaintsList = item;
    },

    //投诉分类
    CheckedChange(value) {
      console.log(value);
    },

    //立即投诉
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          console.log(this.form);
          let array = "";
          for (let a = 0; a < this.form.types.length; a++) {
            array = array + this.form.types[a].id + ",";
          }
          // 去掉最后一个逗号
          if (array.length != 0) {
            array = array.substring(0, array.length - 1);
          }
          console.log(array);

          let userInfo = JSON.parse(
            window.sessionStorage.getItem("currentInfo")
          );
          console.log(userInfo);
          let datas = new FormData();
          //订单ID
          datas.append("orderNumber", this.complaintsList.id);
          //用户ID
          datas.append("userId", userInfo.user_ID);
          //投诉类型
          datas.append("complaintsType", array);
          //投诉内容
          datas.append("complaintsContext", this.form.text);
          //投诉内容
          // datas.append("orderImprove", this.form.text);

          console.log(datas);
          this.$http.post("/ordercomplaints", datas).then((res) => {
            console.log("投诉", res);
            if (res.data.code == 200) {
              this.$message({
                message: "投诉成功",
                type: "success",
              });
              this.complaints = false;
              this.init();
              this.$emit("jumpNav");
            } else {
              this.$message.error("投诉失败");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //投诉重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    //富文本内容变化
    change(val) {
      console.log(val);
    },

    // 表格自定义样式调整
    tableRowStyle() {
      return "padding:0;";
    },
    //获取订单列表
    init() {
      this.checked = 0;
      api
        .queryOrder(
          this.starttime,
          this.endtime,
          this.customerID,
          this.pageSize,
          this.currentPage,
          this.orderUserId,
          this.orderPayType,
          this.orderId,
          this.whetherFullPayment
        )
        .then((res) => {
          console.log("获取订单列表", res);
          if (res.success) {
            this.total = res.data.pageInfo.total;
            let data = res.data.pageInfo.list;
            // data.forEach((el) => {
            //   if (el.whetherFullPayment === 1) {
            //     el.surplusMoney = (Number(el.discountfee) * 0.7).toFixed(2);
            //   }
            // });
            // let mapList = data.map(el=>{

            // })
            this.tableData = data;
            this.allTotalMoney = Number(res.data.resultMoney).toFixed(2);
          } else {
            this.$message.error("获取订单列表失败");
          }
        })
        .catch((error) => {
          console.error("error", error);
        });
    },

    // 进度追踪
    track(item) {
      console.log(item);
      this.speed = true;
      api.oplogger(item.id).then((res) => {
        console.log("订单流程图", res);
        if (res.success) {
          if (res.data.length > 0) {
            res.data.forEach((el) => {
              el.color = "#0bbd87";
            });
          }
          this.activities = res.data;
        } else {
          this.$message.error("获取订单流程失败");
        }
      });
    },
    // 发票申请
    applyInvoice() {
      // console.log(item);
      // this.orderInfo = item;
      if (this.userType !== 0) {
        this.$message.warning("当前账号非客户账号,无法申请开发票！");
        return;
      }
      // api.getinvoiceList(this.userId).then((res) => {
      //   console.log("获取发票列表", res);
      //   if (res.success) {
      //     if (res.data.length > 0) {
      //       if (this.selectList.length <= 0) {
      //         this.$message.warning(
      //           "请选择当前分页可开票订单！(未开票且已支付的订单)"
      //         );
      //         return;
      //       }
      //       console.log(res.data[0]);
      //       this.billdInfo = res.data[0];
      //       this.billing = true;
      //     } else {
      //       this.$confirm(
      //         "当前账号尚未填写发票信息，请前往费用中心填写发票资料！",
      //         "发票信息完善",
      //         {
      //           confirmButtonText: "确定",
      //           cancelButtonText: "取消",
      //           type: "warning",
      //         }
      //       )
      //         .then(() => {})
      //         .catch(() => {});
      //     }
      //   }
      // });
    },
    // 切换列表
    replace(item) {
      this.checked = item.id;
      console.log(item);
      if (item.id === 0) {
        this.init();
      }
      // if (item.id === 1) {
      //   this.getInvoice();
      // } else {
      //   this.init();
      // }
    },
    // 查询
    query() {
      //   if (
      //     this.starttime === "" &&
      //     this.endtime === "" &&
      //     this.cpid === "" &&
      //     this.orderType === "" &&
      //     this.orderPayType === ""
      //   ) {
      //     this.$message.warning("请输入查询参数");
      //     return;
      //   }
      this.init();
    },
    // 选择客户分页
    handleSizeChange4(val) {
      this.pageSize3 = val;
      this.getCustomer();
    },
    handleCurrentChange4(val) {
      this.pageNumber3 = val;
      this.getCustomer();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.init();
    },
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.getInvoice();
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.getInvoice();
    },
    handleSizefileChange(val) {
      this.pageSizefile = val;
      this.getUpLoadFilesList();
    },
    handleCurrentfileChange(val) {
      this.currentPagefile = val;
      this.getUpLoadFilesList();
    },
    //tabbar切换
    handleClick(tab, event) {
      console.log(tab, event);
      console.log(tab.index);
      this.index = tab.index;
    },
  },

  // mounted() {
  //     this.$nextTick(() => {
  //         let loadingNode = document.getElementsByClassName('el-loading-mask')
  //         console.log(loadingNode)
  //         for (let i = 0; i < loadingNode.length; i++) {
  //             loadingNode[i].style.zIndex = 20006
  //         }
  //     })
  // },
};
</script>

<style lang="scss" scoped>
.personalInfo {
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .card-left {
    width: 200px;
    height: 320px;

    .top {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      background-color: #047bd7;
      color: #fff;
      font-size: 24px;

      i {
        font-size: 28px;
        margin-right: 10px;
      }
    }

    .infos {
      width: 100%;
      box-sizing: border-box;
      // border: 1px solid #e6e6e6;
      // border-top: none;
      li {
        box-sizing: border-box;
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px dashed #e6e6e6;
        cursor: pointer;
      }

      li:last-child {
        border-bottom: none;
      }

      .checked {
        background-color: #058af2;
        color: #fff;
        font-weight: 600;
      }
    }
  }

  .card-right {
    width: 980px;
    background-color: #fff;
  }

  .info {
    width: 100%;
    box-sizing: border-box;
    // padding: 15px;
  }

  .card {
    width: 100%;

    .screen {
      margin-top: 10px;

      .colls {
        display: flex;
        line-height: 40px;
        margin: 15px 0;

        span {
          margin-right: 5px;
          width: 100px;
        }

        .kuoz {
          margin: 0 10px;
        }
      }
      .moneyTotal {
        line-height: 40px;
        margin-top: 15px;
      }

      .coll {
        display: flex;
        justify-content: space-around;
        .ipt {
          width: 25%;
        }
      }
      .btn {
        margin-left: 20px;
      }
    }

    .card-top {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #e6e6e6;
      padding: 0 30px;
      line-height: 50px;

      span {
        display: inline-block;
        width: 5px;
        height: 17px;
        background: #058af2;
        margin-right: 20px;
      }

      h3 {
        color: #058af2;
      }
    }

    .list {
      width: 100%;
      box-sizing: border-box;
      padding: 5px 30px;

      .list-top {
        display: flex;
        justify-content: space-between;

        span {
          color: #058af2;
        }

        span:last-child:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .list-item {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .items {
          margin-top: 10px;
          width: 255px;
          height: 155px;
          background: transparent url("../../static/imgs/beijing1.png")
            no-repeat scroll 0% 0%;
          position: relative;

          .border {
            box-sizing: border-box;
            padding: 15px;
            font-size: 14px;
            font-weight: 400;

            p {
              padding: 3px 0 5px 0;

              border-bottom: 1px solid #e6e6e6;
            }

            i {
              display: inline-block;
              margin-right: 10px;
            }

            p:nth-child(2) {
              height: 65px;
              display: flex;
              align-items: center;

              span {
                display: block;
                overflow: hidden;
                white-space: normal;
                text-overflow: ellipsis;
              }
            }

            p:last-child {
              border-bottom: none;
            }
          }

          .top {
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            color: #fff;

            .top-df {
              display: none;
              background: #fbb244 none repeat scroll 0% 0%;
              padding: 0px 10px;
              border-radius: 0px 0px 5px 5px;
            }
          }

          .active {
            display: block;
            padding: 0 10px;
            background: #058af2 none repeat scroll 0% 0%;
          }

          .top:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          .bottom {
            display: none;
            position: absolute;
            right: 20px;
            bottom: 8px;
            cursor: pointer;
            font-size: 14px;

            p {
              float: left;
              border: 1px solid #d4d4d4;
              margin-left: 5px;
              height: 25px;
              line-height: 25px;
              width: 40px;
              text-align: center;
              border-radius: 3px;
              background-color: #fff;
            }

            p:hover {
              color: #058af2;
            }
          }
        }

        .items-bgd {
          background: transparent url("../../static/imgs/beijing2.png")
            no-repeat scroll 0% 0%;
        }

        .items:hover .top-df {
          display: block;
        }

        .items:hover .bottom {
          display: block;
        }

        .items:nth-last-child(-n + 1) {
          height: 0;
          background: none;
        }
      }
    }
  }

  /* 查询条件 */
  .query {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .input {
      width: 30%;
      margin: 0 10px;
    }

    .btn {
      margin-left: 10px;
    }
  }

  /* 开票记录 */
  .invoices {
    .pages {
      margin: 10px;
    }
  }
  .invoiceListClass {
    padding: 10px;
  }

  /*账户充值*/
  .recharge {
    .card-form {
      width: 50%;
      margin: 30px auto;
      /*账户充值_金额数目*/
      .money {
        color: red;
        padding: 0 5px;
        font-weight: bold;
      }
    }

    /*付款方式*/
    .payment {
      width: 95%;
      border-top: 1px dashed #e6e6e6;

      .text {
        font-size: 14px;
        width: 50%;
        margin: 20px auto 20px;
      }

      /*付款平台*/
      .pingtai {
        overflow: hidden;

        ul li {
          float: left;
          display: inline;
          position: relative;
          width: 177px;
          height: 50px;
          cursor: pointer;
          border: 1px solid #d2d1d1;
          margin-right: 18px;
          /*微信支付*/
          .wx {
            background: url(../../static/imgs/sprites-payment-logo.png)
              no-repeat;
            background-position: 0 -1880px;
            display: inline-block;
            width: 175px;
            height: 37px;
            vertical-align: middle;
            position: absolute;
            top: 7px;
            *top: 5px;
            left: 1px;
            z-index: 2;
          }

          /*支付宝支付*/
          .zfb {
            background: url(../../static/imgs/sprites-payment-logo.png)
              no-repeat;
            background-position: 0 -120px;
            display: inline-block;
            width: 175px;
            height: 37px;
            vertical-align: middle;
            position: absolute;
            top: 7px;
            *top: 5px;
            left: 1px;
            z-index: 2;
          }
        }
      }
    }
  }

  /*上传文件*/
  .bill {
    /*筛选条件*/
    .card-form {
      display: flex;
      line-height: 40px;
      margin: 20px auto;
      margin-right: 20px;
      justify-content: flex-end;

      div {
        padding: 0 5px;
      }
    }

    /*表格*/
    .table {
      margin-right: 0;
      border-top: 1px dashed #e6e6e6;

      table {
        width: 100%;
        border-collapse: collapse; //去除td之间的空隙

        tr {
          th:first-of-type {
            width: 40%;
          }

          td {
            font-size: 13px;

            .grey {
              color: #b4b4b4;
              margin-right: 5px;
            }

            .bottom {
              height: 90px;
              padding-top: 10px;
            }

            .bottom > p {
              line-height: initial;
              margin: 5px 0;
            }

            /*图文部分*/
            .picture {
              display: flex;
              font-size: 13px;
              line-height: initial;

              img {
                margin-left: 15px;
                margin-bottom: 10px;
                width: 80px;
                height: 80px;
              }

              .text {
                margin-left: 15px;
                margin-top: 0;
                margin-bottom: 0;
                display: grid;

                p {
                  color: #999;

                  a {
                    color: #1d94d1;
                    text-decoration: none;
                  }

                  .c1 {
                    color: #e56315;
                  }
                }
              }
            }
          }

          /*订单状态/进度追踪*/
          .zhuangtai,
          .jindu {
            text-align: center;

            i {
              font-weight: bold;
            }
          }
        }

        .list {
          border: 1px solid #f8f8f8;

          td > div:first-of-type {
            background-color: #f8f8f8;
          }
        }
      }
    }

    /*分页*/
    .pages {
      display: flex;
      margin: 20px 20px;
      justify-content: space-between;
    }
  }

  /*发票申请*/
  /*.invoice{*/
  /*    !*.card-top{*!*/
  /*        a{*/
  /*            font-size: 14px;*/
  /*            color: #1D94D1;*/
  /*            cursor: pointer;*/
  /*            text-decoration: none;*/
  /*        }*/
  /*    !*}*!*/
  /*}*/
}
.dialogs {
  width: 100%;
  .queryMember {
    width: 100%;
    margin-bottom: 10px;
  }
  .memberTable {
    ::v-deep .el-radio__label {
      display: none;
    }
  }
}
.tables {
  ::v-deep .el-table .cell {
    padding: 0;
  }

  ::v-deep .el-table .cell,
  ::v-deep .el-table--border td:first-child .cell {
    padding: 0;
  }

  ::v-deep .el-table_1_column_1 {
    padding: 0;
  }

  ::v-deep .el-table td {
    padding: 0;
  }

  .top {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #f8f8f8;
    color: #b4b4b4;

    span {
      color: #000000;
    }
  }

  .box {
    padding: 5px 10px;
    height: 102px;
    display: flex;
    justify-content: space-around;
    .img {
      margin: 4px 15px 0;
      width: 80px;
      height: 80px;

      img {
        display: inline-block;
        width: 80px;
        height: 100%;
      }
    }
    .info {
      width: 35%;
      .p1 {
        color: #409eff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .p3 {
        span {
          color: #ff0000;
        }
      }
    }
    .rights {
      width: 35%;
      .p3 {
        span {
          color: #ff0000;
        }
      }
    }
  }

  .types {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      padding: 10px 0;
    }

    .btn {
      width: 50%;
    }
  }

  .boxInfo {
    display: flex;
    justify-content: center;
    align-items: center;

    ::v-deep .el-button {
      height: 40px;
    }
  }
}

.content {
  .orderInfo {
    margin-bottom: 10px;

    p {
      text-indent: 2em;
    }
  }
}

.upFile {
  text-align: center;

  .top {
    margin-bottom: 10px;

    .date {
      margin-top: 10px;

      .btn {
        margin-left: 10px;
      }
    }
  }

  .tableList {
    margin-bottom: 10px;

    .success {
      color: #058af2;
    }

    .error {
      color: #ff0000;
    }
  }

  .noTable {
    text-align: center;
    color: #66b1ff;
    margin: 15px;
  }

  .upBtn {
    margin-top: 15px;
  }
}

.payContent {
  /*text-align: center;*/

  .orderInfo {
    margin: 10px 0;
  }

  .top {
    margin: 10px 0;

    .check-group {
      display: flex;
      flex-direction: column;
      line-height: 30px;
    }
  }

  .ipt {
    height: 40px;
    line-height: 40px;
    display: flex;
    margin: 15px 0px;

    .inputs {
      width: 200px;
    }
  }

  .payTypes {
    /*付款平台*/
    .pingtai {
      overflow: hidden;
      margin-left: 125px;

      ul li {
        float: left;
        display: inline;
        position: relative;
        width: 177px;
        height: 50px;
        cursor: pointer;
        border: 1px solid #d2d1d1;
        margin-right: 18px;
        /*微信支付*/
        .wx {
          background: url(../../static/imgs/sprites-payment-logo.png) no-repeat;
          background-position: 0 -1880px;
          display: inline-block;
          width: 175px;
          height: 37px;
          vertical-align: middle;
          position: absolute;
          top: 7px;
          *top: 5px;
          left: 1px;
          z-index: 2;
        }

        /*支付宝支付*/
        .zfb {
          background: url(../../static/imgs/sprites-payment-logo.png) no-repeat;
          background-position: 0 -120px;
          display: inline-block;
          width: 175px;
          height: 37px;
          vertical-align: middle;
          position: absolute;
          top: 7px;
          *top: 5px;
          left: 1px;
          z-index: 2;
        }
      }
    }

    .imgs {
      display: flex;
      justify-content: center;
    }

    p {
      text-align: center;
      color: #ff0000;
    }
  }

  .successBtn {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/*投诉 表格*/
.complaints {
  table {
    border: 1px solid #ebeef5;
    width: 100%;
    /*tr{*/
    /*    border-bottom: 1px solid #EBEEF5;*/
    /*}*/
    /*tr:last-of-type{*/
    /*    border-bottom: 0;*/
    /*}*/
    tr {
      th {
        color: #909399;
        font-size: 14px;
        font-weight: bold;
        padding: 12px 10px;
        text-align: center;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
      }

      th:last-of-type {
        border-right: 0;
      }

      td {
        color: #606266;
        font-size: 14px;
        padding: 12px 10px;
        text-align: center;
        border-right: 1px solid #ebeef5;
        border-bottom: 1px solid #ebeef5;
      }

      td:last-of-type {
        border-right: 0;
      }

      .orange {
        color: #eb6d00;
        font-size: 20px;
      }
    }

    tr:last-of-type th {
      border-bottom: 0;
    }

    tr:last-of-type td {
      border-bottom: 0;
    }
  }
}

/*文件上传弹框*/
.file {
  ::v-deep .el-dialog {
    overflow: auto;
    //height: 700px;
  }
}

/*操作_浏览按钮*/
.browse {
  padding: 0;

  .liulan {
    color: white;
    text-decoration: none;
    height: 100%;
    padding: 7px 15px;
    display: block;
    text-align: center;
  }
}

/*!*文件上传加载遮罩层*!*/
/*.el-popup-parent--hidden{*/
/*    ::v-deep .el-loading-mask{*/
/*        z-index: 99999;*/
/*    }*/
/*}*/
</style>

<style lang="scss">
.el-checkbox__inner,
.el-radio__inner {
  border: 1px solid #232430;
}
/*tabbar切换  */
.el-tabs {
  display: block;
  width: 100%;
  /*tabbar分布*/
  .el-tabs__nav {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .el-tabs__active-bar {
      display: none;
    }

    .is-active {
      border-bottom: 2px solid #409eff;
    }

    .el-tabs__item {
      margin: 0 20px;
      padding: 0;
    }
  }
}

.table {
  .list {
    .bottom {
      /*上传文件按钮*/
      .el-button--text {
        color: white;
        border-radius: 5px;
        padding: 12px 15px;
        background-color: #409eff;
      }
    }

    .jindu {
      /*进度追踪按钮*/
      .el-button--text {
        color: white;
        border-radius: 5px;
        padding: 10px 8px;
        background-color: #409eff;
      }
    }
  }
}

.el-input__icon:after {
  height: auto;
}

/*  !*表格样式  *!*/
/*    .table {*/
/*        .el-table .cell {*/
/*            text-align: center !important;*/
/*        }*/
/*    }*/

/*!*  发票申请（按钮样式）  *!*/
/*  .invoice{*/
/*      .el-row{*/
/*          display: flex;*/
/*          margin: 10% auto;*/
/*          button{*/
/*              margin: auto;*/
/*          }*/
/*      }*/

/*  !*  申请发票表单  *!*/
/*      .el-form{*/
/*          margin: 20px auto;*/
/*          width: 50%;*/
/*      }*/
/*  }*/

.card {
  .detail {
    .el-dialog {
      height: 75% !important;
      overflow: auto;

      .el-dialog__header {
        padding: 20px 20px 20px;
        // position: fixed;
        background: #fff;
        width: calc(55% - 40px);
      }

      // .el-dialog__body {
      //   margin-top: 64px;
      // }
    }
  }
}
</style>
