<template>
  <div>
    <div class="card">
      <div class="card-top">
        <span></span>
        <h3>应收金额汇总</h3>
      </div>
      <div class="query">
        <el-row :gutter="10">
          <!-- 全部客户 -->
          <el-col :span="6">
            <el-select
              v-model="member"
              filterable
              clearable
              placeholder="请选择"
              @change="selectMember"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <!-- 全部客服 -->
          <el-col :span="4" v-if="userType === 1">
            <el-select
              v-model="service"
              filterable
              clearable
              placeholder="请选择"
              @change="selectService"
            >
              <el-option
                v-for="(item, index) in services"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <!-- 起始时间 -->
          <el-col :span="4">
            <el-date-picker
              v-model="startTime"
              type="date"
              placeholder="选择起始日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <!-- 结束时间 -->
          <el-col :span="4">
            <el-date-picker
              v-model="endTime"
              type="date"
              placeholder="选择结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-col>
          <!-- 按钮 -->
          <el-col :span="4">
            <el-button type="primary" @click="querys">查询</el-button>
            <!--            <el-button type="primary" @click="exportTable">导出</el-button>-->
          </el-col>
        </el-row>
      </div>
      <div class="money">
        <p>
          初期汇总:<span class="spans" style="color: #cfa24b">{{
            initialMoney
          }}</span
          >元
        </p>
        <p>
          新增汇总:<span class="spans" style="color: #cf5ab9">{{
            addMoney
          }}</span
          >元
        </p>
        <p>
          减少汇总:<span class="spans" style="color: #40cfa3">{{
            reduceMoney
          }}</span
          >元
        </p>
        <p>
          应收汇总:<span class="spans" style="color: #007aff">{{
            receivableMoney
          }}</span
          >元
        </p>
      </div>
      <div class="table">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="custName" label="客户名称"></el-table-column>
          <el-table-column prop="custLevel" label="客户类型"></el-table-column>
          <el-table-column prop="salesman" label="所属客服"></el-table-column>
          <el-table-column prop="preFee" label="初期应收"></el-table-column>
          <el-table-column label="本期新增">
            <template slot-scope="scope">
              <span
                @click="addTable(scope.row)"
                :style="scope.row.qsFee > 0 ? 'color:#ff0000' : ''"
                >{{ scope.row.qsFee }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="本期减少">
            <template slot-scope="scope">
              <span
                @click="reduceTable(scope.row)"
                :style="
                  scope.row.payFee > 0 ? 'color:#40cf83' : 'color:#ff0000'
                "
                >{{ scope.row.payFee }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="期末应收">
            <template slot-scope="scope">
              <span>{{ scope.row.termNum | numbers }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>

      <!--本期新增对话框-->
      <el-dialog title="本期新增" :visible.sync="addTables" width="68%" center>
        <div class="add_money">
          <span class="custName">{{ addDetail.custName }}</span
          >：合计<span class="qsFee">{{ addDetail.qsFee }}</span
          >元
        </div>
        <table class="tables">
          <tr>
            <th>序</th>
            <th>下单日期</th>
            <th>订单号</th>
            <th>产品名称</th>
            <th>送货数量</th>
            <th>送货单号</th>
            <th>签收金额</th>
            <th>签收日期</th>
          </tr>
          <tr v-for="(item, index) in xinzengList" :key="item.orderId">
            <td>{{ index + 1 }}</td>
            <td>{{ item.updateTime }}</td>
            <td>{{ item.orderId }}</td>
            <td>{{ item.orderName }}</td>
            <td>{{ item.sendNum }}</td>
            <td>{{ item.invoiceCode }}</td>
            <td>{{ item.realAmount }}</td>
            <td>{{ item.updateTime }}</td>
          </tr>
        </table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">关 闭</el-button>
        </span>
      </el-dialog>

      <!--本期减少对话框-->
      <el-dialog
        title="本期减少"
        :visible.sync="reduceTables"
        width="68%"
        center
      >
        <div class="add_money">
          <span class="custName">{{ reduceDetail.custName }}</span
          >：合计<span class="qsFee">{{ reduceDetail.payFee }}</span
          >元
        </div>
        <table class="tables">
          <tr>
            <th>序</th>
            <th>收款日期</th>
            <th>金额</th>
            <th>支付方式</th>
            <th>收款人</th>
            <th>摘要</th>
          </tr>
          <tr v-for="(item, index) in reduceList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.collectionDate }}</td>
            <td>{{ item.collectionAmount }}</td>
            <td>{{ item.collectionStyle }}</td>
            <td>{{ item.operatorName }}</td>
            <td>{{ item.remarks }}</td>
          </tr>
        </table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="canceljs">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "../../api/invoice/invoice";
import url from "../../api/picture";

export default {
  name: "receivable",
  data() {
    return {
      baseUrl: url.baseUrl,
      tableData: [], // 应收对账数据表
      customerId: "", // 客户id
      salesmanid: "", // 客服id
      initialMoney: 0, //初期汇总
      addMoney: 0, //新增汇总
      reduceMoney: 0, //减少汇总
      receivableMoney: 0, //应收汇总
      startTime: "",
      endTime: "",
      member: "",
      options: [], // 客户
      service: "",
      services: [], // 客服
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      spareMemberList: [], // 备用客户数据
      spareServeList: [], // 备用客服数据

      //本期新增_对话框
      addTables: false,
      //本期新增_数据
      addDetail: [],
      xinzengList: [],

      //本期减少_对话框
      reduceTables: false,
      //本期减少_数据
      reduceDetail: [],
      reduceList: [],
      userType: "",
      querysSaleanId: "", // 客户userId
    };
  },
  filters: {
    numbers(val) {
      let value = 0;
      if (!isNaN(val) && val !== "") {
        value = parseFloat(val).toFixed(2);
      } else {
        value = 0;
      }
      return value;
    },
  },
  created() {
    let obj = JSON.parse(window.sessionStorage.getItem("currentInfo"));
    if (obj) {
      this.userType = obj.userType;
    }
    // this.init();
    this.getAllPeoper();
  },
  methods: {
    // 查询
    querys() {
      this.init();
    },
    // 导出
    exportTable() {
      let len = this.tableData.length;
      if (len <= 0) {
        this.$message.warning("当前列表暂无数据导出！");
        return;
      }
      let kpiUrl = this.baseUrl;
      let url = `/reconciliation/exportReceivable?customerId=${this.customerId}&salesmanid=${this.salesmanid}&startTime=${this.startTime}&endTime=${this.endTime}&pageSize=${this.pageSize}&pageNumber=${this.pageNumber}`;
      window.location.href = `${kpiUrl}${url}`;
      console.log(`${kpiUrl}${url}`);

      // let url = "/reconciliation/exportReceivable";
      // let app = document.getElementById("app");
      // let $form = document.createElement("form");
      // $form.setAttribute("action", `${kpiUrl}${url}`);
      // $form.setAttribute("method", "GET");
      // $form.setAttribute("target", "_blank");
      // let $ipt = document.createElement("input");
      // $ipt.setAttribute("customerId", this.customerId);
      // $ipt.setAttribute("salesmanid", this.salesmanid);
      // $ipt.setAttribute("startTime", this.startTime);
      // $ipt.setAttribute("endTime", this.endTime);
      // $ipt.setAttribute("pageSize", this.pageSize);
      // $ipt.setAttribute("pageNumber", this.pageNumber);
      // $form.appendChild($ipt);
      // app.appendChild($form);
      // console.log($form);
      // $form.submit();
      // app.removeChild($form);
      // api
      //   .exportReceivable(
      //     this.customerId,
      //     this.salesmanid,
      //     this.startTime,
      //     this.endTime,
      //     this.pageSize,
      //     this.pageNumber
      //   )
      //   .then((res) => {
      //     console.log("导出接口调用", res);
      //     if (res.success) {
      //       this.$message.success("导出成功！");
      //     } else {
      //       this.$message.error("导出失败！");
      //     }
      //   });
    },
    //选择客户
    selectMember(e) {
      console.log(e);
      this.customerId = e;
      let obj = this.spareMemberList.find((el) => e == el.customerId);
      console.log("获取当前客服", obj);
      if (obj) {
        this.querysSaleanId = obj.user_ID;
      } else {
        this.querysSaleanId = "";
      }
      this.getAllPeoper();
    },
    // 选择客服
    selectService(e) {
      console.log(e, this.salesmanid);
      if (e !== this.salesmanid) {
        console.log("执行了这里");
        this.member = "";
        this.options = this.spareMemberList;
        this.customerId = "";
      }
      this.salesmanid = e;
    },
    // 本期减少
    reduceTable(item) {
      console.log(item);
      if (item.payFee == 0.0) {
        this.reduceTables = false;
      } else {
        this.reduceTables = true;
        this.reduceDetail = item;
        api
          .showDetailsPay(item.custId, this.startTime, this.endTime)
          .then((res) => {
            console.log("本期减少", res);
            if (res.success) {
              this.reduceList = res.data;
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    //本期减少_取消
    canceljs() {
      this.reduceTables = false;
    },
    //本期新增
    addTable(item) {
      console.log(item);
      if (item.qsFee == 0.0) {
        this.addTables = false;
      } else {
        this.addTables = true;
        this.addDetail = item;
        api
          .showDetailsQs(item.custId, this.startTime, this.endTime)
          .then((res) => {
            console.log("本期新增", res);
            if (res.success) {
              this.xinzengList = res.data;
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
    //本期新增_取消
    cancel() {
      this.addTables = false;
    },
    getAllPeoper() {
      api
        .getAllCustomerAndService(this.querysSaleanId, this.salesmanid)
        .then((res) => {
          console.log("获取客户，客服集合", res);
          if (res.code == 200 && res.success) {
            let clientList = res.data.clientList;
            let customerList = res.data.customerList;
            let customerInfo = res.data.customerInfo;
            // let arr = [];
            clientList.forEach((el) => {
              el.value = el.customerId;
              el.label = el.name ? el.name : "暂无名称(无效数据)";
            });
            customerList.forEach((el) => {
              el.value = el.user_ID;
              el.label = el.name ? el.name : "暂无名称(无效数据)";
            });
            let obj = {
              value: "",
              label: "全部客户",
            };
            let obj1 = {
              value: "",
              label: "全部客服",
            };
            clientList.unshift(obj);
            customerList.unshift(obj1);
            this.options = clientList;
            this.spareMemberList = clientList;

            this.services = customerList;
            this.spareServeList = customerList;
            if (customerInfo) {
              this.$nextTick(() => {
                let have = this.services.some(
                  (el) => el.user_ID == customerInfo.user_ID
                );
                console.log("是否存在", have);
                if (!have) {
                  let obj2 = {
                    value: customerInfo.user_ID,
                    label: customerInfo.name,
                  };
                  this.services.push(obj2);
                }
                if (this.userType === 1) {
                  this.service = customerInfo.user_ID;
                  this.salesmanid = customerInfo.user_ID;
                }
              });
            } else {
              this.$nextTick(() => {
                this.service = "";
                this.salesmanid = "";
              });
            }
            // console.log("切换数组", arr);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    init() {
      // 客户id，客服id，起始时间，结束时间
      if (this.startTime == null) {
        this.startTime = "";
      }
      if (this.endTime == null) {
        this.endTime = "";
      }
      api
        .receivable(
          this.customerId,
          this.salesmanid,
          this.startTime,
          this.endTime,
          this.pageSize,
          this.pageNumber
        )
        .then((res) => {
          console.log("获取应收表格数据", res);
          if (res.code == 200 && res.success) {
            let list = res.data.pageInfo.list;
            list.forEach((el) => {
              el.termNum =
                Number(el.preFee) + Number(el.qsFee) - Number(el.payFee);
            });
            this.tableData = list;
            this.total = res.data.pageInfo.total;
            let data = res.data.sumData;
            this.startTime = res.data.startTime;
            this.endTime = res.data.endTime;
            if (data) {
              this.initialMoney = data.sumPre ? data.sumPre : 0; //初期汇总
              this.addMoney = data.sumQs ? data.sumQs : 0; //新增汇总
              this.reduceMoney = data.sumPay ? data.sumPay : 0; //减少汇总
              this.receivableMoney = data.sumYs ? data.sumYs : 0; //应收汇总
            } else {
              this.initialMoney = this.addMoney = this.reduceMoney = this.receivableMoney = 0;
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;

  .card-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 30px;
    line-height: 50px;

    span {
      display: inline-block;
      width: 5px;
      height: 17px;
      background: #058af2;
      margin-right: 20px;
    }

    h3 {
      color: #058af2;
    }
  }

  .query {
    margin: 15px 0;
  }

  ::v-deep .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  .money {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;

    .spans {
      font-weight: 600;
    }
  }

  .table {
    .page {
      margin: 10px;
    }
  }

  /* 本期新增 */
  .add_money {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
    /*客户名称*/
    .custName {
      color: #cd9d43;
    }

    /*合计*/
    .qsFee {
      color: #cf5ab9;
    }
  }

  .tables {
    width: 100%;
    border: 1px solid #ebeef5;

    tr {
      border-bottom: 1px solid #ebeef5;

      th {
        background: #ecf6ff;
      }

      th,
      td {
        padding: 10px;
        text-align: center;
        border-bottom: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
      }

      th:last-child {
        border-right: none !important;
      }

      td:last-child {
        border-right: none !important;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }

    tr:hover {
      background: #f5f5f5;
    }
  }
}
</style>
